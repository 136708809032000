import { defineStore, acceptHMRUpdate } from "pinia";
import { ref, computed } from "vue";
import api from "@/router/api";
import _ from "lodash";

export const usePropertyFieldsStore = defineStore("propertyFields", () => {
  const propertyFields = ref({});
  const fieldKeys = computed(() => _.keys(propertyFields.value));

  async function fetchPropertyDataField(propertyId) {
    const existingField = getPropertyField(propertyId);

    if (existingField === "await") {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      const result = await fetchPropertyDataField(propertyId);

      return result;
    } else if (_.isObject(existingField)) {
      return existingField;
    } else if (_.includes(fieldKeys.value, propertyId)) {
      return null;
    } else {
      propertyFields.value[propertyId] = "await";
      const response = await api.get(`properties/${propertyId}`);

      patchPropertyDataFields(response.data);

      return response.data;
    }
  }

  function patchPropertyDataFields(newField) {
    const id = newField.fieldContentId;
    propertyFields.value[id] = newField;
  }

  function getPropertyField(id) {
    return propertyFields.value[id];
  }

  return {
    propertyFields,
    fetchPropertyDataField,
    getPropertyField,
    patchPropertyDataFields,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePropertyFieldsStore, import.meta.hot),
  );
}
