<template>
  <div class="overflow-hidden">
    <div
      v-tooltip="
        'This citation supports this datapoint but the source material cannot be shared. It has been reviewed and approved by Tower Hunt.'
      "
      v-if="confidential && !authored"
      class="flex items-center space-x-1"
    >
      <div
        class="ml-2 inline-flex rounded-full bg-green-100 p-1 text-xs font-semibold leading-5 text-green-800"
      >
        <ShieldCheckIcon class="w-4 h-4" />
      </div>
      <div class="text-gray-700 font-medium block py-2 text-xs">
        Confidential
      </div>
    </div>
    <div v-else-if="citationDetails">
      <div class="flex w-full">
        <div
          v-show="approvable"
          class="flex flex-col flex-shrink-0 border-l border-r border-gray-300 divide-y divide-gray-300"
        >
          <button
            @click="accept"
            type="button"
            v-tooltip="'Accept citation'"
            class="flex-1 inline-flex items-center justify-center bg-white px-0.5 py-3 font-semibold text-green-700 hover:bg-green-50 text-xs"
            data-test="accept-citation-button"
          >
            <CheckIcon class="h-3 w-3" />
          </button>
          <button
            @click="reject"
            type="button"
            v-tooltip="'Reject citation'"
            class="flex-1 inline-flex items-center justify-center bg-white px-0.5 py-3 font-semibold text-red-700 hover:bg-red-50 text-xs"
            data-test="reject-citation-button"
          >
            <XMarkIcon class="h-3 w-3" />
          </button>
        </div>
        <div
          class="max-w-full overflow-hidden flex-1 p-2 hover:bg-gray-50"
          v-tooltip="description"
        >
          <div class="flex items-center justify-between">
            <div class="flex items-center text-xs">
              <CalendarDaysIcon
                class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <p>
                <time
                  v-if="citationDetails.dateField"
                  :datetime="citationDetails.dateField.fieldValue"
                  >{{
                    moment(citationDetails.dateField.fieldValue).format(
                      "MMM YYYY",
                    )
                  }}</time
                >
                <span v-else>Undated</span>
              </p>
            </div>
            <div class="flex items-center space-x-1">
              <div
                v-if="citationDetails.confidential"
                v-tooltip="'Confidential'"
                class="ml-2 flex flex-shrink-0"
              >
                <p
                  class="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800"
                >
                  <EyeSlashIcon class="w-4 h-4" />
                </p>
              </div>
              <div class="flex flex-shrink-0">
                <p
                  class="inline-flex rounded-full bg-cyan-100 px-2 text-xs font-semibold leading-5 text-cyan-800"
                >
                  {{ _.capitalize(citationType) }}
                </p>
              </div>
              <VDropdown v-if="removable">
                <button
                  aria-has-popup="true"
                  type="button"
                  class="inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100"
                  data-test="citation-field-menu-button"
                >
                  <EllipsisVerticalIcon class="w-5 h-5" />
                </button>
                <template #popper>
                  <div class="flex flex-col">
                    <DataFieldActionMenu
                      :data-field="citationField"
                      :user-store="userStore"
                      :modal-store="modalStore"
                      :unlocker-store="unlockerStore"
                      :tasks-store="tasksStore"
                      :task-list-store="taskListStore"
                      :reminder-store="reminderStore"
                      :guest-profile-store="guestProfileStore"
                      :property-diagram-store="propertyDiagramStore"
                      :change-group-store="changeGroupStore"
                      :layout-store="layoutStore"
                      :documentation-store="documentationStore"
                      @completed="emit('refetch')"
                      @dismiss="emit('refetch')"
                      class="w-72 divide-y divide-gray-200 focus:outline-none"
                    />
                  </div>
                </template>
              </VDropdown>
            </div>
          </div>
          <div
            :class="displayCompact ? '' : 'flex justify-between'"
            class="mt-2"
          >
            <div :class="displayCompact ? '' : 'flex'" class="">
              <p
                v-if="_.get(citationDetails.nameField, 'fieldValue')"
                class="text-left text-xs font-medium text-gray-500"
              >
                {{ _.get(citationDetails.nameField, "fieldValue") }}
              </p>
              <a
                v-if="citationType === 'website'"
                href=""
                @click.prevent.stop="viewUrl"
                :class="displayCompact ? 'mt-2' : 'mt-0 ml-4'"
                class="flex items-center text-xs text-indigo-700"
              >
                <LinkIcon
                  class="mr-1.5 h-5 w-5 flex-shrink-0 text-indigo-600"
                  aria-hidden="true"
                />
                <span class="truncate">{{
                  citationDetails.urlField.fieldValue
                }}</span>
              </a>
              <a
                v-else-if="citationType === 'file'"
                href=""
                @click.prevent="viewFile"
                :class="displayCompact ? 'mt-2' : 'mt-0 ml-4'"
                class="flex items-center text-xs text-indigo-700"
                data-test="preview-citation-file-button"
              >
                <PaperClipIcon
                  class="mr-1.5 h-5 w-5 flex-shrink-0 text-indigo-600"
                  aria-hidden="true"
                />
                <span class="truncate">{{
                  citationDetails.fileField.fieldContent.name
                }}</span>
              </a>
            </div>
            <div
              v-if="citationType === 'website'"
              :class="displayCompact ? 'mt-2' : 'mt-0'"
              class=""
            >
              <p class="flex items-center text-xs text-gray-500">
                <template v-if="paywalled">
                  <KeyIcon
                    v-tooltip="
                      'Content may be inaccessible without some form of payment.'
                    "
                    class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span
                    v-tooltip="
                      'Content may be inaccessible without some form of payment.'
                    "
                    >Paywalled</span
                  >
                </template>
                <template v-else>
                  <EyeIcon
                    class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>Public</span>
                </template>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  CalendarDaysIcon,
  EyeIcon,
  EyeSlashIcon,
  LinkIcon,
  PaperClipIcon,
  KeyIcon,
  XMarkIcon,
  CheckIcon,
  ShieldCheckIcon,
  EllipsisVerticalIcon,
} from "@heroicons/vue/20/solid";
import { ref, onMounted, computed } from "vue";
import { useUploadedFileStore } from "@/stores/uploadedFile";
import { useUserStore } from "@/stores/user";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useModalStore } from "@/stores/modal";
import { useUnlockerStore } from "@/stores/unlocker";
import { useTasksStore } from "@/stores/tasks";
import { useTaskListStore } from "@/stores/taskList";
import { useReminderStore } from "@/stores/reminder";
import { useGuestProfileStore } from "@/stores/guestProfile";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useDocumentationStore } from "@/stores/documentation";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { storeToRefs } from "pinia";
import { cleanUrl } from "@/assets/cleanUrl";
import DataFieldActionMenu from "./DataFieldActionMenu.vue";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";
import moment from "moment";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps([
  "citationField",
  "citationScope",
  "compact",
  "embeddedDiff",
  "removable",
]);
const emit = defineEmits(["validated-citation", "refetch"]);
const citationDetails = ref(null);

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const userStore = useUserStore();
const { isAdmin, emailUnverified } = storeToRefs(userStore);
const documentationStore = useDocumentationStore();
const tasksStore = useTasksStore();
const taskListStore = useTaskListStore();
const reminderStore = useReminderStore();
const guestProfileStore = useGuestProfileStore();
const propertyDiagramStore = usePropertyDiagramStore();
const changeGroupStore = useCrowdsourcedChangeGroupStore();

const displayCompact = computed(
  () => props.compact || workspaceLayout.value === "sideBySide",
);
const citationId = computed(() => props.citationField.fieldContentId);
const citationFieldType = computed(() => props.citationField.fieldContentType);
const confidential = computed(
  () => props.citationField.fieldContent.confidential,
);
const authored = computed(() => props.citationField.fieldContent.authored);
const citationType = computed(() =>
  _.get(citationDetails.value, "citationType"),
);
const paywalled = computed(() => {
  const boolString = _.get(citationDetails.value, "paywalledField.fieldValue");

  if (boolString === "true") return true;
  else if (boolString === "false") return false;
  else return null;
});
const description = computed(
  () => _.get(citationDetails.value, "descriptionField.fieldValue"),
  "",
);
const adminApprovable = computed(() => {
  return isAdmin.value;
});
const approvable = computed(() => {
  return (
    !props.embeddedDiff &&
    !props.citationField.validated &&
    props.citationField.approvable &&
    (adminApprovable.value || !props.citationField.authored)
  );
});
const fetchable = computed(() => !confidential.value || authored.value);

onMounted(() => {
  fetchCitation();
});

async function fetchCitation() {
  if (fetchable.value) {
    api
      .get(
        `data_field_citation_details/${citationId.value}?citation_field_type=${
          citationFieldType.value
        }&scope=${props.citationScope || null}`,
      )
      .then((json) => {
        citationDetails.value = json.data;
      });
  }
}

function viewUrl() {
  window.open(cleanUrl(citationDetails.value.urlField.fieldValue), "_blank");
}
function viewFile() {
  const uploadedFileStore = useUploadedFileStore();

  uploadedFileStore.viewEmbed(citationDetails.value.fileField.fieldContent);
}

const modalStore = useModalStore();
const { modalPayload } = storeToRefs(modalStore);
const unlockerStore = useUnlockerStore();
const { upgradeSuccessful } = storeToRefs(unlockerStore);
const validationPayload = computed(() => {
  return {
    changeGroupId: props.citationField.latestChangeGroupId,
    adminOverride: adminApprovable.value ? "override" : null,
  };
});
const validationSuccessCallback = () => emit("validated-citation");

function accept() {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else {
    const apiRequestFunc = () =>
      api.post(`validation_accept_changes`, validationPayload.value);

    if (adminApprovable.value) {
      apiRequestFunc().then(() => validationSuccessCallback());
    } else {
      subscribeIntercept(apiRequestFunc);
    }
  }
}
function reject() {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else {
    const apiRequestFunc = () =>
      api.post(`validation_reject_changes`, validationPayload.value);

    if (adminApprovable.value) {
      apiRequestFunc().then(() => validationSuccessCallback());
    } else {
      subscribeIntercept(apiRequestFunc);
    }
  }
}
function subscribeIntercept(apiRequestFunc) {
  subscribeInterceptor({
    apiRequestFunc,
    successCallback: validationSuccessCallback,
    modalPayloadRef: modalPayload,
    upgradeSuccessfulRef: upgradeSuccessful,
    afterSubscribe: "apiRequest",
    context: "validating",
  });
}
</script>
