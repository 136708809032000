<template>
  <div class="rounded-md bg-yellow-50 p-1.5">
    <div class="w-full flex">
      <div class="flex-shrink-0">
        <ExclamationTriangleIcon
          class="h-4 w-4 text-yellow-400"
          aria-hidden="true"
        />
      </div>
      <div class="flex-grow ml-3 space-y-1">
        <h3 class="text-xs font-medium text-yellow-800">Data Warning</h3>
        <div
          v-for="warning in warningData"
          v-observe-visibility="{
            callback: (isVisible, entry) =>
              fetchPropertyDataFields(isVisible, entry, warning),
            once: true,
          }"
          :key="warning.id"
          class="flex items-center justify-between"
        >
          <div class="text-xs text-yellow-700">
            <p v-tooltip="warning.extendedDescription">
              {{ warning.description }}
            </p>
          </div>
          <VDropdown>
            <button
              type="button"
              :v-tooltip="`${warningTip(
                warning.id,
              )} Earn money by fixing issues.`"
              class="rounded bg-white px-2 py-1 text-xs text-yellow-900 shadow-sm ring-1 ring-inset ring-yellow-300 hover:bg-yellow-50"
            >
              View issues
            </button>
            <template #popper>
              <div class="p-1 max-w-md flex flex-wrap items-center">
                <template
                  v-if="_.includes(['no_value', 'no_loan_amount'], warning.id)"
                >
                  <a
                    v-for="(
                      mixedTypeInvestmentField, index
                    ) in warning.rawFields"
                    :key="mixedTypeInvestmentField.localId"
                    href=""
                    @click.prevent="
                      clickHandler(warning.id, mixedTypeInvestmentField)
                    "
                    class="flex items-baseline text-xs font-medium text-indigo-500 hover:text-indigo-700"
                  >
                    <template
                      v-if="mixedTypeInvestmentField.fieldContent?.name"
                    >
                      {{
                        mixedTypeInvestmentField.fieldContent?.name
                      }}</template
                    >
                    <template
                      v-else-if="
                        mixedTypeInvestmentField.fieldContentType ===
                          'Investment' &&
                        investmentFieldFor(
                          `${mixedTypeInvestmentField.fieldContentType}${mixedTypeInvestmentField.fieldContentId}`,
                        )
                      "
                      >{{
                        investmentLabelFor(
                          `${mixedTypeInvestmentField.fieldContentType}${mixedTypeInvestmentField.fieldContentId}`,
                        )
                      }}</template
                    >
                    <template v-else>
                      {{ mixedTypeInvestmentField.fieldContentType
                      }}{{ mixedTypeInvestmentField.fieldContentId }}
                    </template>
                    <div
                      class="mr-1"
                      v-if="index < warning.rawFields.length - 1"
                    >
                      ;
                    </div>
                  </a></template
                >
                <template v-else>
                  <a
                    v-for="(propertyField, index) in propertyDataFields[
                      warning.id
                    ]"
                    :key="propertyField.localId"
                    href=""
                    @mouseenter="
                      propertyMarkerPulseId = propertyField.fieldContentId
                    "
                    @mouseleave="propertyMarkerPulseId = null"
                    @click.prevent="clickHandler(warning.id, propertyField)"
                    class="flex items-baseline text-xs font-medium text-indigo-500 hover:text-indigo-700"
                  >
                    {{ propertyField.fieldContent.name }}
                    <div
                      class="mr-1"
                      v-if="index < propertyDataFields[warning.id].length - 1"
                    >
                      ,
                    </div>
                  </a>
                </template>
              </div>
            </template>
          </VDropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ExclamationTriangleIcon } from "@heroicons/vue/20/solid";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { useMainMapStore } from "@/stores/mainMap";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import selfLabel from "@/components/crowdsourcing/selfLabelDataField";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import api from "@/router/api";
import _ from "lodash";

defineProps(["warningData"]);
const analyzePanelStore = useAnalyzePanelStore();
const { analyzeInvestmentFields } = storeToRefs(analyzePanelStore);
const propertyDiagramStore = usePropertyDiagramStore();
const mapStore = useMainMapStore();
const { propertyMarkerPulseId } = storeToRefs(mapStore);
const dealStore = useDealBuilderStore();
const propertyFieldsStore = usePropertyFieldsStore();

const propertyDataFields = ref({});
const investmentDataFields = ref([]);
const investmentGroupDataFields = ref([]);

function investmentFieldFor(investmentKey) {
  const field = analyzeInvestmentFields.value?.[investmentKey];

  return field || null;
}
function investmentLabelFor(investmentKey) {
  const field = investmentFieldFor(investmentKey);

  if (field) {
    const propertyId = field.fieldContent?.asset?.fieldContent?.propertyId;

    if (propertyId) {
      const propertyField =
        analyzeInvestmentFields.value?.[`Property${propertyId}`];

      return selfLabel(field, propertyField);
    } else {
      return selfLabel(field);
    }
  } else {
    return "";
  }
}

function warningTip(warningId) {
  switch (warningId) {
    case "no_value":
    case "no_loan_amount": {
      return "See which investments have issues.";
    }
    default:
      return "See which properties have issues.";
  }
}

async function fetchPropertyDataFields(isVisible, entry, warning) {
  if (isVisible) {
    for (const field of warning.rawFields) {
      if (field.fieldContentType === "Investment") {
        investmentDataFields.value.push(field);
      } else if (field.fieldContentType === "InvestmentGroup") {
        investmentGroupDataFields.value.push(field);
      } else if (field.propertyId) {
        fetchPropertyDataField(warning.id, field.propertyId);
      }
    }
  }
}

async function fetchPropertyDataField(warningId, propertyId) {
  const field = await propertyFieldsStore.fetchPropertyDataField(propertyId);

  const existingPropertyFields = propertyDataFields.value[warningId];

  if (existingPropertyFields) {
    propertyDataFields.value[warningId] = _.unionBy(
      [field],
      existingPropertyFields,
      "localId",
    );
  } else {
    propertyDataFields.value[warningId] = [field];
  }
}

function viewInvestment(dataField) {
  const investmentId = dataField.fieldContentId;
  api.get(`investment_deal_builder_assets/${investmentId}`).then((json) => {
    const { assetDataField, propertyDataField } = json.data;

    dealStore.patchPropertyDataFields(propertyDataField);
    dealStore.upsertDealBuilder({
      assetDataField,
      timelineInvestmentId: investmentId,
      timeTravelDate: dataField.date,
    });
  });
}

function viewInvestmentGroup(dataField) {
  const investmentGroupId = dataField.fieldContentId;
  dealStore.upsertDealBuilder({
    existingGroupId: investmentGroupId,
  });
}

function clickHandler(warningId, dataField) {
  switch (warningId) {
    case "no_value":
    case "no_loan_amount": {
      console.log("handle investment/group");
      if (dataField.fieldContentType === "Investment") {
        viewInvestment(dataField);
      } else if (dataField.fieldContentType === "InvestmentGroup") {
        viewInvestmentGroup(dataField);
      }
      break;
    }
    default:
      console.log("navigate to diagram");
      propertyDiagramStore.navigateToDiagram(dataField.fieldContentId, true);
  }
}
</script>
