<template>
  <div class="mt-4">
    <div class="flex justify-between">
      <div class="text-sm text-gray-500">
        Connect this floor area to a related condo or co-op.
      </div>
    </div>
    <div class="mt-4 px-4 grid grid-cols-2 gap-x-8">
      <div
        v-if="linkedUnitRightDataFields.length > 0"
        class="flex flex-col space-y-2"
      >
        <dt class="text-sm font-medium text-gray-500">Linked Unit Rights:</dt>
        <ol
          v-for="rightField in linkedUnitRightDataFields"
          :key="rightField.localId"
          class="p-1.5 rounded-md flex items-center justify-between hover:bg-gray-200"
        >
          <DataField
            :data-field="rightField"
            text-classes="text-sm font-medium max-w-48"
            text-styles=""
            @completed="fetchLinkedRights"
            @unlocked="fetchLinkedRights"
            @open-sourced="fetchLinkedRights"
          />
          <button
            @click="removeRightConfirmation(rightField.fieldContentId)"
            type="button"
            v-tooltip="'Remove from floor area'"
            :data-test="`discard-floor-area-unit-right-${rightField.localId}-button`"
            class="h-5 w-5 p-1 inline-flex justify-center items-center text-gray-500 hover:text-gray-700"
          >
            <i class="fas fa-times" />
          </button>
        </ol>
      </div>
      <div
        v-else
        class="text-center"
        data-test="linked-unit-rights-empty-state"
      >
        <LinkIcon class="mt-8 mx-auto h-8 w-8 text-gray-500" />
        <h3 class="mt-2 text-sm font-medium text-gray-900">
          No Linked Unit Rights
        </h3>
        <p class="mt-1 text-sm text-gray-500">
          Link a condo or co-op to this floor area.
        </p>
      </div>
      <ol
        v-if="unlinkedUnitRightDataFields.length > 0"
        class="flex flex-col space-y-2"
      >
        <dt class="text-sm font-medium text-gray-500">Unlinked Unit Rights:</dt>
        <FloorAreaLayoutLinkingControl
          v-for="rightField in unlinkedUnitRightDataFields"
          :key="rightField.localId"
          :layout="rightField"
          @linking="setLinking"
          @perform-link="persistLink"
        />
      </ol>
      <div
        v-else
        class="text-center"
        data-test="linked-unit-rights-empty-state"
      >
        <QrCodeIcon class="mt-8 mx-auto h-8 w-8 text-gray-500" />
        <h3 class="mt-2 text-sm font-medium text-gray-900">
          No Unlinked Unit Rights
        </h3>
        <p class="mt-1 text-sm text-gray-500">
          Unit Rights for this floor area will appear here.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { LinkIcon, QrCodeIcon } from "@heroicons/vue/20/solid";
import { ref, computed, onMounted, watch, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useModalStore } from "@/stores/modal";
import DataField from "@/components/crowdsourcing/DataField.vue";
import FloorAreaLayoutLinkingControl from "@/components/crowdsourcing/FloorAreaLayoutLinkingControl.vue";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps([
  "dataField",
  "availabilityGroupId",
  "dateFields",
  "perAreaSize",
  "scheduleDataField",
]);

const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { changeGroupId } = storeToRefs(changeGroupStore);
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyIdParam, propertyDiagramSelected, propertyDiagramEditAction } =
  storeToRefs(propertyDiagramStore);
const modalStore = useModalStore();
const { confirmationPayload } = storeToRefs(modalStore);

const unitRightDataFields = ref([]);
const linkedUnitRightDataFields = ref([]);
const linking = ref(null);
const adding = ref(false);

const unlinkedUnitRightDataFields = computed(() =>
  _.differenceBy(
    unitRightDataFields.value,
    linkedUnitRightDataFields.value,
    "fieldContentId",
  ),
);

const floorAreaId = computed(() => props.dataField.fieldContentId);

watch(
  propertyDiagramEditAction,
  async (val, oldVal) => {
    if (oldVal && !val && adding.value && propertyIdParam.value) {
      console.log("layout created completed");
      adding.value = false;
      await nextTick();
      if (!propertyDiagramSelected.value) {
        fetchRights();
      }
    }
  },
  { deep: true },
);

onMounted(() => {
  fetchLinkedRights();

  if (floorAreaId.value) {
    fetchRights();
  }
});

async function fetchRights() {
  const response = await api.get(
    `unit_based_property_rights/?property_id=${props.dataField.joiningContentId}`,
  );

  if (response?.data) {
    unitRightDataFields.value = response.data;
  }
}

async function fetchLinkedRights() {
  const response = await api.get(
    `floor_area_property_rights/${floorAreaId.value}`,
  );

  if (response?.data) {
    linkedUnitRightDataFields.value = response.data;
  }
}

function setLinking(layoutDataField = null) {
  linking.value = layoutDataField;
}

function persistLink() {
  if (linking.value) {
    const payload = {
      propertyId: props.dataField.joiningContentId,
      propertyRightId: linking.value.fieldContentId,
      floorAreas: [{ id: floorAreaId.value }],
      landCoverings: [],
      changeGroupId: changeGroupId.value,
    };
    setTimeout(() => {
      const apiRequestFunc = () =>
        api.post(`unit_based_property_rights`, payload);
      const successCallback = async () => {
        fetchLinkedRights();
        fetchRights();
      };
      const failureCallback = () => setLinking();
      return changeGroupStore.originateData(
        apiRequestFunc,
        successCallback,
        failureCallback,
      );
    }, 125);
  }
}

function removeRightConfirmation(rightId) {
  confirmationPayload.value = {
    title: "Remove unit right from floor area",
    message:
      "Are you sure you want to remove this property right from the floor area?",
    affirmText: "Remove",
    affirmCallback: () => removeRight(rightId),
  };
}
async function removeRight(rightId) {
  removeRightFromDatabase(rightId).then(() => {
    fetchLinkedRights();
    fetchRights();
  });
}
async function removeRightFromDatabase(rightId) {
  const apiRequestFunc = () =>
    api.delete(
      `floor_area_property_rights/${floorAreaId.value}?right_id=${rightId}&change_group_id=${changeGroupId.value}`,
    );
  const successCallback = (json) => {
    propertyDiagramStore.postEditingPatch(json);
  };
  const failureCallback = () => {};
  await changeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}
</script>
