<template>
  <div v-if="loaded" id="available-validations-container" class="w-full h-full">
    <div
      v-if="changeGroup"
      :id="`change-group-${changeGroup.id}`"
      class="flex flex-col overflow-hidden space-y-4"
    >
      <div class="m-2">
        <div class="flex items-center justify-between">
          <div
            v-tooltip="`Group ${changeGroup.id}`"
            class="mb-1 text-gray-700 font-semibold uppercase tracking-wide"
          >
            Changes for your review
          </div>
          <h4 v-if="isAdmin" class="text-xs text-gray-600 font-semibold">
            Group {{ changeGroup.id }}
          </h4>
        </div>
        <div
          id="change-group-header"
          class="border-2 border-gray-400 bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200"
        >
          <div
            class="px-2 pb-1 bg-gray-200 flex justify-between"
            data-test="change-group-header"
          >
            <div class="pt-1 w-56">
              <div class="mb-1 text-center text-indigo-600 font-medium">
                ${{ changeGroup?.potentialRewards }} of potential rewards
              </div>
              <ValidationProgressBar
                :accepted-change-ids="acceptedChangeIds"
                :rejected-change-ids="rejectedChangeIds"
                :skipped-change-ids="skippedChangeIds"
                :all-change-ids="allChangeIds"
              />
            </div>
            <div class="pt-1 flex flex-col items-end">
              <div
                v-if="!allViewed && !isAdmin"
                class="text-sm text-gray-500 font-medium"
              >
                First, view all changes
              </div>
              <ValidationActions
                :action-in-progress="actionInProgress"
                :admin-approvable="adminApprovable"
                :disabled="!allViewed && !isAdmin"
                :has-conflicts="hasConflicts"
                :compact="true"
                :change-count="summarizedChangeCount"
                test-prefix="change-header"
                @approve="approveAll"
                @disapprove="disapproveAll"
                @accept="acceptAll"
                @reject="rejectAll"
                @skip="skipAll"
              />
            </div>
          </div>
          <ChangeGroupContext :change-group="changeGroup" />
        </div>
      </div>

      <template v-if="validationData.length === 3">
        <div id="change-count" class="mx-2 flex items-center justify-between">
          <p class="text-gray-700 text-sm">
            Showing
            <strong class="font-semibold" data-test="validation-change-count">{{
              pluralize("changed field", summarizedChangeCount, true)
            }}</strong>
            with
            <strong class="font-semibold">{{
              pluralize("addition", changeGroup.additionsCount, true)
            }}</strong>
            and
            <strong class="font-semibold">{{
              pluralize("deletion", changeGroup.removalsCount, true)
            }}</strong>
          </p>

          <div
            class="flex text-sm text-gray-400 bg-gray-100 p-0.5 rounded-lg items-center"
          >
            <button
              @click="outputFormat = 'side-by-side'"
              type="button"
              :class="
                outputFormat === 'side-by-side'
                  ? 'bg-white shadow-sm'
                  : 'hover:bg-white hover:shadow-sm'
              "
              class="p-1.5 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <span class="">Split</span>
            </button>
            <button
              @click="outputFormat = 'line-by-line'"
              type="button"
              :class="
                outputFormat === 'line-by-line'
                  ? 'bg-white shadow-sm'
                  : 'hover:bg-white hover:shadow-sm'
              "
              class="ml-0.5 p-1.5 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <span class="">Unified</span>
            </button>
          </div>
        </div>

        <ul
          id="changes-container"
          :class="
            workspaceLayout === 'topAndBottom' ? 'h-[100px]' : 'h-[450px]'
          "
          class="overflow-y-auto px-2 border-t-2 border-gray-400"
        >
          <li
            v-for="(changes, groupIndex) in groupedValidationData"
            :key="`${groupLabelFor(changes)}-${groupIndex}`"
            class="space-y-3"
          >
            <div
              v-observe-visibility="{
                callback: (isVisible, entry) =>
                  fetchContextFor(isVisible, entry, groupLabelFor(changes)),
                once: true,
              }"
              :class="groupIndex === 0 ? '' : 'mt-3'"
              class="sticky top-0 py-2 bg-white flex items-baseline justify-between z-[999999]"
            >
              <div class="flex items-center space-x-1">
                <h2
                  v-if="!isTopLevel(groupLabelFor(changes))"
                  class="text-lg text-gray-700 font-semibold"
                >
                  <template v-if="contextLabels[groupLabelFor(changes)]">{{
                    contextLabels[groupLabelFor(changes)].summary
                  }}</template>
                  <template v-else>{{
                    _.startCase(groupLabelFor(changes))
                  }}</template>
                </h2>
                <span
                  v-if="
                    !isTopLevel(groupLabelFor(changes)) &&
                    contextLabels[groupLabelFor(changes)]?.topLevelField
                  "
                  >at</span
                >
                <DataField
                  v-if="contextLabels[groupLabelFor(changes)]?.topLevelField"
                  :data-field="
                    contextLabels[groupLabelFor(changes)]?.topLevelField
                  "
                  primary-text-path="fieldContent.name"
                  secondary-text-path="fieldContentType"
                  text-classes="text-lg leading-6 font-medium"
                  text-styles=""
                />
                <TopLevelContentNavigation
                  v-if="contextLabels[groupLabelFor(changes)]?.topLevelField"
                  :top-level-field="
                    contextLabels[groupLabelFor(changes)]?.topLevelField
                  "
                  @set-active-group="setActiveGroup"
                />
              </div>
              <h4
                v-if="contextLabels[groupLabelFor(changes)]"
                class="text-xs text-gray-600 font-semibold"
              >
                {{ _.startCase(groupLabelFor(changes)) }}
              </h4>
            </div>
            <component
              v-for="(change, changeIndex) in dateSorted(changes)"
              :key="changeIndex"
              :is="change.component"
              v-bind="
                _.merge({}, change.componentProps, {
                  investmentRelations,
                  changeInvestments,
                  relatedInvestments,
                  investmentValuations,
                  outputFormat,
                  testIndex: changeIndex,
                  groupIndex: groupIndex,
                  changeIndex: changeIndex,
                  nextUp,
                  validatedChangeIds: votedIds,
                })
              "
              @has-conflicts="addConflicts"
              @resolved-conflicts="removeConflicts"
              @set-investment-relation="setInvestmentRelation"
              @viewed="(payload) => view(payload)"
              @validation-submitted="
                (payload) =>
                  submit(
                    payload,
                    groupIndex,
                    finalGroupIndex,
                    changeIndex,
                    currentGroupFinalChangeIndex,
                  )
              "
            />
          </li>
        </ul>
      </template>
      <div v-else class="mt-6 flex items-center justify-center">
        <GridLoader :loading="true" size="10px" color="#4338ca" />
      </div>
    </div>
    <div v-else-if="isAdmin" class="w-full h-full">
      <div
        class="h-full flex items-center justify-center max-w-2xl mx-auto py-16 px-4"
        data-test="available-validations-admin-empty-state"
      >
        <div class="text-center">
          <h1
            class="text-base leading-6 font-semibold text-indigo-600 tracking-wide uppercase"
          >
            Admin Validations
          </h1>
          <p class="mt-1 text-3xl leading-10 font-extrabold text-gray-900">
            Select a validation group
          </p>
          <p class="max-w-xl mt-5 mx-auto text-lg leading-7 text-gray-500">
            Use the dashboard to select a new change group to review.
          </p>
        </div>
      </div>
    </div>
    <div v-else class="w-full h-full">
      <div
        class="h-full flex items-center justify-center max-w-2xl mx-auto py-16 px-4"
        data-test="available-validations-empty-state"
      >
        <div class="text-center">
          <h1
            class="text-base leading-6 font-semibold text-indigo-600 tracking-wide uppercase"
          >
            Available Validations
          </h1>
          <p class="mt-1 text-3xl leading-10 font-extrabold text-gray-900">
            Earn money and reputation by reviewing crowdsourced data
          </p>
          <p class="max-w-xl mt-5 mx-auto text-lg leading-7 text-gray-500">
            Validators get a free peek at the freshest data.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ChangeDiff from "@/components/crowdsourcing/ChangeDiff.vue";
import HasManyModelDiff from "@/components/crowdsourcing/HasManyModelDiff.vue";
import RollUpDiff from "@/components/crowdsourcing/RollUpDiff.vue";
import ValidationActions from "@/components/crowdsourcing/ValidationActions.vue";
import ChangeGroupContext from "@/components/crowdsourcing/ChangeGroupContext.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import TopLevelContentNavigation from "@/components/crowdsourcing/TopLevelContentNavigation.vue";
import GridLoader from "vue-spinner/src/GridLoader.vue";
import {
  computed,
  onMounted,
  ref,
  watch,
  markRaw,
  onBeforeUnmount,
  nextTick,
} from "vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useAdminModeStore } from "@/stores/adminMode";
import { useModalStore } from "@/stores/modal";
import { useUnlockerStore } from "@/stores/unlocker";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useAvailableValidationsChannelStore } from "@/stores/availableValidationsChannel";
import { useTopLevelContentFieldsStore } from "@/stores/topLevelContentFields";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useRouter, useRoute } from "vue-router";
import api from "@/router/api";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import {
  standaloneGroupingData,
  rollUpGroupingData,
  hasManyGroupingData,
} from "@/components/crowdsourcing/validationGrouping";

import _ from "lodash";
import pluralize from "pluralize";
import ValidationProgressBar from "./ValidationProgressBar.vue";

const changeGroup = ref(null);
const validationData = ref([]);
const contextLabels = ref({});
const outputFormat = ref("side-by-side");
const loaded = ref(false);
const actionInProgress = ref(false);
const viewedChangeIds = ref([]);
const validatedChangeIds = ref([]);
const nextUp = ref({
  groupIndex: 0,
  changeIndex: 0,
});
const fetchingValidationData = ref(false);
const conflictChangeIds = ref([]);
const investmentRelations = ref({});
const changeInvestments = ref({});
const relatedInvestments = ref({});
const investmentValuations = ref({});

const topLevelContentFieldsStore = useTopLevelContentFieldsStore();
const secondaryPanelStore = useSecondaryPanelStore();
const layoutStore = useWorkspaceLayoutStore();
const {
  isDesktop,
  windowWidth,
  windowHeight,
  workspaceLayout,
  workspaceResized,
} = storeToRefs(layoutStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { existingChangeGroupId } = storeToRefs(changeGroupStore);
const adminModeStore = useAdminModeStore();
const { isAdminDashboard } = storeToRefs(adminModeStore);
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyIdParam } = storeToRefs(propertyDiagramStore);
const userStore = useUserStore();
const { signedIn, isAdmin } = storeToRefs(userStore);
const availableValidationsChannelStore = useAvailableValidationsChannelStore();
const { availableValidationsChannelDataQueue, activeValidationChangeGroupId } =
  storeToRefs(availableValidationsChannelStore);

const currentGroup = computed(() =>
  _.get(groupedValidationData.value, `[${nextUp.value.groupIndex}]`),
);
const finalGroupIndex = computed(() => _.size(groupedValidationData.value) - 1);
const currentGroupFinalChangeIndex = computed(
  () => _.size(currentGroup.value) - 1,
);
const groupedValidationData = computed(() => {
  const flattened = _.flatten(validationData.value);

  const rawGrouped = _.groupBy(flattened, function (changeDataObject) {
    const groupingData = changeDataObject.groupingData;

    return `${groupingData.contentType}-${groupingData.contentId}`;
  });

  return _.orderBy(
    rawGrouped,
    [
      function (changes) {
        const mins = changes.map((change) => {
          if (change.changeType === "standalone") {
            return change.componentProps.dataFieldChange.sortDate;
          } else {
            return change.componentProps.validationData.sortDate;
          }
        });
        const min = _.min(mins);

        return min;
      },
    ],
    ["asc"],
  );
});

const hasConflicts = computed(() => {
  return conflictChangeIds.value.length > 0;
});
const adminApprovable = computed(() => {
  return isAdmin.value;
});
const summarizedChangeCount = computed(() => {
  return (
    textDiffChangeIds.value.length +
    groupFieldCount(modelDataFieldGroups.value) +
    groupFieldCount(standaloneRollUpDataFieldGroups.value)
  );
});
const modelDataFieldGroups = computed(() => {
  return _.get(changeGroup.value, "modelDataFieldGroups", {});
});
const standaloneRollUpDataFieldGroups = computed(() => {
  return _.get(changeGroup.value, "standaloneRollUpDataFieldGroups", {});
});
const textDiffChangeIds = computed(() => {
  return _.concat(
    _.get(changeGroup.value, "standaloneDataFieldChangeIds", []),
    _.get(changeGroup.value, "hasOneModelDataFieldChangeIds", []),
  );
});
const acceptedChangeIds = computed(() => {
  return validatedChangeIds.value.flatMap(({ changeIds, validationInput }) => {
    if (validationInput === "Accepted") {
      return changeIds;
    } else {
      return [];
    }
  });
});
const rejectedChangeIds = computed(() => {
  return validatedChangeIds.value.flatMap(({ changeIds, validationInput }) => {
    if (validationInput === "Rejected") {
      return changeIds;
    } else {
      return [];
    }
  });
});
const skippedChangeIds = computed(() => {
  return validatedChangeIds.value.flatMap(({ changeIds, validationInput }) => {
    if (validationInput === "Skipped") {
      return changeIds;
    } else {
      return [];
    }
  });
});
const votedIds = computed(() =>
  _.union(
    acceptedChangeIds.value,
    rejectedChangeIds.value,
    skippedChangeIds.value,
  ),
);
const allViewed = computed(() => {
  const diff = _.difference(allChangeIds.value, _.uniq(viewedChangeIds.value));

  return diff.length === 0;
});
const allChangeIds = computed(() => {
  const modelChangeIds = _.reduce(
    modelDataFieldGroups.value,
    function (result, newIds) {
      return _.concat(result, newIds);
    },
    [],
  );

  return _.concat(modelChangeIds, textDiffChangeIds.value);
});
const groupedDataCount = computed(() => _.size(groupedValidationData.value));

watch(groupedDataCount, () => {
  if (groupedDataCount.value > 0) {
    setTimeout(function () {
      resizeValidationsContainer();
    }, 100);
  }
});
watch(workspaceLayout, () => {
  resizeValidationsContainer();
});
watch(windowHeight, () => {
  resizeValidationsContainer();
});
watch(windowWidth, () => {
  resizeValidationsContainer();
});
watch(workspaceResized, () => {
  if (workspaceResized.value) {
    setTimeout(function () {
      resizeValidationsContainer();
    }, 100);
  }
});

watch(availableValidationsChannelDataQueue, () => {
  checkActionableStatus();
});

watch(propertyIdParam, (val, oldVal) => {
  if (oldVal && !val) {
    activeValidationChangeGroupId.value = null;
  } else if (val && changeGroup.value) {
    activeValidationChangeGroupId.value = changeGroup.value.id;
  }
});

watch(existingChangeGroupId, () => {
  if (existingChangeGroupId.value) {
    changeGroup.value = null;
    activeValidationChangeGroupId.value = null;
    fetchExistingGroup();
  }
});
watch(changeGroup, () => {
  if (changeGroup.value) {
    fetchValidationData();
  } else {
    investmentRelations.value = {};
    changeInvestments.value = {};
    relatedInvestments.value = {};
    investmentValuations.value = {};
    viewedChangeIds.value = [];
    validatedChangeIds.value = [];
    nextUp.value = {
      groupIndex: 0,
      changeIndex: 0,
    };
  }
});

onMounted(() => {
  if (!signedIn.value) {
    logIn();
    return;
  }

  if (existingChangeGroupId.value) {
    fetchExistingGroup();
  } else {
    fetchInValidationGroup();
  }

  window.addEventListener("keyup", changeNavigationHandler);
});

onBeforeUnmount(async () => {
  if (activeValidationChangeGroupId.value) {
    activeValidationChangeGroupId.value = null;
    if (propertyIdParam.value) {
      await propertyDiagramStore.removeUnusedDiagramData();
      propertyDiagramStore.fetchDiagramData();
    }
  }

  window.removeEventListener("keyup", changeNavigationHandler);
});

const router = useRouter();
const route = useRoute();

function logIn() {
  setTimeout(() => {
    if (isDesktop.value) {
      secondaryPanelStore.setAuthenticate({ name: "SignIn" });
      router.push({
        name: route.name,
        query: {
          ...route.query,
          horizontalTab: "Authenticate",
          verticalTab: "SignIn",
        },
      });
    } else {
      router.push({ name: "SignIn" });
    }
  }, 250);
  secondaryPanelStore.closeValidations();
}

function changeNavigationHandler(e) {
  switch (e.key) {
    case "ArrowDown":
      {
        const { groupIndex, changeIndex } = nextUp.value;
        nextUp.value = {
          groupIndex: nextGroup(
            groupIndex,
            finalGroupIndex.value,
            changeIndex,
            currentGroupFinalChangeIndex.value,
          ),
          changeIndex: nextChange(
            groupIndex,
            finalGroupIndex.value,
            changeIndex,
            currentGroupFinalChangeIndex.value,
          ),
        };
      }
      break;
    case "ArrowUp":
      {
        const { groupIndex, changeIndex } = nextUp.value;
        nextUp.value = {
          groupIndex: previousGroup(groupIndex, changeIndex),
          changeIndex: previousChange(groupIndex, changeIndex),
        };
      }
      break;
  }
}

function addConflicts(ids) {
  const newChangeIds = _.union(ids, conflictChangeIds.value);

  conflictChangeIds.value = newChangeIds;
}
function removeConflicts(ids) {
  const newChangeIds = _.difference(ids, conflictChangeIds.value);

  conflictChangeIds.value = newChangeIds;
}
function groupFieldCount(group) {
  return _.chain(group).values().flatten().size().value();
}
function groupLabelFor(group) {
  const groupingData = _.head(group).groupingData;

  return `${groupingData.contentType}-${groupingData.contentId}`;
}
function dateSorted(changes) {
  return _.sortBy(changes, [
    function (change) {
      if (change.changeType === "standalone") {
        return change.componentProps.dataFieldChange.sortDate;
      } else {
        return change.componentProps.validationData.sortDate;
      }
    },
  ]);
}
function checkActionableStatus() {
  if (changeGroup.value) {
    api
      .get(
        `change_group_actionable_status/${changeGroup.value.id}?is_admin=${isAdminDashboard.value}`,
      )
      .then((json) => {
        const isActionable = json.data;

        if (!isActionable) {
          fetchInValidationGroup();
        }
      });
  }
}
function setActiveGroup() {
  if (changeGroup.value) {
    activeValidationChangeGroupId.value = changeGroup.value.id;
  }
}
function fetchInValidationGroup() {
  changeGroup.value = null;
  existingChangeGroupId.value = null;
  activeValidationChangeGroupId.value = null;

  if (!isAdmin.value) {
    api.get(`next_available_validation`).then((json) => {
      changeGroup.value = json.data;

      if (propertyIdParam.value && changeGroup.value) {
        activeValidationChangeGroupId.value = changeGroup.value.id;
      }

      loaded.value = true;
      actionInProgress.value = false;
    });
  } else {
    loaded.value = true;
    actionInProgress.value = false;
  }
}
function fetchExistingGroup() {
  if (existingChangeGroupId.value) {
    api
      .get(
        `crowdsourced_change_groups/${existingChangeGroupId.value}?is_admin=${isAdminDashboard.value}`,
      )
      .then((json) => {
        changeGroup.value = json.data;

        if (propertyIdParam.value && changeGroup.value) {
          activeValidationChangeGroupId.value = changeGroup.value.id;
        }

        loaded.value = true;
      });
  }
}
async function fetchContextFor(isVisible, entry, groupLabel) {
  if (isVisible) {
    const [contentType, contentId] = _.split(groupLabel, "-");

    const fetchable =
      contentType &&
      _.trim(contentType) !== "" &&
      contentId &&
      _.trim(contentId) !== "";

    if (fetchable) {
      const contextResponse =
        await topLevelContentFieldsStore.fetchValidationContext(
          contentType,
          contentId,
        );
      contextLabels.value[groupLabel] = contextResponse;
      contextLabels.value[groupLabel].topLevelField =
        findTopLevelFieldFor(groupLabel);
    }
  }
}
function isTopLevel(groupLabel) {
  const [contentType] = _.split(groupLabel, "-");

  return _.includes(
    [
      "Property",
      "Company",
      "Contact",
      "InvestmentGroup",
      "SpaceAvailabilityGroup",
    ],
    contentType,
  );
}
function findTopLevelFieldFor(groupLabel) {
  const metaDatas = _.head(
    groupedValidationData.value.filter((changeArray) => {
      return groupLabelFor(changeArray) === groupLabel;
    }),
  );

  if (metaDatas) {
    const containingObject = _.find(metaDatas, function (metaDataObject) {
      return (
        _.get(
          metaDataObject,
          "componentProps.dataFieldChange.topLevelContent",
        ) ||
        _.get(metaDataObject, "componentProps.validationData.topLevelContent")
      );
    });

    return (
      _.get(
        containingObject,
        "componentProps.dataFieldChange.topLevelContent",
      ) ||
      _.get(containingObject, "componentProps.validationData.topLevelContent")
    );
  } else {
    return null;
  }
}
async function fetchValidationData() {
  if (!fetchingValidationData.value) {
    validationData.value = [];
    fetchingValidationData.value = true;
    const standaloneData = await fetchStandaloneData();
    validationData.value.push(standaloneData);
    const rollUpData = await fetchRollUpData();
    validationData.value.push(rollUpData);
    const hasManyData = await fetchHasManyData();
    validationData.value.push(hasManyData);
    fetchingValidationData.value = false;
  }
}

async function fetchStandaloneData() {
  let fetchedData = [];
  for (const id of textDiffChangeIds.value) {
    const response = await api.get(`data_field_change_groups/${id}`);

    fetchedData.push({
      changeType: "standalone",
      groupingData: standaloneGroupingData(response.data),
      component: markRaw(ChangeDiff),
      componentProps: {
        dataFieldChange: response.data,
      },
    });
  }
  return fetchedData;
}
async function fetchRollUpData() {
  let fetchedData = [];
  for (const apiLookupKey in standaloneRollUpDataFieldGroups.value) {
    const response = await api.get(
      `standalone_roll_up_validations/${apiLookupKey}?change_group_id=${changeGroup.value.id}`,
    );
    fetchedData.push({
      changeType: "rollUp",
      groupingData: rollUpGroupingData(apiLookupKey),
      component: markRaw(RollUpDiff),
      componentProps: {
        validationData: response.data,
        changeGroupId: changeGroup.value.id,
        apiLookupKey,
        changeIds: standaloneRollUpDataFieldGroups.value[apiLookupKey],
      },
    });
  }
  return fetchedData;
}
async function fetchHasManyData() {
  let fetchedData = [];
  for (const apiLookupKey in modelDataFieldGroups.value) {
    const response = await api.get(
      `has_many_model_validations/${apiLookupKey}?change_group_id=${changeGroup.value.id}`,
    );
    fetchedData.push({
      changeType: "hasMany",
      groupingData: hasManyGroupingData(apiLookupKey),
      component: markRaw(HasManyModelDiff),
      componentProps: {
        validationData: response.data,
        changeGroupId: changeGroup.value.id,
        apiLookupKey,
        changeIds: modelDataFieldGroups.value[apiLookupKey],
      },
    });
  }
  return fetchedData;
}

const modalStore = useModalStore();
const { modalPayload } = storeToRefs(modalStore);
const unlockerStore = useUnlockerStore();
const { upgradeSuccessful } = storeToRefs(unlockerStore);

function acceptAll() {
  const payload = { changeGroupId: changeGroup.value.id };

  actionInProgress.value = true;

  subscribeInterceptor({
    apiRequestFunc: () => api.post(`validation_accept_changes`, payload),
    successCallback: fetchInValidationGroup,
    modalPayloadRef: modalPayload,
    upgradeSuccessfulRef: upgradeSuccessful,
    afterSubscribe: "apiRequest",
    context: "validating",
  });
}
function approveAll() {
  if (adminApprovable.value) {
    const payload = {
      changeGroupId: changeGroup.value.id,
      adminOverride: "override",
    };

    actionInProgress.value = true;

    api.post(`validation_accept_changes`, payload).then(() => {
      fetchInValidationGroup();
    });
  }
}
function rejectAll() {
  const payload = {
    changeGroupId: changeGroup.value.id,
    conflictChangeIds: conflictChangeIds.value,
  };

  actionInProgress.value = true;

  subscribeInterceptor({
    apiRequestFunc: () => api.post(`validation_reject_changes`, payload),
    successCallback: fetchInValidationGroup,
    modalPayloadRef: modalPayload,
    upgradeSuccessfulRef: upgradeSuccessful,
    afterSubscribe: "apiRequest",
    context: "validating",
  });
}
function disapproveAll() {
  if (adminApprovable.value) {
    const payload = {
      changeGroupId: changeGroup.value.id,
      conflictChangeIds: conflictChangeIds.value,
      adminOverride: "override",
    };

    actionInProgress.value = true;

    api.post(`validation_reject_changes`, payload).then(() => {
      fetchInValidationGroup();
    });
  }
}
function skipAll() {
  const payload = {
    changeGroupId: changeGroup.value.id,
    conflictChangeIds: conflictChangeIds.value,
  };

  actionInProgress.value = true;

  api.post(`validation_skip_changes`, payload).then(() => {
    fetchInValidationGroup();
  });
}

function setInvestmentRelation({
  relatedContentKey,
  assetId,
  assetType,
  investmentId,
}) {
  if (relatedContentKey) {
    const existingAsset = investmentRelations.value[relatedContentKey];
    const existingInvestment = changeInvestments.value[relatedContentKey];

    if (!existingAsset && assetType && assetId) {
      const combinedKey = `${assetType}-${assetId}`;
      investmentRelations.value[relatedContentKey] = combinedKey;

      fetchRelatedInvestmentsFor(assetType, assetId);
    }

    if (!existingInvestment && investmentId) {
      changeInvestments.value[relatedContentKey] = investmentId;
    }
  }
}

async function fetchRelatedInvestmentsFor(assetType, assetId) {
  const assetKey = `${assetType}-${assetId}`;
  const existingInvestments = relatedInvestments.value[assetKey];

  if (!_.isArray(existingInvestments)) {
    let response = null;

    if (assetType === "InvestmentGroup") {
      response = await api.get(
        `investment_group_investments/${assetId}?scope=validation`,
      );
    } else {
      response = await api.get(`asset_investments/${assetType}/${assetId}`);
    }

    if (response?.data) {
      relatedInvestments.value[assetKey] = response.data;
      fetchLatestValuationsFor(
        response.data.map((investmentField) => investmentField.fieldContentId),
      );
    }
  }
}

async function fetchLatestValuationsFor(investmentIds) {
  for (const id of investmentIds) {
    const response = await api.get(`latest_investment_valuation/${id}`);

    if (response?.data) {
      investmentValuations.value[id] = response.data;
    }
  }
}

function view(payload) {
  viewedChangeIds.value = _.union(
    payload?.changeIds || [],
    viewedChangeIds.value,
  );
}

function submit(
  payload,
  groupIndex,
  finalGroupIndex,
  changeIndex,
  finalChangeIndex,
) {
  validatedChangeIds.value.push(payload);
  nextUp.value = {
    groupIndex: nextGroup(
      groupIndex,
      finalGroupIndex,
      changeIndex,
      finalChangeIndex,
    ),
    changeIndex: nextChange(
      groupIndex,
      finalGroupIndex,
      changeIndex,
      finalChangeIndex,
    ),
  };
}

function nextGroup(groupIndex, finalGroupIndex, changeIndex, finalChangeIndex) {
  const isEnd =
    groupIndex === finalGroupIndex && changeIndex === finalChangeIndex;
  const isLastChange = changeIndex === finalChangeIndex;

  if (isEnd) {
    return groupIndex;
  } else if (isLastChange) {
    return groupIndex + 1;
  } else {
    return groupIndex;
  }
}

function previousGroup(groupIndex, changeIndex) {
  const isBeginning = groupIndex === 0 && changeIndex === 0;
  const isFirstChange = changeIndex === 0;

  if (isBeginning) {
    return 0;
  } else if (isFirstChange) {
    return groupIndex - 1;
  } else {
    return groupIndex;
  }
}

function nextChange(
  groupIndex,
  finalGroupIndex,
  changeIndex,
  finalChangeIndex,
) {
  const isEnd =
    groupIndex === finalGroupIndex && changeIndex === finalChangeIndex;
  const isLastChange = changeIndex === finalChangeIndex;

  if (isEnd) {
    return changeIndex;
  } else if (isLastChange) {
    return 0;
  } else {
    return changeIndex + 1;
  }
}

function previousChange(groupIndex, changeIndex) {
  const isBeginning = groupIndex === 0 && changeIndex === 0;
  const isFirstChange = changeIndex === 0;

  if (isBeginning) {
    return 0;
  } else if (isFirstChange) {
    const previousGroup = _.get(
      groupedValidationData.value,
      `[${groupIndex - 1}]`,
    );
    const previousGroupFinalChangeIndex = _.size(previousGroup) - 1;
    return previousGroupFinalChangeIndex;
  } else {
    return changeIndex - 1;
  }
}

const resizeValidationsContainer = _.debounce(setChangesContainerHeight, 500);

async function setChangesContainerHeight() {
  let changesContainerEl = document.getElementById("changes-container");

  if (validationData.value.length === 3 && changesContainerEl) {
    changesContainerEl.style.height =
      workspaceLayout.value === "topAndBottom" ? `100px` : `450px`;

    await nextTick();

    changesContainerEl = document.getElementById("changes-container");
    const availableValidationsEl = document.getElementById(
      "available-validations-container",
    );
    const changeHeaderEl = document
      .getElementById("change-group-header")
      ?.getBoundingClientRect()?.height;
    const changeCountEl = document
      .getElementById("change-count")
      ?.getBoundingClientRect()?.height;
    const allHeights = _.compact([changeHeaderEl, changeCountEl]);
    const elsHeight = _.sum(allHeights);
    const containerHeight = Math.max(
      availableValidationsEl.scrollHeight,
      availableValidationsEl.offsetHeight,
    );
    const ratio = workspaceLayout.value === "topAndBottom" ? 0.8 : 0.945;
    const calculatedHeight = (containerHeight - elsHeight) * ratio;

    if (elsHeight) {
      changesContainerEl.style.height = `${_.round(calculatedHeight)}px`;
    }
  }

  if (isAdminDashboard.value) {
    workspaceResized.value = false;
  }
}
</script>

<style scoped>
:deep(.code-diff-view) {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
