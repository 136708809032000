import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import selfSelected from "@/components/property-diagram/diagramBlockSelfSelected";
import _ from "lodash";

export const usePropertyDiagramContentSelectionStore = defineStore(
  "propertyDiagramContentSelection",
  () => {
    const propertyDiagramStore = usePropertyDiagramStore();
    const {
      landCoveringUnits,
      propertyDiagramSelected,
      propertyDiagramAdded,
      propertyDiagramAddedTypes,
      propertyDiagramAdding,
      propertyDiagramEditAction,
      propertyDiagramLastAdded,
      propertyDiagramSelectedPropertyId,
      propertyDiagramSelectedLandCoveringId,
      propertyDiagramSelectedParcelId,
      selectedPropertyIsAirLayer,
    } = storeToRefs(propertyDiagramStore);

    function propertyIsSelected(propertyId) {
      return propertyId === propertyDiagramSelectedPropertyId.value;
    }

    function alreadyAdded(dataField) {
      if (dataField) {
        const standardizedField = dataField.localId
          ? dataField
          : dataField.coveringDataField;
        const localIdMatch = _.find(propertyDiagramAdded.value, {
          localId: standardizedField.localId,
        });
        const floorAreaMatch =
          standardizedField.fieldContentType === "FloorArea" &&
          _.find(propertyDiagramAdded.value, {
            fieldContentType: "FloorArea",
            fieldContentId: standardizedField.fieldContentId,
          });

        if (
          _.includes(
            ["addCondo", "addCoop"],
            _.get(propertyDiagramEditAction.value, "action"),
          )
        ) {
          return localIdMatch || floorAreaMatch;
        } else {
          return localIdMatch;
        }
      } else {
        return false;
      }
    }
    function removeFromAdded(dataField) {
      const floorAreaMatch =
        dataField.fieldContentType === "FloorArea" &&
        _.find(propertyDiagramAdded.value, {
          fieldContentType: "FloorArea",
          fieldContentId: dataField.fieldContentId,
        });

      if (
        _.includes(
          ["addCondo", "addCoop"],
          _.get(propertyDiagramEditAction.value, "action"),
        )
      ) {
        if (floorAreaMatch) {
          return _.differenceBy(
            propertyDiagramAdded.value,
            [dataField],
            "fieldContentId",
          );
        } else {
          return _.differenceBy(
            propertyDiagramAdded.value,
            [dataField],
            "localId",
          );
        }
      } else {
        return _.differenceBy(
          propertyDiagramAdded.value,
          [dataField],
          "localId",
        );
      }
    }
    function fieldToAdd(dataField) {
      return alreadyAdded(dataField)
        ? removeFromAdded(dataField)
        : _.unionBy(propertyDiagramAdded.value, [dataField], "localId");
    }
    function addMultipleViaShiftClick(payload) {
      if (payload && payload.shiftClickLevelsToAdd) {
        propertyDiagramLastAdded.value = payload.dataField;
        propertyDiagramStore.pushToPropertyDiagramAdded(
          payload.shiftClickLevelsToAdd,
        );
      }
    }
    function leftShiftableAirRights() {
      if (
        selectedPropertyIsAirLayer.value &&
        _.get(
          propertyDiagramSelected.value,
          "dataField.fieldContentSubType",
          null,
        ) === "unused_development_rights"
      ) {
        return airRightsIsShiftable("left");
      } else {
        return false;
      }
    }
    function rightShiftableAirRights() {
      if (
        selectedPropertyIsAirLayer.value &&
        _.get(
          propertyDiagramSelected.value,
          "dataField.fieldContentSubType",
          null,
        ) === "unused_development_rights"
      ) {
        return airRightsIsShiftable("right");
      } else {
        return false;
      }
    }
    function leftShiftableFeeSimple(propertyId) {
      if (
        _.get(
          propertyDiagramSelected.value,
          "dataField.fieldContentSubType",
          null,
        ) === "fee_simple"
      ) {
        return feeSimpleIsShiftable("left", propertyId);
      } else {
        return false;
      }
    }
    function rightShiftableFeeSimple(propertyId) {
      if (
        _.get(
          propertyDiagramSelected.value,
          "dataField.fieldContentSubType",
          null,
        ) === "fee_simple"
      ) {
        return feeSimpleIsShiftable("right", propertyId);
      } else {
        return false;
      }
    }
    function airRightsIsShiftable(direction) {
      const airLayerPropertyId = propertyDiagramSelectedPropertyId.value;
      const airRightsId = _.get(
        propertyDiagramSelected.value,
        "dataField.fieldContentId",
        null,
      );
      const hasMultiParcelBuildings = _.includes(
        propertyDiagramStore.feeSimplesWithMultiParcelLandCoverings(
          airLayerPropertyId,
        ),
        airRightsId,
      );
      const order = _.get(
        propertyDiagramSelected.value,
        "dataField.order",
        null,
      );
      const orderableCount = _.compact(
        propertyDiagramStore
          .orderedAirRights(airLayerPropertyId)
          .map((df) => _.isNumber(df.order)),
      ).length;

      if (orderableCount <= 1) {
        return false;
      } else if (airRightsId && _.isNumber(order) && !hasMultiParcelBuildings) {
        if (direction === "left") {
          return order !== 0;
        } else {
          return (
            order <
            propertyDiagramStore.orderedAirRights(airLayerPropertyId).length - 1
          );
        }
      } else {
        return false;
      }
    }
    function feeSimpleIsShiftable(direction, propertyId) {
      const feeId = _.get(
        propertyDiagramSelected.value,
        "dataField.fieldContentId",
        null,
      );
      const loneEasement = propertyDiagramStore.hasLoneEasement(propertyId);
      const hasReciprocalEasement = !!_.get(
        propertyDiagramSelected.value,
        "dataField.fieldContent.superiorId",
        null,
      );
      const hasMultiParcelBuildings = _.includes(
        propertyDiagramStore.feeSimplesWithMultiParcelLandCoverings(propertyId),
        feeId,
      );
      const order = _.get(
        propertyDiagramSelected.value,
        "dataField.order",
        null,
      );
      const orderableCount = _.compact(
        propertyDiagramStore
          .orderedFeeSimples(propertyId)
          .map((df) => _.isNumber(df.order)),
      ).length;

      if (orderableCount <= 1) {
        return false;
      } else if (
        feeId &&
        _.isNumber(order) &&
        (!hasReciprocalEasement || loneEasement) &&
        !hasMultiParcelBuildings
      ) {
        if (direction === "left") {
          return order !== 0;
        } else {
          return (
            order <
            propertyDiagramStore.orderedFeeSimples(propertyId).length - 1
          );
        }
      } else {
        return false;
      }
    }
    function leftShiftableLandCovering(propertyId) {
      if (
        _.get(
          propertyDiagramSelected.value,
          "dataField.fieldContentType",
          null,
        ) === "LandCovering"
      ) {
        return landCoveringIsShiftable("left", propertyId);
      } else {
        return false;
      }
    }
    function rightShiftableLandCovering(propertyId) {
      if (
        _.get(
          propertyDiagramSelected.value,
          "dataField.fieldContentType",
          null,
        ) === "LandCovering"
      ) {
        return landCoveringIsShiftable("right", propertyId);
      } else {
        return false;
      }
    }
    function landCoveringIsShiftable(direction, propertyId) {
      const feeId = _.get(
        propertyDiagramSelected.value,
        "dataField.decoratingContentId",
        null,
      );
      const landCoveringId = _.get(
        propertyDiagramSelected.value,
        "dataField.fieldContentId",
        null,
      );
      const orderedLandCoveringsWrapper = _.find(
        propertyDiagramStore.feeSingleParcelLandCoveringOrderings(propertyId),
        { feeId },
      );

      if (feeId && landCoveringId && orderedLandCoveringsWrapper) {
        const landCoveringWithMeta = _.find(
          orderedLandCoveringsWrapper.landCoverings,
          {
            coveringId: landCoveringId,
          },
        );

        return _.get(landCoveringWithMeta, `${direction}Shiftable`, false);
      } else {
        return false;
      }
    }
    function selectedPayloadShiftableMeta(payload, propertyId) {
      if (_.get(payload, "dataField.fieldContentType") === "LandCovering") {
        return {
          leftShiftableLandCovering: leftShiftableLandCovering(propertyId),
          rightShiftableLandCovering: rightShiftableLandCovering(propertyId),
        };
      } else if (
        _.get(payload, "dataField.fieldContentSubType") === "fee_simple"
      ) {
        return {
          leftShiftableFeeSimple: leftShiftableFeeSimple(propertyId),
          rightShiftableFeeSimple: rightShiftableFeeSimple(propertyId),
        };
      } else if (
        _.get(payload, "dataField.fieldContentSubType") ===
        "unused_development_rights"
      ) {
        return {
          leftShiftableAirRights: leftShiftableAirRights(propertyId),
          rightShiftableAirRights: rightShiftableAirRights(propertyId),
        };
      } else {
        return {};
      }
    }
    function hasAirRights(dataField, propertyId) {
      const airRights = propertyDiagramStore.scopedFieldsFor(
        "Air Rights",
        propertyId,
      );
      return _.find(airRights, function (df) {
        return (
          _.get(df, "fieldContent.feeSimpleId", null) ===
          _.get(dataField, "fieldContentId", null)
        );
      });
    }
    function hasUnitRight(dataField) {
      switch (dataField.fieldContentType) {
        case "LandCovering":
          return _.find(landCoveringUnits.value, function (df) {
            return df.fieldContentId === dataField.fieldContentId;
          });
        case "FloorArea":
        default:
          return false;
      }
    }

    function hypothetical(standardizedField = null) {
      if (!standardizedField) return true;

      if (
        _.includes(
          ["LandCovering", "FloorArea"],
          standardizedField.fieldContentType,
        )
      ) {
        const state = standardizedField.fieldContent.state;

        if (_.isNil(state)) return true;
        else {
          switch (state) {
            case "hypothetical":
            case "construction_proposed":
            case "construction_approved":
            case "construction_permitted":
              return true;
            default:
              return false;
          }
        }
      } else {
        return true;
      }
    }

    function isSelected(dataField) {
      return selfSelected(
        _.get(dataField, "fieldContent"),
        propertyDiagramSelected.value,
      );
    }

    function propertyRightSelectionBackgroundStylingFor({
      propertyId,
      dataField,
      ignore = false,
      internalName = null,
    }) {
      if (dataField) {
        const selectedText = "text-gray-700 font-semibold border-gray-700";
        const unselectedText =
          "text-gray-500 font-medium border-gray-300 hover:border-gray-400";
        const selectedStyling =
          "border-2 border-dashed border-green-500 text-white bg-green-200 cursor-pointer";
        const selectableStyling =
          "border-2 border-dashed border-indigo-500 text-gray-300 bg-gray-50 hover:bg-indigo-100 hover:text-indigo-300 hover:border-green-500 cursor-pointer";
        const standardizedField = dataField.localId
          ? dataField
          : dataField.coveringDataField;
        const recordType = _.get(standardizedField, "fieldContentType", null);
        const subType = _.get(standardizedField, "fieldContent.type", null);

        if (
          (alreadyAdded(standardizedField) &&
            !selectedPropertyIsAirLayer.value) ||
          (alreadyAdded(standardizedField) &&
            selectedPropertyIsAirLayer.value &&
            propertyIsSelected(propertyId))
        ) {
          return `${selectedText} ${selectedStyling}`;
        } else if (
          (propertyDiagramAdding.value.additionType &&
            propertyIsSelected(propertyId)) ||
          propertyDiagramAdding.value.additionSubType === "batch edit"
        ) {
          switch (propertyDiagramAdding.value.additionType) {
            case "landCovering":
              switch (recordType) {
                case "PropertyRight":
                  switch (subType) {
                    case "Fee simple":
                      return `${unselectedText} ${selectableStyling}`;
                    case "Unused Development Rights": {
                      if (selectedPropertyIsAirLayer.value) {
                        return `${unselectedText} ${selectableStyling}`;
                      } else {
                        return null;
                      }
                    }
                    default:
                      return null;
                  }
                case "LandCovering":
                default:
                  return null;
              }
            case "floor area":
              switch (recordType) {
                case "PropertyRight":
                  switch (subType) {
                    case "Fee simple": {
                      if (hasAirRights(standardizedField, propertyId)) {
                        return null;
                      } else {
                        return `${unselectedText} ${selectableStyling}`;
                      }
                    }
                    case "Unused Development Rights": {
                      if (selectedPropertyIsAirLayer.value) {
                        return null;
                      } else {
                        return `${unselectedText} ${selectableStyling}`;
                      }
                    }
                    default:
                      return null;
                  }
                case "LandCovering": {
                  if (
                    _.get(
                      standardizedField,
                      "fieldContent.hasLandCoveringLevels",
                      false,
                    )
                  ) {
                    return null;
                  } else {
                    return `${unselectedText} ${selectableStyling}`;
                  }
                }
                default:
                  return `${unselectedText} ${selectableStyling}`;
              }
            case "landCoveringGrouping":
              switch (recordType) {
                case "LandCovering":
                  return `${unselectedText} ${selectableStyling}`;
                default:
                  return null;
              }
            case "property feature":
              switch (recordType) {
                case "PropertyRight":
                  switch (subType) {
                    case "Fee simple": {
                      return `${unselectedText} ${selectableStyling}`;
                    }
                    case "Unused Development Rights": {
                      if (selectedPropertyIsAirLayer.value) {
                        return `${unselectedText} ${selectableStyling}`;
                      } else {
                        return null;
                      }
                    }
                    default:
                      return null;
                  }
                case "LandCovering":
                  return `${unselectedText} ${selectableStyling}`;
                default:
                  return null;
              }
            case null: {
              if (
                propertyDiagramAdding.value.additionSubType === "batch edit"
              ) {
                switch (recordType) {
                  case "PropertyRight":
                    return null;
                  case "LandCovering": {
                    if (
                      _.includes(propertyDiagramAddedTypes.value, recordType) ||
                      propertyDiagramAddedTypes.value.length === 0
                    ) {
                      return `${unselectedText} ${selectableStyling}`;
                    } else {
                      return null;
                    }
                  }
                  default:
                    return null;
                }
              } else {
                return null;
              }
            }
            default:
              return null;
          }
        } else {
          switch (recordType) {
            case "PropertyRight":
              switch (subType) {
                case "Fee simple":
                  if (ignore) {
                    return null;
                  } else if (
                    !selectedPropertyIsAirLayer.value &&
                    propertyIsSelected(propertyId) &&
                    _.get(propertyDiagramEditAction.value, "action", null) ===
                      "relocateLandCovering"
                  ) {
                    return `${unselectedText} ${selectableStyling}`;
                  } else {
                    return isSelected(standardizedField)
                      ? `${selectedText} text-gray-700 font-semibold border-2 bg-yellow-300`
                      : `${unselectedText} text-gray-500 font-medium border-2 bg-yellow-200 hover:bg-yellow-300`;
                  }
                case "Leasehold": {
                  if (isSelected(standardizedField)) {
                    switch (
                      dataField.fieldContent.layersFromNearestControlling
                    ) {
                      case 1:
                        return `${selectedText} border-2 bg-yellow-300`;
                      case 2:
                        return `${selectedText} border-2 bg-orange-300`;
                      case 3:
                        return `${selectedText} border-2 bg-red-300`;
                      case 4:
                        return `${selectedText} border-2 bg-violet-300`;
                      case 5:
                        return `${selectedText} border-2 bg-fuchsia-300`;
                      default:
                        return `${selectedText} border-2 bg-yellow-300`;
                    }
                  } else {
                    switch (
                      dataField.fieldContent.layersFromNearestControlling
                    ) {
                      case 1:
                        return `${unselectedText} border-2 border-gray-300 bg-yellow-100 hover:bg-yellow-200`;
                      case 2:
                        return `${unselectedText} border-2 border-gray-300 bg-orange-100 hover:bg-orange-200`;
                      case 3:
                        return `${unselectedText} border-2 border-gray-300 bg-red-100 hover:bg-red-200`;
                      case 4:
                        return `${unselectedText} border-2 border-gray-300 bg-violet-100 hover:bg-violet-200`;
                      case 5:
                        return `${unselectedText} border-2 border-gray-300 bg-fuchsia-100 hover:bg-fuchsia-200`;
                      default:
                        return `${unselectedText} border-2 border-gray-300 bg-yellow-100 hover:bg-yellow-200`;
                    }
                  }
                }
                case "Co-op":
                  return isSelected(standardizedField)
                    ? `${selectedText} border-2 bg-cyan-300`
                    : `${unselectedText} border-2 border-gray-300 bg-cyan-100 hover:bg-cyan-200`;
                case "Condominium":
                  return isSelected(standardizedField)
                    ? `${selectedText} border-2 bg-emerald-300`
                    : `${unselectedText} border-2 border-gray-300 bg-emerald-100 hover:bg-emerald-200`;
                case "Reciprocal Easement Agreement":
                  return isSelected(standardizedField)
                    ? `${selectedText} border-2 bg-lime-300`
                    : `${unselectedText} border-2 border-gray-300 bg-lime-100 hover:bg-lime-200`;
                case "Unused Development Rights": {
                  if (
                    (selectedPropertyIsAirLayer.value &&
                      propertyIsSelected(propertyId)) ||
                    propertyDiagramStore.propertyIsAirLayer(propertyId)
                  ) {
                    if (ignore) {
                      return null;
                    } else if (
                      propertyIsSelected(propertyId) &&
                      _.get(propertyDiagramEditAction.value, "action", null) ===
                        "relocateLandCovering"
                    ) {
                      return `${unselectedText} ${selectableStyling}`;
                    } else {
                      return isSelected(standardizedField)
                        ? `${selectedText} text-gray-700 font-semibold border-2 bg-cyan-300`
                        : `${unselectedText} text-gray-500 font-medium border-2 bg-cyan-200 hover:bg-cyan-300`;
                    }
                  } else {
                    return isSelected(standardizedField)
                      ? `${selectedText} border-2 border-dashed bg-cyan-400`
                      : null;
                  }
                }
                default:
                  return null;
              }
            case "LandCovering":
              if (
                _.includes(
                  ["addLandCoveringGrouping"],
                  _.get(propertyDiagramEditAction.value, "action", null),
                )
              ) {
                return `${unselectedText} ${selectableStyling}`;
              } else if (
                _.includes(
                  ["relocateFloorArea"],
                  _.get(propertyDiagramEditAction.value, "action", null),
                )
              ) {
                if (standardizedField.fieldContent?.hasLandCoveringLevels) {
                  return null;
                } else {
                  return `${unselectedText} ${selectableStyling}`;
                }
              } else if (
                _.includes(
                  ["addCondo", "addCoop"],
                  _.get(propertyDiagramEditAction.value, "action", null),
                ) &&
                !hasUnitRight(standardizedField)
              ) {
                return `${unselectedText} ${selectableStyling}`;
              } else if (isSelected(standardizedField)) {
                const hypotheticalStyling = hypothetical(standardizedField)
                  ? "border-dashed"
                  : "";
                switch (internalName) {
                  case "Land Coverings (above-grade)":
                    return `${selectedText} ${hypotheticalStyling} border-l-2 border-r-2 border-t-2 bg-slate-200`;
                  case "Land Coverings (below-grade)":
                    return `${selectedText} ${hypotheticalStyling} border-l-2 border-r-2 border-b-2 bg-slate-200`;
                  default:
                    return `${selectedText} ${hypotheticalStyling} border-2 bg-slate-200`;
                }
              } else {
                return null;
              }
            default:
              return null;
          }
        }
      }
    }

    function feeSimpleColumnClickHandler({
      propertyId,
      dataField,
      ignore = false,
      backdrop = false,
      data = null,
    }) {
      if (dataField) {
        const standardizedField = dataField.localId
          ? dataField
          : dataField.coveringDataField;
        const recordType = _.get(standardizedField, "fieldContentType", null);
        const subType = _.get(standardizedField, "fieldContent.type", null);
        const addToSelected = () => {
          propertyDiagramLastAdded.value = standardizedField;
          propertyDiagramAdded.value = fieldToAdd(standardizedField);
        };

        if (
          (propertyDiagramAdding.value.additionType &&
            propertyIsSelected(propertyId)) ||
          propertyDiagramAdding.value.additionSubType === "batch edit"
        ) {
          switch (propertyDiagramAdding.value.additionType) {
            case "landCovering":
              switch (recordType) {
                case "PropertyRight":
                  switch (subType) {
                    case "Fee simple": {
                      addToSelected();
                      break;
                    }
                    case "Unused Development Rights":
                      if (selectedPropertyIsAirLayer.value) {
                        addToSelected();
                        break;
                      } else {
                        break;
                      }
                    default:
                      break;
                  }
                  break;
                default:
                  break;
              }
              break;
            case "floor area":
              switch (recordType) {
                case "PropertyRight":
                  switch (subType) {
                    case "Fee simple": {
                      if (hasAirRights(standardizedField, propertyId)) {
                        break;
                      } else {
                        addToSelected();
                        break;
                      }
                    }
                    case "Unused Development Rights": {
                      if (selectedPropertyIsAirLayer.value) {
                        break;
                      } else {
                        addToSelected();
                        break;
                      }
                    }
                    default:
                      break;
                  }
                  break;
                case "LandCovering": {
                  if (
                    _.get(
                      standardizedField,
                      "fieldContent.hasLandCoveringLevels",
                      false,
                    )
                  ) {
                    break;
                  } else {
                    addToSelected();
                  }
                  break;
                }
                case "FloorArea":
                case "LandCoveringDiagramVerticalGrouping":
                  break;
                case "LandCoveringLevel":
                  addToSelected();
                  addMultipleViaShiftClick(data);
                  break;
                default: {
                  addToSelected();
                  break;
                }
              }
              break;
            case "landCoveringGrouping":
              switch (recordType) {
                case "LandCovering":
                  addToSelected();
                  break;
                default:
                  break;
              }
              break;
            case "property feature":
              switch (recordType) {
                case "PropertyRight":
                  switch (subType) {
                    case "Fee simple": {
                      addToSelected();
                      break;
                    }
                    case "Unused Development Rights": {
                      if (selectedPropertyIsAirLayer.value) {
                        addToSelected();
                        break;
                      } else {
                        break;
                      }
                    }
                    default:
                      break;
                  }
                  break;
                case "LandCoveringLevel":
                  addToSelected();
                  addMultipleViaShiftClick(data);
                  break;
                case "LandCovering":
                case "FloorArea":
                  addToSelected();
                  break;
                default:
                  break;
              }
              break;
            case null: {
              if (
                propertyDiagramAdding.value.additionSubType === "batch edit"
              ) {
                switch (recordType) {
                  case "PropertyRight":
                    break;
                  case "LandCoveringLevel": {
                    if (
                      _.includes(propertyDiagramAddedTypes.value, recordType) ||
                      propertyDiagramAddedTypes.value.length === 0
                    ) {
                      addToSelected();
                      addMultipleViaShiftClick(data);
                      break;
                    } else {
                      break;
                    }
                  }
                  case "LandCovering":
                  case "FloorArea": {
                    if (
                      _.includes(propertyDiagramAddedTypes.value, recordType) ||
                      propertyDiagramAddedTypes.value.length === 0
                    ) {
                      addToSelected();
                      break;
                    } else {
                      break;
                    }
                  }
                  default:
                    break;
                }
              }
              break;
            }
            default:
              break;
          }
        } else {
          if (!ignore) {
            let payload = {
              dataField: standardizedField,
              recordType,
            };

            singleClickSelect({ propertyId, data: payload });
          } else if (backdrop) {
            propertyDiagramStore.clearSelect(propertyId);
          }
        }
      }
    }

    function singleClickSelect({ propertyId, data }) {
      const addToSelected = () => {
        propertyDiagramLastAdded.value = data.dataField;
        propertyDiagramAdded.value = fieldToAdd(data.dataField);
      };

      if (propertyDiagramEditAction.value) {
        if (
          _.includes(
            ["relocateFloorArea", "addVerticalGrouping", "addVerticalOpenings"],
            propertyDiagramEditAction.value.action,
          ) &&
          data.recordType === "LandCoveringLevel"
        ) {
          addToSelected();
          addMultipleViaShiftClick(data);
        } else if (
          _.includes(
            ["addLandCoveringGrouping"],
            propertyDiagramEditAction.value.action,
          ) &&
          data.recordType === "LandCovering"
        ) {
          addToSelected();
        } else if (
          _.includes(
            ["relocateFloorArea"],
            propertyDiagramEditAction.value.action,
          ) &&
          data.recordType === "LandCovering" &&
          !data.dataField.fieldContent?.hasLandCoveringLevels
        ) {
          addToSelected();
        } else if (
          propertyDiagramEditAction.value.action === "addLayout" &&
          data.recordType === "FloorArea"
        ) {
          addToSelected();
        } else if (
          propertyDiagramEditAction.value.action === "relocateLandCovering" &&
          data.recordType === "PropertyRight"
        ) {
          addToSelected();
        } else if (
          propertyDiagramEditAction.value.action === "relocateCondoCoop" &&
          data.recordType === "FloorArea"
        ) {
          addToSelected();
        } else if (
          _.includes(
            ["addCondo", "addCoop"],
            propertyDiagramEditAction.value.action,
          ) &&
          _.includes(["LandCovering", "FloorArea"], data.recordType) &&
          !hasUnitRight(data.dataField)
        ) {
          addToSelected();
        } else if (
          _.includes(
            ["addFloorAreaCondo", "addFloorAreaCoop"],
            propertyDiagramEditAction.value.action,
          ) &&
          data.recordType === "FloorArea"
        ) {
          addToSelected();
        } else {
          return;
        }
      } else if (
        !propertyDiagramAdding.value.additionType &&
        propertyDiagramAdding.value.additionSubType !== "batch edit"
      ) {
        propertyDiagramSelected.value = data;
        propertyDiagramSelected.value = _.merge(
          {},
          data,
          selectedPayloadShiftableMeta(data, propertyId),
        );
        propertyDiagramSelectedPropertyId.value = propertyId;

        if (data.recordType === "LandCovering") {
          propertyDiagramSelectedLandCoveringId.value =
            data.dataField.fieldContentId;
          propertyDiagramSelectedParcelId.value = null;
        } else if (
          data.recordType === "PropertyRight" &&
          _.includes(
            ["Unused Development Rights"],
            data.dataField.fieldContent.type,
          ) &&
          selectedPropertyIsAirLayer.value
        ) {
          propertyDiagramSelectedParcelId.value = data.dataField.fieldContentId;
          propertyDiagramSelectedLandCoveringId.value = null;
        } else if (
          data.recordType === "PropertyRight" &&
          _.includes(
            ["Fee simple", "Reciprocal Easement Agreement"],
            data.dataField.fieldContent.type,
          )
        ) {
          propertyDiagramSelectedParcelId.value = data.dataField.fieldContentId;
          propertyDiagramSelectedLandCoveringId.value = null;
        } else {
          propertyDiagramSelectedParcelId.value = null;
          propertyDiagramSelectedLandCoveringId.value = null;
        }
      } else if (propertyIsSelected(propertyId)) {
        feeSimpleColumnClickHandler({
          propertyId,
          dataField: data.dataField,
          data,
        });
      } else if (
        !propertyDiagramAdding.value.additionType &&
        propertyDiagramAdding.value.additionSubType === "batch edit"
      ) {
        switch (data.recordType) {
          case "FloorArea":
            addToSelected();
            break;
          default:
            console.log("batch edit add?", data.recordType);
            break;
        }
      } else {
        console.log("single click, property not selected");
      }
    }

    return {
      fieldToAdd,
      propertyIsSelected,
      alreadyAdded,
      hypothetical,
      propertyRightSelectionBackgroundStylingFor,
      feeSimpleColumnClickHandler,
      singleClickSelect,
    };
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePropertyDiagramContentSelectionStore, import.meta.hot),
  );
}
