<template>
  <div
    :id="scrollId"
    :class="isCurrent ? 'border-4 border-orange-500' : 'border border-gray-400'"
    class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200"
  >
    <div
      :class="isCurrent ? 'bg-orange-300' : 'bg-gray-200'"
      class="p-2 flex items-center justify-between text-gray-500"
    >
      <div class="flex items-center space-x-1">
        <button
          @click.prevent="toggleExpanded"
          type="button"
          class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <div class="">
          <div class="flex items-center space-x-1">
            <div
              v-if="hasConflicts"
              v-tooltip="'Conflicts present'"
              class="flex-shrink-0"
            >
              <!-- Heroicon name: mini/exclamation-triangle -->
              <svg
                class="h-6 w-6 text-orange-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <a
              v-if="fieldName"
              @click.prevent="toggleExpanded"
              href=""
              class="text-gray-700 font-bold"
              >{{ _.startCase(fieldAlias) }}</a
            >
            <span
              class="text-sm"
              :class="
                changeType === 'addition' ? 'text-green-500' : 'text-red-500'
              "
              >{{ changeType }}</span
            >
          </div>
        </div>
      </div>
      <div
        v-if="adminApprovable || validationInput"
        class="flex items-center space-x-2"
      >
        <CheckIcon
          v-if="validationInput === 'Accepted'"
          class="h-4 w-4 text-green-500"
        />
        <XMarkIcon
          v-if="validationInput === 'Rejected'"
          class="h-4 w-4 text-red-500"
        />
        <ChevronDoubleRightIcon
          v-if="validationInput === 'Skipped'"
          class="h-4 w-4 text-blue-500"
        />
        <div v-if="validationInput" :class="validationColor">
          {{ validationInput }}
        </div>
        <AdminValidationVoteSummary
          v-if="adminApprovable && validationSubmissions && !validationInput"
          :validation-submissions="validationSubmissions"
        />
      </div>
    </div>
    <template v-if="expanded && hasConflicts">
      <div class="bg-blue-50 p-2" :data-test="`conflict-source-change`">
        <div class="flex items-center space-x-2">
          <h2 class="text-gray-800 font-semibold mb-2">Conflicting changes</h2>
          <button
            v-tooltip="'Get help'"
            @click="getConflictHelp"
            class="flex items-center"
          >
            <QuestionMarkCircleIcon class="h-5 w-5 text-gray-800" />
          </button>
        </div>
        <div class="flex space-x-2">
          <DataFieldChangeUser :change-id="dataFieldChange.id" />
          <div class="min-w-0 flex-1">
            <form @submit.prevent>
              <div>
                <h2 class="text-gray-700 font-semibold text-sm mt-2">
                  Differences
                </h2>
                <code-diff
                  v-if="isStandalone || isOneOffModel"
                  :old-string="oldString"
                  :new-string="newString"
                  :file-name="fieldName"
                  :no-diff-line-feed="false"
                  diff-style="char"
                  language="plaintext"
                  :output-format="outputFormat"
                />

                <EmbeddedVisualDiff
                  :field-name="fieldName"
                  :data-field-change="dataFieldChange"
                  :output-format="outputFormat"
                  :investment-relations="investmentRelations"
                  :related-investments="relatedInvestments"
                  :investment-valuations="investmentValuations"
                  :change-investments="changeInvestments"
                  @set-investment-relation="setInvestmentRelation"
                />

                <CitationsList
                  :data-field-change="dataFieldChange"
                  :output-format="outputFormat"
                />
              </div>
              <div class="mt-2 flex items-center justify-end">
                <button
                  v-if="adminApprovable"
                  @click="approve(dataFieldChange.id)"
                  type="button"
                  class="space-x-1 relative inline-flex items-center px-3 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-green-700 hover:bg-green-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  data-test="conflict-source-change-approve-button"
                >
                  <svg
                    class="h-5 w-5 text-green-700"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span class="">Approve</span>
                </button>
                <button
                  v-else
                  @click="accept(dataFieldChange.id)"
                  type="button"
                  class="relative inline-flex items-center px-3 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-green-700 hover:bg-green-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  data-test="conflict-source-change-accept-button"
                >
                  <svg
                    class="h-5 w-5 text-green-700"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span class="">Accept</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        v-for="(conflict, index) in conflicts"
        :key="conflict.localId"
        class="bg-orange-50 p-2"
        :data-test="`conflict-${index}`"
      >
        <div class="flex space-x-2">
          <DataFieldChangeUser :change-id="conflict.latestChangeId" />
          <div class="min-w-0 flex-1">
            <form @submit.prevent>
              <div>
                <h2 class="text-gray-700 font-semibold text-sm mt-2">
                  Differences
                </h2>
                <code-diff
                  v-if="isStandalone || isOneOffModel"
                  :old-string="oldString"
                  :new-string="stringFor(conflict)"
                  :file-name="fieldName"
                  :no-diff-line-feed="false"
                  diff-style="char"
                  language="plaintext"
                  :output-format="outputFormat"
                />

                <EmbeddedVisualDiff
                  :field-name="fieldName"
                  :data-field="conflict"
                  :data-field-change="dataFieldChange"
                  :output-format="outputFormat"
                  :investment-relations="investmentRelations"
                  :change-investments="changeInvestments"
                  :related-investments="relatedInvestments"
                  :investment-valuations="investmentValuations"
                  @set-investment-relation="setInvestmentRelation"
                />

                <CitationsList
                  :data-field="conflict"
                  :data-field-change="dataFieldChange"
                  :output-format="outputFormat"
                />
              </div>
              <div class="mt-2 flex items-center justify-end">
                <button
                  v-if="adminApprovable"
                  @click="approve(conflict.latestChangeId)"
                  type="button"
                  class="space-x-1 relative inline-flex items-center px-3 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-green-700 hover:bg-green-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  :data-test="`conflict-${index}-approve-button`"
                >
                  <svg
                    class="h-5 w-5 text-green-700"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span class="">Approve</span>
                </button>
                <button
                  v-else
                  @click="accept(conflict.latestChangeId)"
                  type="button"
                  class="relative inline-flex items-center px-3 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-green-700 hover:bg-green-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500"
                  :data-test="`conflict-${index}-accept-button`"
                >
                  <svg
                    class="h-5 w-5 text-green-700"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span class="">Accept</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <ValidationGuidance
        class="py-2"
        :typed-test-index="scrollId"
        :meta-key="
          dataFieldChange?.after?.metaKey || dataFieldChange?.before?.metaKey
        "
        :change-type="dataFieldChange?.changeType"
      />

      <ValidationActions
        class="flex flex-col pt-2"
        :focal-change="isCurrent"
        :action-in-progress="actionInProgress"
        :admin-approvable="adminApprovable"
        :has-conflicts="hasConflicts"
        :change-count="conflicts.length"
        @disapprove="disapproveAll"
        @reject="rejectAll"
        @skip="skipAll"
      />
    </template>
    <div v-else-if="expanded" class="flex flex-col">
      <div class="px-2">
        <h2 class="text-gray-700 font-semibold text-sm mt-2">Differences</h2>
        <code-diff
          v-if="isStandalone || isOneOffModel"
          :old-string="oldString"
          :new-string="newString"
          :file-name="fieldName"
          :no-diff-line-feed="false"
          diff-style="char"
          language="plaintext"
          :output-format="outputFormat"
        />

        <EmbeddedVisualDiff
          :field-name="fieldName"
          :data-field-change="dataFieldChange"
          :output-format="outputFormat"
          :investment-relations="investmentRelations"
          :change-investments="changeInvestments"
          :investment-valuations="investmentValuations"
          :related-investments="relatedInvestments"
          @set-investment-relation="setInvestmentRelation"
        />

        <CitationsList
          :data-field-change="dataFieldChange"
          :output-format="outputFormat"
        />
      </div>

      <div class="bg-gray-200 border-t border-gray-400">
        <ValidationGuidance
          class="px-2 pt-2"
          :typed-test-index="scrollId"
          :meta-key="
            dataFieldChange?.after?.metaKey || dataFieldChange?.before?.metaKey
          "
          :change-type="dataFieldChange?.changeType"
        />

        <ValidationActions
          class="flex flex-col pt-2"
          :focal-change="isCurrent"
          :action-in-progress="actionInProgress"
          :admin-approvable="adminApprovable"
          :test-index="typedTestIndex"
          @approve="approve(dataFieldChange.id)"
          @disapprove="disapprove"
          @accept="accept(dataFieldChange.id)"
          @reject="reject"
          @skip="skip"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import CitationsList from "@/components/crowdsourcing/CitationsList.vue";
import ValidationActions from "@/components/crowdsourcing/ValidationActions.vue";
import ValidationGuidance from "@/components/crowdsourcing/ValidationGuidance.vue";
import api from "@/router/api";
import fieldDiffAttributes from "@/components/crowdsourcing/fieldDiffAttributes";
import moment from "moment";
import { currencyAmount } from "@/assets/numberHelpers";
import { computed, onMounted, ref, watch } from "vue";
import { useUserStore } from "@/stores/user";
import { useModalStore } from "@/stores/modal";
import { useUnlockerStore } from "@/stores/unlocker";
import { useTopLevelContentFieldsStore } from "@/stores/topLevelContentFields";
import { useDocumentationStore } from "@/stores/documentation";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";
import { storeToRefs } from "pinia";
import { resolveDatabaseConflicts } from "@/assets/documentation/articles/resolveDatabaseConflicts";
import _ from "lodash";
import dataFieldAliases from "@/assets/dataFieldAliases";
import DataFieldChangeUser from "@/components/crowdsourcing/DataFieldChangeUser.vue";
import EmbeddedVisualDiff from "@/components/crowdsourcing/EmbeddedVisualDiff.vue";
import {
  CheckIcon,
  ChevronDoubleRightIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from "@heroicons/vue/20/solid";
import VueScrollTo from "vue-scrollto";
import AdminValidationVoteSummary from "@/components/crowdsourcing/AdminValidationVoteSummary.vue";

const props = defineProps([
  "dataFieldChange",
  "outputFormat",
  "investmentRelations",
  "changeInvestments",
  "relatedInvestments",
  "investmentValuations",
  "testIndex",
  "groupIndex",
  "changeIndex",
  "nextUp",
  "validatedChangeIds",
]);
const emit = defineEmits([
  "has-conflicts",
  "resolved-conflicts",
  "validation-submitted",
  "viewed",
  "set-investment-relation",
]);

const topLevelContentFieldsStore = useTopLevelContentFieldsStore();

const expanded = ref(false);
const viewed = ref(false);
const validationSubmissions = ref([]);
const validationInput = ref(null);
const actionInProgress = ref(false);
const validationContextMetaData = ref(null);
const userStore = useUserStore();
const { isAdmin, emailUnverified } = storeToRefs(userStore);
const documentationStore = useDocumentationStore();

const validationColor = computed(() => {
  switch (validationInput.value) {
    case "Accepted":
      return "text-green-500";
    case "Rejected":
      return "text-red-500";
    case "Skipped":
      return "text-blue-500";
    default:
      return "";
  }
});
const isCurrent = computed(() => {
  if (
    _.isNumber(props.nextUp.groupIndex) &&
    _.isNumber(props.nextUp.changeIndex)
  ) {
    return (
      props.nextUp.groupIndex === props.groupIndex &&
      props.nextUp.changeIndex === props.changeIndex
    );
  } else {
    return false;
  }
});
const isNext = computed(() => {
  if (
    _.isNumber(props.nextUp.groupIndex) &&
    _.isNumber(props.nextUp.changeIndex)
  ) {
    return props.nextUp.groupIndex === props.groupIndex;
  } else {
    return false;
  }
});
const scrollId = computed(
  () => `group-${props.groupIndex}-change-${props.changeIndex}`,
);
const typedTestIndex = computed(() => `standalone-${props.testIndex}`);
const adminApprovable = computed(() => {
  return isAdmin.value;
});
const rawConflicts = computed(
  () => _.get(props.dataFieldChange, "conflicts") || [],
);
const conflicts = computed(() => {
  return isAdmin.value
    ? rawConflicts.value
    : rawConflicts.value.filter((dataField) => !dataField.authored);
});
const conflictChangeIds = computed(() => {
  return competingChanges.value.map((dataField) => {
    return { id: dataField.latestChangeId };
  });
});
const hasConflicts = computed(() => {
  return conflicts.value.length > 0;
});
const competingChanges = computed(() => {
  const after = _.get(props.dataFieldChange, "after");
  const arr = after ? [after] : [];

  return _.unionBy(arr, conflicts.value, "localId");
});
const isTopLevel = computed(() => {
  return (
    `${_.get(topLevelField.value, "fieldContentType")} ${_.get(
      topLevelField.value,
      "fieldContentId",
    )}` ===
    `${_.get(primaryField.value, "decoratingContentType")} ${_.get(
      primaryField.value,
      "decoratingContentId",
    )}`
  );
});
const changeType = computed(() => _.get(props.dataFieldChange, "changeType"));
const fieldName = computed(() => {
  return (
    _.get(primaryField.value, "fieldName") ||
    _.get(primaryField.value, "fieldContentType")
  );
});
const fieldAlias = computed(() => {
  return (
    _.get(dataFieldAliases, `${fieldName.value}.singular`) ||
    `${fieldName.value}`
  );
});
const beforeField = computed(() => _.get(props.dataFieldChange, "before"));
const afterField = computed(() => _.get(props.dataFieldChange, "after"));
const primaryField = computed(() =>
  changeType.value === "addition" ? afterField.value : beforeField.value,
);
const isStandalone = computed(() => {
  return !!_.get(primaryField.value, "fieldName");
});
const isOneOffModel = computed(() => {
  return _.includes(
    ["ContentLocation", "ContentPolygon"],
    _.get(primaryField.value, "fieldContentType"),
  );
});
const oldString = computed(() => {
  if (isNew.value) {
    return "";
  } else {
    return stringFor(beforeField.value);
  }
});
const newString = computed(() => {
  if (afterField.value) return stringFor(afterField.value);
  else return "";
});
const isNew = computed(() => {
  return !beforeField.value;
});
const topLevelField = computed(() => {
  return _.get(props.dataFieldChange, "topLevelContent");
});

watch(hasConflicts, () => {
  if (hasConflicts.value) {
    emit("has-conflicts", conflictChangeIds.value);
  }
});

watch(
  () => props.validatedChangeIds,
  () => {
    const changeId = props.dataFieldChange?.id;

    if (_.includes(props.validatedChangeIds, changeId)) {
      expanded.value = false;
    }
  },
  { deep: true },
);

watch(isCurrent, () => {
  if (isCurrent.value) {
    if (props.nextUp.changeIndex === props.changeIndex) {
      debouncedScroll();
    }
  }
});

watch(isNext, () => {
  if (isNext.value) {
    expand();
    if (props.nextUp.changeIndex === props.changeIndex) {
      debouncedScroll();
    }
  }
});

onMounted(() => {
  if (isNext.value) expand();
  if (primaryField.value && !isTopLevel.value) {
    fetchValidationContextFor(primaryField.value, "decorating");
  }

  if (hasConflicts.value) {
    emit("has-conflicts", conflictChangeIds.value);
  }

  if (adminApprovable.value) {
    fetchValidationSubmissions();
  }
});

const debouncedScroll = _.debounce(
  function () {
    if (isCurrent.value) {
      VueScrollTo.scrollTo(`#${scrollId.value}`, 500, {
        container: "#changes-container",
        offset: -80,
      });
    }
  },
  500,
  { leading: true },
);

function toggleExpanded() {
  if (expanded.value) {
    expanded.value = false;
  } else {
    expand();
  }
}

function expand() {
  expanded.value = true;
  if (!viewed.value) {
    emit("viewed", {
      changeIds: [props.dataFieldChange.id],
    });
  }
  viewed.value = true;
}

function stringFor(dataField) {
  if (isStandalone.value) {
    if (dataField.fieldValueType === "Date") {
      if (dataField.fieldValue) {
        return moment(dataField.fieldValue).format("MMMM Do, YYYY");
      } else {
        return "Empty/unknown date";
      }
    } else if (
      _.includes(
        [
          "value",
          "fully_funded_loan_amount",
          "min_investment_size",
          "max_investment_size",
        ],
        dataField.fieldName,
      )
    ) {
      return `$${currencyAmount(dataField.fieldValue, 0)}`;
    } else if (_.includes(["loan_coupon_rate"], dataField.fieldName)) {
      return `${currencyAmount(dataField.fieldValue, 2)}%`;
    } else if (_.includes(["density_percent_modifier"], dataField.fieldName)) {
      return `${currencyAmount(dataField.fieldValue, 0)}%`;
    } else if (dataField.fieldName === "height") {
      return `${_.round(_.toNumber(dataField.fieldValue), 1)} feet`;
    } else if (dataField.fieldName === "land_area") {
      return `${_.round(_.toNumber(dataField.fieldValue), 2)} acres`;
    } else if (dataField.fieldValueType === "Integer") {
      if (
        _.includes(
          ["min_area", "max_area", "standardized_area"],
          dataField.fieldName,
        )
      ) {
        return `${currencyAmount(dataField.fieldValue, 0)} SF`;
      } else {
        return `${currencyAmount(dataField.fieldValue, 0)}`;
      }
    } else {
      return dataField.fieldValue;
    }
  } else if (isOneOffModel.value) {
    return oneOffModelToString(dataField);
  } else {
    return "Many models";
  }
}
function oneOffModelToString(dataField) {
  if (dataField) {
    const contentType = dataField.fieldContentType;
    const attributesArray = _.get(fieldDiffAttributes, contentType);

    if (attributesArray) {
      const sortedArray = _.sortBy(attributesArray, ["order"]);

      return _.compact(
        _.map(sortedArray, function (attrObj) {
          const label = attrObj.name;
          const value = _.get(dataField.fieldContent, attrObj.value);
          const emptyValue =
            _.isArray(value) || _.isObject(value)
              ? _.isEmpty(value)
              : _.isNil(value);

          if (emptyValue) {
            return null;
          } else {
            const formattedValue = _.isArray(value) ? value.join(", ") : value;

            return `${label}: ${formattedValue}`;
          }
        }),
      ).join("\n");
    } else {
      return "";
    }
  } else {
    return null;
  }
}
async function fetchValidationContextFor(dataField, layerType) {
  validationContextMetaData.value =
    await topLevelContentFieldsStore.fetchValidationContext(
      _.get(dataField, `${layerType}ContentType`),
      _.get(dataField, `${layerType}ContentId`),
    );
}
async function fetchValidationSubmissions() {
  const response = await api.post(`data_field_change_validation_submissions`, {
    changeIds: [props.dataFieldChange?.id],
  });

  if (response?.data) {
    validationSubmissions.value = response.data;
  }
}

const modalStore = useModalStore();
const { modalPayload } = storeToRefs(modalStore);
const unlockerStore = useUnlockerStore();
const { upgradeSuccessful } = storeToRefs(unlockerStore);

function accept(changeId) {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else {
    const payload = {
      changeIds: [changeId].map((id) => {
        return { id };
      }),
    };
    const successCallback = () => {
      expanded.value = false;
      validationInput.value = "Accepted";

      if (hasConflicts.value) {
        const rejectPayload = competingChanges.value
          .filter((dataField) => {
            return dataField.latestChangeId !== changeId;
          })
          .map((dataField) => dataField.latestChangeId);

        reject(rejectPayload);
        emit("resolved-conflicts", conflictChangeIds.value);
      }

      emit("validation-submitted", {
        changeIds: [changeId],
        validationInput: validationInput.value,
      });
      actionInProgress.value = false;
    };

    actionInProgress.value = true;

    subscribeInterceptor({
      apiRequestFunc: () => api.post(`validation_accept_changes`, payload),
      successCallback,
      modalPayloadRef: modalPayload,
      upgradeSuccessfulRef: upgradeSuccessful,
      afterSubscribe: "apiRequest",
      context: "validating",
    });
  }
}
function approve(changeId) {
  if (emailUnverified.value) {
    userStore.promptToVerify();
  } else if (adminApprovable.value) {
    const payload = {
      changeIds: [changeId].map((id) => {
        return { id };
      }),
      adminOverride: "override",
    };

    actionInProgress.value = true;

    api.post(`validation_accept_changes`, payload).then(() => {
      expanded.value = false;
      validationInput.value = "Accepted";
      emit("validation-submitted", {
        changeIds: [changeId],
        validationInput: validationInput.value,
      });
      actionInProgress.value = false;
    });

    if (hasConflicts.value) {
      const disapprovePayload = competingChanges.value
        .filter((dataField) => {
          return dataField.latestChangeId !== changeId;
        })
        .map((dataField) => dataField.latestChangeId);

      disapprove(disapprovePayload);
      emit("resolved-conflicts", conflictChangeIds.value);
    }
  }
}
function rejectAll() {
  if (hasConflicts.value) {
    const payload = {
      changeIds: conflictChangeIds.value,
    };
    const successCallback = () => {
      expanded.value = false;
      validationInput.value = "Rejected";
      emit("validation-submitted", {
        changeIds: conflictChangeIds.value,
        validationInput: validationInput.value,
      });
      actionInProgress.value = false;
      emit("resolved-conflicts", conflictChangeIds.value);
    };

    actionInProgress.value = true;

    subscribeInterceptor({
      apiRequestFunc: () => api.post(`validation_reject_changes`, payload),
      successCallback,
      modalPayloadRef: modalPayload,
      upgradeSuccessfulRef: upgradeSuccessful,
      afterSubscribe: "apiRequest",
      context: "validating",
    });
  }
}
function disapproveAll() {
  if (adminApprovable.value && hasConflicts.value) {
    const payload = {
      changeIds: conflictChangeIds.value,
      adminOverride: "override",
    };

    actionInProgress.value = true;

    api.post(`validation_reject_changes`, payload).then(() => {
      expanded.value = false;
      validationInput.value = "Rejected";
      emit("validation-submitted", {
        changeIds: conflictChangeIds.value,
        validationInput: validationInput.value,
      });
      actionInProgress.value = false;
    });

    emit("resolved-conflicts", conflictChangeIds.value);
  }
}
function reject(changeIds = [props.dataFieldChange.id]) {
  const payload = {
    changeIds: changeIds.map((id) => {
      return { id };
    }),
  };
  const successCallback = () => {
    expanded.value = false;
    validationInput.value = "Rejected";
    emit("validation-submitted", {
      changeIds,
      validationInput: validationInput.value,
    });
    actionInProgress.value = false;
  };

  actionInProgress.value = true;

  subscribeInterceptor({
    apiRequestFunc: () => api.post(`validation_reject_changes`, payload),
    successCallback,
    modalPayloadRef: modalPayload,
    upgradeSuccessfulRef: upgradeSuccessful,
    afterSubscribe: "apiRequest",
    context: "validating",
  });
}
function disapprove(changeIds = [props.dataFieldChange.id]) {
  if (adminApprovable.value) {
    const payload = {
      changeIds: changeIds.map((id) => {
        return { id };
      }),
      adminOverride: "override",
    };

    actionInProgress.value = true;

    api.post(`validation_reject_changes`, payload).then(() => {
      expanded.value = false;
      validationInput.value = "Rejected";
      emit("validation-submitted", {
        changeIds,
        validationInput: validationInput.value,
      });
      actionInProgress.value = false;
    });
  }
}
function skipAll() {
  if (hasConflicts.value) {
    const payload = {
      changeIds: conflictChangeIds.value,
    };

    actionInProgress.value = true;

    api.post(`validation_skip_changes`, payload).then(() => {
      expanded.value = false;
      validationInput.value = "Skipped";
      emit("validation-submitted", {
        changeIds: conflictChangeIds.value,
        validationInput: validationInput.value,
      });
      actionInProgress.value = false;
    });

    emit("resolved-conflicts", conflictChangeIds.value);
  }
}
function skip() {
  const payload = {
    changeIds: [props.dataFieldChange.id].map((id) => {
      return { id };
    }),
  };

  actionInProgress.value = true;

  api.post(`validation_skip_changes`, payload).then(() => {
    expanded.value = false;
    validationInput.value = "Skipped";
    emit("validation-submitted", {
      changeIds: [props.dataFieldChange.id],
      validationInput: validationInput.value,
    });
    actionInProgress.value = false;
  });
}

function getConflictHelp() {
  documentationStore.viewArticle(resolveDatabaseConflicts);
}

function setInvestmentRelation(payload) {
  emit("set-investment-relation", payload);
}
</script>
