<template>
  <div
    :id="`${spaceKey}-space-editor-container`"
    :data-test="`${spaceKey}-space-editor-container`"
    class="bg-white overflow-visible shadow rounded-lg border border-gray-400 divide-y divide-gray-200"
  >
    <div
      :class="[
        expanded ? 'rounded-t-lg' : 'rounded-lg',
        dataField.fieldContentType === 'LandCovering'
          ? 'bg-orange-300'
          : 'bg-blue-300',
      ]"
      class="flex items-center justify-between overflow-hidden p-2 text-gray-700 hover:text-gray-800"
    >
      <div class="flex items-center space-x-2">
        <button
          type="button"
          @click="toggleExpanded"
          class="font-semibold uppercase tracking-wide"
        >
          {{ headerLabel }}{{ unlocatedSpaces ? " (Unlocated Spaces)" : "" }}
        </button>
        <div
          v-if="spaceObject.diagramLegendColor"
          v-tooltip="'Property diagram floor area outline color'"
          class="h-4 w-4 rounded-md ring-1 ring-inset ring-black ring-opacity-0"
          :class="[spaceObject.diagramLegendColor]"
        />
      </div>
      <div class="flex items-center space-x-2">
        <button
          @click="removeSpace"
          type="button"
          :data-test="`${decoratingAndFieldKey(
            spaceObject.dataField,
          )}-remove-space-button`"
          class="h-5 w-5 p-1 inline-flex justify-center items-center text-sm"
        >
          <i class="fas fa-times" />
        </button>
      </div>
    </div>

    <div
      v-if="expanded"
      class="bg-gray-100 p-2 text-gray-700 text-xs font-semibold uppercase tracking-wide"
    >
      Timeline
    </div>
    <EventsTimeline
      v-if="expanded"
      :sources="[
        {
          contentId: dataField.fieldContentId,
          contentType: dataField.fieldContentType,
          dataField,
        },
      ]"
      context="space-usage-builder-editor-container"
      :left-edge-id="`lists-panel-container`"
      :forward-months="60"
      :lookback-years="10"
      class="pt-2 pl-2"
    />

    <div
      v-if="expanded"
      class="flex items-center justify-between p-2 bg-gray-100"
    >
      <div class="text-gray-700 text-xs font-semibold uppercase tracking-wide">
        Space Usage{{ unlocatedSpaceLabel }}
      </div>
      <div class="flex items-center space-x-2">
        <button
          @click="spaceUsagePanelExpanded = !spaceUsagePanelExpanded"
          v-tooltip="spaceUsagePanelExpanded ? 'Minimize' : 'Expand'"
          type="button"
          class="h-5 w-5 p-1 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
        >
          <i v-if="spaceUsagePanelExpanded" class="fas fa-compress" />
          <i v-else class="fas fa-expand" />
        </button>
      </div>
    </div>

    <div
      v-if="expanded && spaceUsagePanelExpanded"
      class="grid gap-2 pb-6"
      style="grid-template-columns: 1fr 80px 1fr"
    >
      <div
        :data-test="`${decoratingAndFieldKey(
          spaceObject.dataField,
        )}-before-space-stack`"
      >
        <h2
          class="p-2 text-gray-500 text-xs font-semibold uppercase tracking-wide"
        >
          as of
          <time :datetime="asOfDate">{{
            moment(asOfDate).format("MMM YYYY")
          }}</time>
        </h2>
        <!-- Current stack -->
        <SpaceUsageStackDiagram
          v-if="stackDisplayable && stackDataField"
          :property-id="propertyId"
          :data-field="stackDataField"
          :top-level-space-data-field="
            unlocatedSpaces ? spaceObject.dataField : null
          "
          context="spaceUsageBuilder"
        />
      </div>
      <div class="flex flex-col">
        <h2
          class="flex justify-center items-center space-x-2 p-2 text-xs font-semibold uppercase tracking-wide text-gray-500"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="flex-shrink-0 h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
            />
          </svg>
          <span>Usage?</span>
        </h2>
      </div>

      <div
        :data-test="`${decoratingAndFieldKey(
          spaceObject.dataField,
        )}-after-space-stack`"
      >
        <h2
          class="p-2 text-gray-500 text-xs font-semibold uppercase tracking-wide"
        >
          After
        </h2>
        <SpaceUsageStackDiagram
          v-if="
            stackDisplayable &&
            stackDataField &&
            (existingOpenAvailability || creatingFutureDeal)
          "
          :property-id="propertyId"
          :data-field="stackDataField"
          :top-level-space-data-field="
            unlocatedSpaces ? spaceObject.dataField : null
          "
          context="spaceUsageBuilder"
          :future="true"
        />

        <template v-else-if="stackDataField && currentSpaceUserUnknown">
          <VDropdown v-if="availabilityGroups.length > 0">
            <button
              type="button"
              class="relative block w-full border-2 border-indigo-300 border-dashed rounded-lg p-12 text-center hover:border-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PlusIcon class="mx-auto h-12 w-12 text-indigo-400" />
              <span class="mt-2 block text-sm font-medium text-indigo-900"
                >Create an active space availability</span
              >
            </button>

            <template #popper>
              <div
                class="w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabindex="-1"
              >
                <div class="py-1" role="none">
                  <a
                    v-for="(group, index) in availabilityGroups"
                    :key="index"
                    @click.prevent="addToPortfolio(group)"
                    href=""
                    class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-1"
                  >
                    Add to{{
                      _.includes(group.placeholderId, "temp") ? " Draft " : " "
                    }}Group{{
                      _.includes(group.placeholderId, "temp")
                        ? ""
                        : group.placeholderId
                    }}:
                    {{
                      pluralize(
                        "Space",
                        spaceUsageBuilderStore.availabilitiesLength(group),
                        true,
                      )
                    }}
                  </a>
                  <a
                    @click.prevent="createFutureDealNewPortfolio"
                    href=""
                    class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-0"
                  >
                    New Group
                  </a>
                </div>
              </div>
            </template>
          </VDropdown>

          <DataVisibilityButton
            v-else
            visibility="safezone"
            class="relative block w-full"
          >
            <template v-slot:button>
              <button
                @click="createFutureDealNewPortfolio"
                type="button"
                class="relative block w-full border-2 border-yellow-300 border-dashed rounded-lg p-12 text-center hover:border-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
              >
                <PlusIcon class="mx-auto h-12 w-12 text-yellow-400" />
                <span class="mt-2 block text-sm font-medium text-yellow-900"
                  >Create an availability or usage</span
                >
              </button>
            </template>
          </DataVisibilityButton>
        </template>
      </div>
    </div>

    <UnlocatedSpacesList
      v-if="unlocatedSpaces && expanded"
      :space-object="spaceObject"
    />
    <AvailabilitiesList v-else-if="expanded" :space-object="spaceObject" />
  </div>
</template>

<script setup>
import EventsTimeline from "@/components/main-layout/EventsTimeline.vue";
import AvailabilitiesList from "@/components/space-usage-builder/AvailabilitiesList.vue";
import UnlocatedSpacesList from "@/components/space-usage-builder/UnlocatedSpacesList.vue";
import SpaceUsageStackDiagram from "@/components/space-usage-builder/SpaceUsageStackDiagram.vue";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import { computed, watch, onMounted, nextTick, ref } from "vue";
import { PlusIcon } from "@heroicons/vue/24/outline";
import DataVisibilityButton from "@/components/crowdsourcing/DataVisibilityButton.vue";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import propertySpaceLabel from "@/assets/spaceLabel";
import selfLabel from "@/components/crowdsourcing/selfLabelDataField";
import moment from "moment";
import pluralize from "pluralize";
import _ from "lodash";

const props = defineProps(["spaceObject"]);
const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const { spaceUsageBuilder, refetchSpaceUsageBuilderEditor, crossInteraction } =
  storeToRefs(spaceUsageBuilderStore);
const propertyFieldsStore = usePropertyFieldsStore();
const timeTravelStore = useTimeTravelStore();
const { asOfDate } = storeToRefs(timeTravelStore);

const spaceUsagePanelExpanded = ref(true);
const stackDisplayable = ref(true);

const unlocatedSpaces = computed(
  () => dataField.value.fieldContentType === "LandCovering",
);
const unlocatedSpaceLabel = computed(() => {
  if (unlocatedSpaces.value) {
    return props.spaceObject.selectedUnlocatedSpace
      ? `: ${selfLabel(props.spaceObject.selectedUnlocatedSpace)}`
      : ": Create a new space";
  } else {
    return "";
  }
});
const stackDataField = computed(() => {
  if (unlocatedSpaces.value) {
    return props.spaceObject.selectedUnlocatedSpace || dataField.value;
  } else {
    return dataField.value;
  }
});
const stackFieldId = computed(() => stackDataField.value?.localId);
const expanded = computed({
  get() {
    return props.spaceObject.expanded;
  },
  set(bool) {
    spaceUsageBuilderStore.setSpaceExpanded({
      spaceKey: spaceKey.value,
      expanded: bool,
    });
  },
});
const spaceKey = computed(() => {
  return decoratingAndFieldKey(dataField.value);
});
const dataField = computed(() => {
  return props.spaceObject.dataField;
});
const propertyId = computed(() => {
  if (_.get(dataField.value, "joiningContentType") === "Property") {
    return _.get(dataField.value, "joiningContentId");
  } else if (_.get(dataField.value, "decoratingContentType") === "Property") {
    return _.get(dataField.value, "decoratingContentId");
  } else {
    return dataField.value.fieldContent?.propertyId;
  }
});
const propertyDataField = computed(() => {
  if (propertyId.value) {
    return propertyFieldsStore.getPropertyField(propertyId.value);
  } else {
    return null;
  }
});
const headerLabel = computed(() => {
  return propertySpaceLabel(dataField.value, propertyDataField.value);
});
const existingOpenAvailability = computed(() => {
  return null;
});
const creatingFutureDeal = computed(() => {
  const spaceKey = decoratingAndFieldKey(props.spaceObject.dataField);

  return _.find(spaceUsageBuilder.value.availabilityGroups, function (group) {
    return _.some(group.availabilities, function (availability) {
      return (
        !availability.existingAvailability &&
        availability.topLevelSpace &&
        spaceKey === decoratingAndFieldKey(availability.topLevelSpace)
      );
    });
  });
});
const currentSpaceUserUnknown = computed(() => {
  return props.spaceObject.spaceUsers.length === 0;
});
const availabilityGroups = computed(() => {
  return _.get(spaceUsageBuilder.value, "availabilityGroups", []);
});

watch(stackFieldId, async (val, oldVal) => {
  if (stackFieldId.value && val !== oldVal) {
    console.log(
      "stackFieldId watcher",
      spaceUsageBuilder.value,
      stackFieldId.value,
    );
    stackDisplayable.value = false;
    await nextTick();

    stackDisplayable.value = true;
  }
});
watch(refetchSpaceUsageBuilderEditor, async () => {
  if (refetchSpaceUsageBuilderEditor.value === spaceKey.value) {
    expanded.value = false;

    await nextTick();
    expanded.value = true;
    refetchSpaceUsageBuilderEditor.value = null;
  }
});

onMounted(() => {
  if (propertyId.value && !propertyDataField.value) {
    propertyFieldsStore.fetchPropertyDataField(propertyId.value);
  }
});

function toggleExpanded() {
  expanded.value = !expanded.value;
}

function removeSpace() {
  spaceUsageBuilderStore.removeSpace(
    decoratingAndFieldKey(props.spaceObject.dataField),
  );
}

function createFutureDealNewPortfolio() {
  crossInteraction.value = null;

  spaceUsageBuilderStore.addAvailabilityGroup({
    spaceDataField: props.spaceObject.dataField,
    availabilityFieldContent: props.spaceObject.dataField,
  });
}
function addToPortfolio(availabilityGroup) {
  spaceUsageBuilderStore.addAvailabilityToGroup({
    existingAvailability: null,
    spaceDataField: props.spaceObject.dataField,
    availabilityFieldContent: props.spaceObject.dataField,
    availabilityGroupPlaceholderId: availabilityGroup.placeholderId,
  });
  spaceUsageBuilderStore.collapsePortfolioSpaces({
    groupId: availabilityGroup.id || availabilityGroup.placeholderId,
  });
  spaceUsageBuilderStore.setAvailabilityGroupExpanded({
    groupId: availabilityGroup.id || availabilityGroup.placeholderId,
    expanded: true,
  });
}
</script>
