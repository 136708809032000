/* global analytics */
import { useUserStore } from "@/stores/user";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useTasksStore } from "@/stores/tasks";
import { ref } from "vue";
import { storeToRefs, defineStore, acceptHMRUpdate } from "pinia";
import { useRoute, useRouter } from "vue-router";
import User from "@/stores/models/user";
import Guest from "@/stores/models/guest";
import api from "@/router/api";

export const useAuthStore = defineStore("auth", () => {
  const userStore = useUserStore();
  const { adminMode, currentUser, isAdmin } = storeToRefs(userStore);
  const secondaryPanelStore = useSecondaryPanelStore();
  const { myAccountActive, validationsActive } =
    storeToRefs(secondaryPanelStore);
  const tasksStore = useTasksStore();
  const { selectedTaskList } = storeToRefs(tasksStore);
  const identityChecked = ref(false);
  async function getCurrentUser() {
    fetchUser().then(
      (fetchedUser) => {
        adminMode.value = false;
        if (fetchedUser.data.signedIn) {
          signIn(new User(fetchedUser.data));

          if (localStorage["token"]) {
            // this.$cable.connection.connect();
          }
        } else if (localStorage["token"]) {
          localStorage.removeItem("token");
          // this.$cable.connection.disconnect();
        } else {
          signIn(new Guest(fetchedUser.data));
        }
        identityChecked.value = true;
      },
      () => {
        adminMode.value = false;
        if (localStorage["token"]) {
          localStorage.removeItem("token");
          // this.$cable.connection.disconnect();
          location.reload();
        }
        identityChecked.value = true;
      },
    );
  }
  async function fetchUser() {
    return api.get("whoami");
  }

  const router = useRouter();
  const route = useRoute();

  async function signIn(userModel) {
    return new Promise((resolve) => {
      currentUser.value = userModel;
      setAnalytics();
      selectedTaskList.value = null;
      tasksStore.fetchLists();
      if (isAdmin.value) {
        resolve("admin");
      } else {
        resolve();
      }
    });
  }

  function setAnalytics() {
    if (!adminMode.value && currentUser.value.id) {
      if (window.LogRocket) {
        console.log("set LogRocket", window.LogRocket);
        window.LogRocket.identify(currentUser.value.id, {
          name: currentUser.value.name,
          email: currentUser.value.email,
          createdAt: currentUser.value.userCreatedAt,
        });
      }

      if (analytics) {
        analytics.identify(currentUser.value.id, {
          name: currentUser.value.name,
          email: currentUser.value.email,
          createdAt: currentUser.value.userCreatedAt,
          signupMonthYear: currentUser.value.signupMonthYear,
        });
      }
    }
  }

  async function signOut() {
    localStorage.removeItem("token");
    // this.$cable.connection.disconnect();

    const adminToken = localStorage.getItem("adminToken");

    if (adminToken) {
      localStorage.setItem("token", adminToken);
      localStorage.removeItem("adminToken");
      adminMode.value = false;
      api.defaults.headers.common["Authorization"] = `Bearer ${adminToken}`;

      api.get("whoami").then((user) => {
        // this.$cable.connection.connect();
        signIn(new User(user.data)).then(() => {
          router.push({ name: "AdminStats" });
        });
      });
    } else {
      currentUser.value = new Guest();
      myAccountActive.value = false;
      validationsActive.value = false;
      secondaryPanelStore.setAuthenticate();
      secondaryPanelStore.revertPanel();

      if (route.name !== "HelpCenter") {
        router.push({
          name: "MainMap",
          query: null,
        });
      }

      setTimeout(() => {
        location.reload();
      }, 500);
    }
  }

  return {
    identityChecked,
    fetchUser,
    getCurrentUser,
    signIn,
    signOut,
    setAnalytics,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
