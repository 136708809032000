<template>
  <div class="h-full rounded-lg overflow-hidden">
    <CardBranding :card="card" style="z-index: 9999" />
    <div class="absolute inset-0">
      <div
        class="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-600 mix-blend-multiply"
      />
    </div>
    <div class="h-full relative flex flex-col">
      <div
        v-if="propertyId && investmentId && locationCenter"
        :id="`investment-location-map-${propertyId}-${investmentId}-${context}`"
        class="w-full bg-gray-100"
        style="height: 237.5px"
      />
      <div v-else class="w-full bg-gray-100" style="height: 237.5px" />
      <div class="mt-5 flex flex-col items-center">
        <h1
          v-if="cardData?.property"
          class="flex max-w-[18rem] justify-center text-center text-2xl font-bold tracking-tight space-x-2"
        >
          <span class="text-white">{{
            cardData.property.fieldContent.name
          }}</span>
        </h1>
        <p
          v-if="cardData?.investmentSummary"
          class="max-w-[16rem] text-center font-medium text text-cyan-200"
        >
          {{ cardData.investmentSummary.summary }}
        </p>
        <div
          v-if="cardData?.investment"
          class="flex items-center justify-center space-x-2"
        >
          <span
            v-if="inPlay"
            class="inline-flex items-center justify-center h-3 w-3 rounded-full bg-green-400"
          />
          <span class="font-medium text text-cyan-200">{{ dateLabel }}</span>
        </div>
        <div
          v-if="propertyId && isDesktop"
          class="pb-3 flex flex-col items-center"
        >
          <a
            href=""
            @click.prevent="viewInvestment"
            class="mt-4 flex items-center rounded-md border border-transparent bg-white px-4 py-2 text-base font-medium text-cyan-700 shadow-sm hover:bg-cyan-50"
          >
            View Investment
          </a>
          <div class="mt-2 flex items-center space-x-2">
            <a
              href=""
              @click.prevent="viewPropertyDiagram"
              class="mt-2 flex items-center justify-center rounded-md border border-white px-3 py-2 text-base font-medium text-white shadow-sm hover:border-cyan-50 hover:text-cyan-50"
              >View Property</a
            >
            <a
              href=""
              @click.prevent="centerOnMap"
              class="mt-2 flex items-center justify-center rounded-md border border-white px-3 py-2 text-base font-medium text-white shadow-sm hover:border-cyan-50 hover:text-cyan-50"
              >Center on Map</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* global L */
import moment from "moment";
import mapStyles from "@/components/maps/mapStyles";
import investmentStates from "@/components/deal-builder/investmentStates";
import locationMarker from "@/components/maps/locationMarker";
import CardBranding from "@/components/collectible-cards/CardBranding.vue";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useMainMapStore } from "@/stores/mainMap";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import _ from "lodash";

const props = defineProps([
  "card",
  "cardIndex",
  "stackIndex",
  "completedCardIds",
  "displayBack",
  "generic",
  "context",
]);

const propertyDiagramStore = usePropertyDiagramStore();
const dealBuilderStore = useDealBuilderStore();
const mapStore = useMainMapStore();
const propertyFieldsStore = usePropertyFieldsStore();
const { temporaryMapCenter, newOriginOverride } = storeToRefs(mapStore);
const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);

const cardData = ref(null);
const investmentState = computed(() =>
  _.get(cardData.value, "investment.fieldContent.state"),
);
const inPlay = computed(() => {
  return (
    investmentState.value &&
    !_.includes(["closed", "withdrawn"], investmentState.value)
  );
});
const investmentDate = computed(() =>
  _.get(cardData.value, "investment.fieldContent.date"),
);
const dateLabel = computed(() => {
  switch (investmentState.value) {
    case "withdrawn":
    case "live":
    case "under_contract":
      return _.find(investmentStates, {
        value: investmentState.value,
      }).statusLabel;
    case "closed":
    default: {
      if (investmentDate.value) {
        return moment(investmentDate.value).format("MMM YYYY");
      } else {
        return "Undated";
      }
    }
  }
});
const propertyId = computed(() =>
  _.get(cardData.value, "property.fieldContentId"),
);
const investmentId = computed(() =>
  _.get(cardData.value, "investment.fieldContentId"),
);
const locationCenter = computed(() => {
  const lat = _.get(
    cardData.value,
    "property.fieldContent.locationDataField.fieldContent.lat",
  );
  const lng = _.get(
    cardData.value,
    "property.fieldContent.locationDataField.fieldContent.lng",
  );

  if (lat && lng) {
    return [_.toNumber(lat), _.toNumber(lng)];
  } else {
    return null;
  }
});
const map = ref(null);
const baseLayers = ref({
  Base: L.gridLayer.googleMutant({
    type: "roadmap",
    styles: mapStyles.styles,
  }),
});

watch(
  () => props.stackIndex,
  () => {
    fetchCardData();
  },
);

onMounted(() => {
  fetchCardData();
});

async function fetchCardData() {
  if (
    props.stackIndex === props.cardIndex &&
    !_.includes(props.completedCardIds, props.card.id)
  ) {
    const cardDataResponse = await api.get(
      `unlocked_investment_card_data/${
        props.card.collectibleCardId || props.card.id
      }?generic=${props.generic}`,
    );
    cardData.value = cardDataResponse.data;

    await nextTick();
    mountMap();
  }
}

function mountMap() {
  if (!map.value) {
    let blockEl = document.getElementById(
      `investment-location-map-${propertyId.value}-${investmentId.value}-${props.context}`,
    );

    if (!blockEl) return;

    map.value = L.map(
      `investment-location-map-${propertyId.value}-${investmentId.value}-${props.context}`,
      {
        scrollWheelZoom: false,
        zoomControl: false,
        dragging: false,
        boxZoom: false,
        doubleClickZoom: false,
        zoomAnimation: false,
        fadeAnimation: true,
        markerZoomAnimation: true,
      },
    ).setView(locationCenter.value, 13);

    baseLayers.value["Base"].addTo(map.value);
    addMarker();
  }
}

function addMarker() {
  const markerIcon = locationMarker({
    classes: "h-5 w-5 bg-teal-600 hover:bg-teal-700 focus:ring-teal-500",
    interactive: false,
    mapStore,
    propertyFieldsStore,
  });
  L.marker(locationCenter.value, {
    interactive: false,
    icon: markerIcon,
  }).addTo(map.value);
}

function viewPropertyDiagram() {
  newOriginOverride.value = true;
  propertyDiagramStore.navigateToDiagram(propertyId.value, true);
}
function viewInvestment() {
  api
    .get(`investment_deal_builder_assets/${investmentId.value}`)
    .then((json) => {
      const { assetDataField, propertyDataField } = json.data;

      dealBuilderStore.patchPropertyDataFields(propertyDataField);
      dealBuilderStore.upsertDealBuilder({ assetDataField });
    });
}
function centerOnMap() {
  newOriginOverride.value = true;
  temporaryMapCenter.value = {
    lat: locationCenter.value[0],
    lng: locationCenter.value[1],
    zoom: 16.5,
  };
}
</script>
