/* global L */
import { mount } from "mount-vue-component";
import app from "@/entrypoints/application";
import PropertyMarker from "@/components/property-diagram/PropertyMarker.vue";
import LandCoveringMarker from "@/components/land-covering-diagram/LandCoveringMarker.vue";

export default function propertyLandCoveringMapMarker({
  dataField,
  location,
  mapStore,
  propertyFieldsStore,
  modalStore,
  unlockerStore,
  userStore,
  tasksStore,
  taskListStore,
  reminderStore,
  guestProfileStore,
  propertyDiagramStore,
  changeGroupStore,
  layoutStore,
  secondaryPanelStore,
  documentationStore,
  analyzePanelStore,
  playerMinimap,
}) {
  let markerVueEl;

  switch (dataField.decoratingContentType) {
    case "Property": {
      const { el } = mount(PropertyMarker, {
        props: {
          mapStore,
          propertyFieldsStore,
          modalStore,
          unlockerStore,
          userStore,
          tasksStore,
          taskListStore,
          reminderStore,
          guestProfileStore,
          propertyDiagramStore,
          changeGroupStore,
          layoutStore,
          secondaryPanelStore,
          documentationStore,
          analyzePanelStore,
          location,
          locationDataField: dataField,
          playerMinimap,
        },
        app,
      });

      markerVueEl = el;
      break;
    }
    case "LandCovering": {
      const { el } = mount(LandCoveringMarker, {
        props: {
          mapStore,
          modalStore,
          unlockerStore,
          userStore,
          tasksStore,
          taskListStore,
          reminderStore,
          guestProfileStore,
          propertyDiagramStore,
          changeGroupStore,
          secondaryPanelStore,
          documentationStore,
          analyzePanelStore,
          location,
          locationDataField: dataField,
        },
        app,
      });

      markerVueEl = el;
      break;
    }
    default:
      console.log("unknown marker content type");
  }

  if (markerVueEl && location.lat && location.lng) {
    const icon = L.divIcon({
      className: "workspace-marker",
      html: markerVueEl,
    });

    return L.marker([location.lat, location.lng], { icon });
  } else {
    return null;
  }
}
