<template>
  <VMenu
    theme="stick-menu"
    :placement="workspaceLayout === 'sideBySide' ? 'left-start' : 'top'"
  >
    <button
      v-observe-visibility="{ callback: fetchHeroImage, once: true }"
      class="relative bg-cover bg-center bg-no-repeat rounded-md shadow-md mr-1.5 w-10 h-10 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-150"
      @mouseenter="handleHover"
      @mouseleave="propertyMarkerPulseId = null"
      @click="viewDetails"
      :class="[
        heroImage ? 'bg-gray-100' : 'bg-white',
        nearbyCompaniesStore.shadowColorFor(involvement),
        `border-2 ${nearbyCompaniesStore.borderColorFor(involvement)}`,
        isProspect(involvement)
          ? `border-dashed ${nearbyCompaniesStore.borderColorFor(involvement)}`
          : '',
      ]"
      :style="`background-image: url(${
        heroImage ||
        'https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png'
      });`"
      type="button"
    >
      <div
        v-if="
          isHunt(involvement) ||
          isHuntAdvisory(involvement) ||
          spaceUsageOnTheHunt(involvement)
        "
        v-tooltip="'Hunting'"
        class="absolute -right-1.5 -top-1.5 inline-flex items-center justify-center rounded-full flex-shrink-0 text-sm bg-white text-red-600"
      >
        <i class="fas fa-crosshairs" />
      </div>
      <span
        v-if="isLiveInvestment(involvement)"
        v-tooltip="'Live'"
        class="absolute -right-1.5 -top-1.5 block h-3 w-3 rounded-full bg-green-500 ring-2 ring-white"
      />
      <ExclamationTriangleIcon
        v-else-if="isUnderContractInvestment(involvement)"
        v-tooltip="'Under Contract'"
        class="absolute -right-1.5 -top-1.5 h-3.5 w-3.5 text-orange-500"
      />
      <div
        v-else-if="isWithdrawnInvestment(involvement)"
        v-tooltip="'Withdrawn'"
        class="absolute -right-1.5 -top-1.5 inline-flex items-center justify-center flex-shrink-0 text-xs text-gray-600"
      >
        <i class="fas fa-skull-crossbones" />
      </div>
      <span
        v-else-if="assetInPlay(involvement)"
        v-tooltip="'In Play'"
        class="absolute -right-1.5 -top-1.5 block h-3 w-3 rounded-full bg-green-500 ring-2 ring-white"
      />
      <Square2StackIcon
        v-if="grouped"
        v-tooltip="isUser(involvement) ? 'Multiple Spaces' : 'Multiple Assets'"
        class="absolute -left-1.5 -top-1.5 h-3.5 w-3.5 text-indigo-400"
      />
    </button>
    <template #popper>
      <div class="w-42 p-1 space-y-0.5">
        <span
          class="inline-flex items-center rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
          :class="[nearbyCompaniesStore.badgeFor(involvement)]"
          >{{ nearbyCompaniesStore.groupFor(involvement) }}</span
        >
        <div v-if="roles.length > 0" class="text-sm font-medium text-gray-700">
          {{ roles.join(", ") }}
        </div>
        <div
          v-else-if="subType && _.trim(subType) !== ''"
          class="text-sm font-medium text-gray-700"
        >
          {{ _.startCase(subType) }}
        </div>
        <div
          v-else-if="isHunt(involvement)"
          class="text-sm font-medium text-gray-700"
        >
          {{
            _.isObject(involvement.fieldContent?.name)
              ? "Hunt"
              : involvement.fieldContent?.name
          }}
        </div>
        <template v-if="isHunt(involvement)"></template>
        <template v-else-if="isHuntAdvisory(involvement)">
          <div class="text-xs text-gray-500">
            {{ clientNameFor(involvement) }}
          </div>
        </template>
        <template v-else>
          <div class="text-xs text-gray-500">
            {{ nearbyCompaniesStore.propertyNameFor(involvement) }}
          </div>
          <div class="text-xs text-gray-500">
            {{ nearbyCompaniesStore.propertyLocationFor(involvement) }}
          </div>
        </template>
      </div>
      <div
        v-if="
          involvement.fieldContent?.availability ||
          involvement.fieldContent?.investment ||
          involvement.fieldContentType === 'Hunt' ||
          involvement.fieldContent?.huntDataField
        "
        class="min-w-[500px]"
      >
        <AvailabilityDetailsTabs
          v-if="involvement.fieldContent?.availability"
          :availability="involvement.fieldContent?.availability"
          :from-menu="true"
        />
        <InvestmentDetailsTabs
          v-else-if="involvement.fieldContent?.investment"
          :investment="involvement.fieldContent?.investment"
          :from-menu="true"
        />
        <DataFieldDetails
          v-else-if="involvement.fieldContentType === 'Hunt'"
          :data-field="involvement"
          :from-menu="true"
        />
        <DataFieldDetails
          v-else-if="involvement.fieldContent?.huntDataField"
          :data-field="involvement.fieldContent?.huntDataField"
          :from-menu="true"
        />
      </div>
    </template>
  </VMenu>
</template>

<script setup>
import { useMainMapStore } from "@/stores/mainMap";
import { useNearbyCompaniesStore } from "@/stores/nearbyCompanies";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import {
  isProspect,
  assetDataFieldFor,
  assetMetaFor,
  investmentFor,
  isLoan,
  isHunt,
  isUser,
  isHuntAdvisory,
  propertyIdFor,
  clientNameFor,
  involvementRolesFor,
  assetSubTypeFor,
  isLiveInvestment,
  isUnderContractInvestment,
  isWithdrawnInvestment,
  assetInPlay,
  spaceUsageOnTheHunt,
} from "@/components/company-detail/companyInvolvementGroups";
import AvailabilityDetailsTabs from "@/components/space-usage-builder/AvailabilityDetailsTabs.vue";
import InvestmentDetailsTabs from "@/components/deal-builder/InvestmentDetailsTabs.vue";
import DataFieldDetails from "@/components/crowdsourcing/DataFieldDetails.vue";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import { storeToRefs } from "pinia";
import _ from "lodash";
import api from "@/router/api";
import { useRoute, useRouter } from "vue-router";
import {
  ExclamationTriangleIcon,
  Square2StackIcon,
} from "@heroicons/vue/20/solid";
import { computed, ref } from "vue";

const props = defineProps(["involvement", "grouped"]);

const nearbyCompaniesStore = useNearbyCompaniesStore();
const dealBuilderStore = useDealBuilderStore();
const { dealBuilder } = storeToRefs(dealBuilderStore);
const mapStore = useMainMapStore();
const { propertyMarkerPulseId } = storeToRefs(mapStore);
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const roles = involvementRolesFor(props.involvement);
const subType = computed(() => {
  if (props.grouped) {
    if (isUser(props.involvement)) {
      return "Multiple Spaces";
    } else {
      return "Multiple Assets";
    }
  } else {
    return assetSubTypeFor(props.involvement);
  }
});
const heroImageFetched = ref(false);
const heroImage = computed(() => {
  if (heroImageFetched.value) {
    return nearbyCompaniesStore.heroImageFor(props.involvement);
  } else {
    return null;
  }
});

async function fetchHeroImage(isVisible) {
  const fetchOutcome = await nearbyCompaniesStore.fetchHeroImage(
    isVisible,
    props.involvement,
  );

  if (fetchOutcome) {
    heroImageFetched.value = true;
  }
}

const router = useRouter();
const route = useRoute();

function pulseProperty() {
  const propertyField = nearbyCompaniesStore.propertyDataFieldFor(
    props.involvement,
  );
  const propertyId =
    propertyField?.fieldContent?.groundLayerPropertyId ||
    propertyField?.fieldContentId;

  if (propertyId) propertyMarkerPulseId.value = propertyId;
}
async function handleHover() {
  if (
    propertyIdFor(props.involvement) &&
    !nearbyCompaniesStore.propertyDataFieldFor(props.involvement) &&
    props.involvement?.unmasked
  ) {
    await nearbyCompaniesStore.fetchPropertyDataField(
      propertyIdFor(props.involvement),
    );
  }

  pulseProperty();
}

async function viewDetails() {
  let assetDataField = assetDataFieldFor(props.involvement);
  const assetMeta = assetMetaFor(props.involvement);

  if (assetDataField) {
    const alreadySelected = _.includes(
      _.keys(_.get(dealBuilder.value, "assets", {})),
      decoratingAndFieldKey(assetDataField),
    );

    if (alreadySelected) {
      router.push({
        name: route.name,
        query: {
          ...route.query,
          horizontalTab: "Deals",
          verticalTab: undefined,
        },
      });
    } else {
      let investment = investmentFor(props.involvement);

      if (isLoan(props.involvement)) {
        const loanId = assetMeta?.id;
        if (loanId) {
          const response = await api.get(`loan_collateral/${loanId}`);
          const loanCollateral = response.data;

          if (loanCollateral) assetDataField = loanCollateral;
        }
      }
      dealBuilderStore.upsertDealBuilder({
        assetDataField,
        timelineInvestmentId: investment?.id,
        focus: "Players",
      });
    }
  } else if (assetMeta) {
    pulseProperty();
  } else {
    console.log("unknown involvement action", props.involvement);
  }
}
</script>
