import { computed, ref, watch, nextTick, markRaw } from "vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useModalStore } from "@/stores/modal";
import { useUnlockerStore } from "@/stores/unlocker";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useNotificationsStore } from "@/stores/notifications";
import { useDataFieldLoadingStore } from "@/stores/dataFieldLoadingManagement";
import { defineStore, storeToRefs, acceptHMRUpdate } from "pinia";
import { useRouter, useRoute } from "vue-router";
import unlockableInnerContent from "@/components/crowdsourcing/unlockableInnerContent";
import subscribeInterceptor from "@/components/crowdsourcing/subscribeInterceptor";
import UnlicensedContentWarning from "@/components/users/subscribe-prompts/UnlicensedContentWarning.vue";
import api from "@/router/api";
import _ from "lodash";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import moment from "moment";
import { groupId, awardable } from "@/assets/transactionHelpers";
import {
  matchingAvailabilityGroup,
  topLevelSpaceDataField,
  localSpaceUsers,
  spaceField,
  awardedUsersFetchRequestKey,
  usersFetchRequestKey,
} from "@/assets/availabilityHelpers";
import {
  existingContentId,
  existingContentType,
  unknownSupplySide,
  mustBeClosed,
  fetchMilliseconds,
  contentType,
  contentId,
} from "@/assets/transactionHelpers";

export const useSpaceUsageBuilderStore = defineStore(
  "spaceUsageBuilder",
  () => {
    const dataFieldLoadingStore = useDataFieldLoadingStore();
    const notificationsStore = useNotificationsStore();
    const changeGroupStore = useCrowdsourcedChangeGroupStore();
    const { changeGroupId } = storeToRefs(changeGroupStore);
    const timeTravelStore = useTimeTravelStore();
    const { asOfMilliseconds, timeTravelTo } = storeToRefs(timeTravelStore);
    const propertyDiagramStore = usePropertyDiagramStore();
    const {
      selectedTimelineEvent,
      propertyIdParam,
      propertyDiagramPropertyIds,
    } = storeToRefs(propertyDiagramStore);
    const layoutStore = useWorkspaceLayoutStore();
    const { workspaceLayout } = storeToRefs(layoutStore);
    const upgradeInProgress = ref(false);
    const { resetRequired, upgradeSuccessful } = storeToRefs(
      useUnlockerStore(),
    );
    const { modalPayload } = storeToRefs(useModalStore());

    const spaceUsageBuilder = ref(null);
    const fetchingSpaceUsageBuilderData = ref(false);
    const refetchSpaceUsageBuilderEditor = ref(null);
    const allFetchedFields = ref([]);
    const fetchRequests = ref([]);
    const availabilityPatchableTimelineEvents = ref([]);
    const patchableCompanyInvolvementFieldIds = ref([]);
    const builderCalculationData = ref({});

    const crossInteraction = computed({
      get() {
        return _.get(spaceUsageBuilder.value, "crossInteraction");
      },
      set(val) {
        if (spaceUsageBuilder.value)
          spaceUsageBuilder.value.crossInteraction = val;
      },
    });

    const availabilityGroups = computed(() => {
      return _.get(spaceUsageBuilder.value, "availabilityGroups", []);
    });
    const spaces = computed(() => {
      return _.get(spaceUsageBuilder.value, "spaces", {});
    });
    const locatedSpaces = computed(() => {
      return _.filter(spaces.value, function ({ dataField }) {
        return _.includes(
          ["FloorArea", "PropertyEnhancement", "LayoutPartition"],
          dataField?.fieldContentType,
        );
      });
    });
    const unlocatedSpaces = computed(() => {
      return _.filter(spaces.value, function ({ dataField }) {
        return _.includes(["LandCovering"], dataField?.fieldContentType);
      });
    });
    const spacesLength = computed(() => {
      return _.size(spaces.value);
    });
    const spaceFieldIds = computed(() => {
      if (spaceUsageBuilder.value) {
        return _.map(spaceUsageBuilder.value.spaces, function (spaceObject) {
          return spaceObject.dataField.localId;
        });
      } else {
        return [];
      }
    });

    const contactInvolvementFields = computed(() => {
      return allFetchedFields.value.filter((cdf) => {
        return cdf.fieldContentType === "ContactCompanyInvolvement";
      });
    });
    const patchableCompanySize = computed(
      () => patchableCompanyInvolvementFieldIds.value.length,
    );
    const contactFieldSize = computed(
      () => contactInvolvementFields.value.length,
    );

    function matchingSpaceObject(spaceKey) {
      if (spaceUsageBuilder.value) {
        return spaceUsageBuilder.value.spaces[spaceKey];
      } else {
        return null;
      }
    }

    // ALREADY FETCHED

    const fetchedKeys = computed(() =>
      fetchRequests.value.map((request) => request.key),
    );

    function alreadyFetched(fetchRequestKey) {
      return _.includes(fetchedKeys.value, fetchRequestKey);
    }
    function registerFetchRequest(dataFields, fetchRequestKey) {
      const payload = {
        key: fetchRequestKey,
        fieldIds: dataFields.map((field) => field.localId),
      };

      fetchRequests.value = _.unionBy([payload], fetchRequests.value, "key");
    }
    function alreadyFetchedFieldsFor(fetchRequestKey) {
      const request = _.find(fetchRequests.value, { key: fetchRequestKey });

      return allFetchedFields.value.filter((field) =>
        _.includes(request?.fieldIds || [], field.localId),
      );
    }

    const router = useRouter();
    const route = useRoute();
    const query = computed(() => route.query);
    const horizontalIsSpaces = computed(
      () => _.get(query.value, "horizontalTab") === "Spaces",
    );

    async function upsertSpaceUsageBuilder({
      spaceDataField = null,
      timeTravelDate = undefined,
      timelineAvailabilityId = null,
      existingGroupId = null,
      collapseAll = true,
    }) {
      let spaceKey = null;
      let newCrossInteraction = null;
      if (timelineAvailabilityId) {
        newCrossInteraction = {
          spaceKey,
          combinedKey: timelineAvailabilityId,
          focus,
          source: "TimelineAvailability",
        };
      }
      let asOf = undefined;

      if (spaceUsageBuilder.value) {
        if (spaceDataField) {
          spaceKey = decoratingAndFieldKey(spaceDataField);
          const unlocatedSpace =
            spaceDataField.fieldContentType === "LandCovering";
          const unlocatedSpaces = unlocatedSpace
            ? { unlocatedSpaces: [], selectedUnlocatedSpace: null }
            : {};
          const spaceObject = _.merge(
            {},
            {
              dataField: spaceDataField,
              availabilities: [],
              spaceUsers: [],
              expanded: true,
              datedTimelineFetched: false,
              undatedTimelineFetched: false,
            },
            unlocatedSpaces,
          );

          crossInteraction.value = newCrossInteraction;
          addSpace({
            spaceObject,
            spaceKey,
          });
        }
      } else {
        if (spaceDataField) {
          spaceKey = decoratingAndFieldKey(spaceDataField);
        }

        newBuilder({
          spaceDataField,
        });
      }

      if (existingGroupId) {
        addAvailabilityGroup({
          existingGroupId,
        });
      }

      await nextTick();
      router.push({
        name: route.name,
        query: {
          ...route.query,
          horizontalTab: "Spaces",
          verticalTab: undefined,
          spaceFieldIds: spaceFieldIds.value,
          asOf,
        },
      });
      await nextTick();

      if (collapseAll) collapseAllSpacesExcept({ spaceKey });

      if (
        (spaceDataField?.joiningContentType === "Property" ||
          spaceDataField?.fieldContent?.propertyId) &&
        propertyIdParam.value
      ) {
        const propertyId =
          spaceDataField.joiningContentType === "Property"
            ? spaceDataField.joiningContentId
            : spaceDataField.fieldContent?.propertyId;
        if (
          propertyId &&
          !_.includes(propertyDiagramPropertyIds.value, propertyId)
        ) {
          propertyDiagramStore.addPropertyToDiagram(propertyId);
        }
      }

      if (timeTravelDate) {
        setTimeout(() => {
          asOf = moment(timeTravelDate).valueOf();
          timeTravelTo.value = timeTravelDate;
        }, 250);
      }

      if (workspaceLayout.value === "fullScreen") {
        layoutStore.matchLayoutToScreen();
      }
    }

    function clearBuilder(clearRootObject = true, clearCalculationData = true) {
      if (clearRootObject) spaceUsageBuilder.value = null;
      if (clearCalculationData) builderCalculationData.value = {};

      allFetchedFields.value = [];
      fetchRequests.value = [];
    }

    // MANAGE FIELDS

    function addTimingFieldsToAvailabilityGroup({
      groupId,
      newFields,
      context,
    }) {
      if (groupId) {
        const existingGroup = _.find(availabilityGroups.value, {
          id: groupId,
        });
        const placeholderGroup = _.find(availabilityGroups.value, {
          placeholderId: groupId,
        });
        const group = existingGroup || placeholderGroup;

        if (group) {
          let timingFieldsPropName = "timingFields";

          switch (context) {
            case "cashflow":
              timingFieldsPropName = "cashflowTimingFields";
              break;
            case "contract":
              timingFieldsPropName = "contractTimingFields";
              break;
            case "occupancy":
              timingFieldsPropName = "occupancyTimingFields";
              break;
          }

          group[timingFieldsPropName] = _.unionBy(
            newFields,
            group[timingFieldsPropName],
            "localId",
          );
        } else {
          console.log("no group to add timing fields to", context);
        }
      }
    }

    async function fetchGroupTimingFields(groupId, context = "availability") {
      const response = await api.get(
        `availability_group_timing_fields/${groupId}?context=${context}`,
      );

      addTimingFieldsToAvailabilityGroup({
        groupId,
        newFields: response.data,
        context,
      });
    }

    async function checkGroupTimingUnlockability(
      groupId,
      context = "availability",
    ) {
      const response = await api.get(
        `availability_group_timing_unlockability/${groupId}?context=${context}`,
      );

      return response;
    }

    async function checkGroupCashflowUnlockability(groupId) {
      const response = await api.get(
        `availability_group_cashflow_unlockability/${groupId}`,
      );

      return response;
    }

    function interceptableField(dataField) {
      return _.get(dataField, "meteringStatus") === "prompt_to_subscribe";
    }
    function interceptablePatch(dataFields, fetchRequestKey = null) {
      if (_.some(dataFields, interceptableField)) {
        promptToSubscribe();
      } else {
        patchDiagramFields(dataFields);
        if (fetchRequestKey) registerFetchRequest(dataFields, fetchRequestKey);
      }
    }
    function unlockWarn() {
      return new Promise((resolve) => {
        if (!modalPayload.value) {
          modalPayload.value = {
            size: "base",
            theme: "light",
            component: markRaw(UnlicensedContentWarning),
            props: {},
          };
          resolve();
        } else {
          resolve();
        }
      });
    }
    function promptToSubscribe() {
      return new Promise((resolve) => {
        if (!modalPayload.value) {
          upgradeInProgress.value = true;
          const successCallback = () => {
            if (upgradeSuccessful.value) {
              console.log("user subscribed");
              upgradeInProgress.value = false;
              upgradeSuccessful.value = false;
              // fetchDiagramData();
              resolve();
            }
          };

          subscribeInterceptor({
            apiRequestFunc: null,
            successCallback,
            modalPayloadRef: modalPayload,
            upgradeSuccessfulRef: upgradeSuccessful,
            afterSubscribe: "successCallback",
            promptReason: "dataUsage",
            context: "licensing",
          });
        } else {
          resolve();
        }
      });
    }
    async function postEditingPatch(json, fetchRequestKey = null) {
      let newData = null;
      if (json.data.dataField) newData = [json.data.dataField];
      else if (json.data.dataFields) newData = json.data.dataFields;

      if (newData) patchDiagramFields(newData, fetchRequestKey);
      if (json.data.timelineEvents) {
        availabilityPatchableTimelineEvents.value = json.data.timelineEvents;
      }
    }
    function patchDiagramFields(newFields, fetchRequestKey = null) {
      return new Promise((resolve) => {
        dropInterceptFields();
        allFetchedFields.value = _.unionBy(
          newFields,
          allFetchedFields.value,
          "localId",
        );
        dropDeprecatedFields();
        if (fetchRequestKey) {
          const remainingFields = _.intersectionBy(
            newFields,
            allFetchedFields.value,
            "localId",
          );
          updateFetchRequests(remainingFields, fetchRequestKey);
        }
        patchCompanyInvolvements(newFields);

        if (_.some(newFields, unlockableInnerContent)) {
          unlockWarn();
        }

        resolve();
      });
    }
    function dropInterceptFields() {
      const strippedFields = allFetchedFields.value.filter((dataField) => {
        return !dataField.meteringStatus;
      });
      allFetchedFields.value = strippedFields;
      cleanUpRequests();
    }
    function dropDeprecatedFields() {
      const strippedFields = allFetchedFields.value.filter((dataField) => {
        return !dataField.deprecatedAt && !dataField.dismissed;
      });
      allFetchedFields.value = strippedFields;
      cleanUpRequests();
    }
    function patchCompanyInvolvements(newFields) {
      // decoratingContentType: 'OwnershipInterest', fieldContentType: 'CompanyInvolvement'
      const involvementFields = newFields.filter(
        (field) => field.fieldContentType === "CompanyInvolvement",
      );
      const fieldIds = involvementFields.map((field) => field.localId);

      patchableCompanyInvolvementFieldIds.value = _.union(
        fieldIds,
        patchableCompanyInvolvementFieldIds.value,
      );
    }
    function dropFetchRequest(dropKey) {
      fetchRequests.value = fetchRequests.value.filter(
        ({ key }) => key !== dropKey,
      );
    }
    function cleanUpRequests() {
      fetchRequests.value.forEach(({ key }) => updateFetchRequests([], key));
    }
    function updateFetchRequests(dataFields, fetchRequestKey) {
      const stillPresentFields = alreadyFetchedFieldsFor(fetchRequestKey);
      // console.log(
      //   "still present",
      //   fetchRequestKey,
      //   stillPresentFields.map((field) => field.localId)
      // );
      const updatedFields = _.unionBy(
        dataFields,
        stillPresentFields,
        "localId",
      );
      // console.log(
      //   "updated",
      //   fetchRequestKey,
      //   updatedFields.map((field) => field.localId)
      // );

      registerFetchRequest(updatedFields, fetchRequestKey);
    }

    watch(resetRequired, () => {
      if (upgradeInProgress.value) {
        resetRequired.value = false;
        upgradeInProgress.value = false;
      }
    });

    // PREPOPULATE
    function prepopulateBuilder({
      depth,
      stubbedAvailability = null,
      topLevelSpaceDataField,
      decoratingDataField = null,
    }) {
      if (spaceUsageBuilder.value) {
        crossInteraction.value = null;

        addAvailabilityGroup({
          spaceDataField: topLevelSpaceDataField,
          availabilityFieldContent: decoratingDataField,
          depth,
          stubbedAvailability,
        });
      } else {
        beginBuilder({
          depth,
          stubbedAvailability,
          spaceDataField: topLevelSpaceDataField,
          availabilityFieldContent: decoratingDataField,
        });
      }
    }

    async function populateBuilderFromQuery(ids) {
      for await (const id of ids) {
        try {
          fetchingSpaceUsageBuilderData.value = true;
          const response = await api.get(`data_field_by_id/${id}`);
          fetchingSpaceUsageBuilderData.value = false;
          const dataField = response.data;
          const spaceKey = decoratingAndFieldKey(dataField);
          const unlocatedSpace =
            _.get(dataField, "fieldContentType") === "LandCovering";
          const unlocatedSpaces = unlocatedSpace
            ? { unlocatedSpaces: [], selectedUnlocatedSpace: null }
            : {};
          if (spaceUsageBuilder.value) {
            const spaceObject = _.merge(
              {},
              {
                dataField,
                availabilities: [],
                spaceUsers: [],
                expanded: true,
                datedTimelineFetched: false,
                undatedTimelineFetched: false,
              },
              {
                diagramBorderColor: spaceBorderColors[spaceModIndex.value],
                diagramLegendColor: spaceLegendColors[spaceModIndex.value],
              },
              unlocatedSpaces,
            );

            addSpace({
              spaceObject,
              spaceKey,
            });
          } else {
            newBuilder({
              spaceDataField: dataField,
            });
          }
        } catch (error) {
          fetchingSpaceUsageBuilderData.value = false;
          console.log(error);
        }
      }
    }

    const spaceModIndex = computed(
      () => _.size(spaceUsageBuilder.value?.spaces || {}) % 6,
    );
    const spaceBorderColors = [
      "border-red-600",
      "border-orange-500",
      "border-yellow-500",
      "border-lime-600",
      "border-teal-600",
      "border-sky-600",
    ];
    const spaceLegendColors = [
      "bg-red-600",
      "bg-orange-500",
      "bg-yellow-500",
      "bg-lime-600",
      "bg-teal-600",
      "bg-sky-600",
    ];

    async function addSpace({ spaceObject, spaceKey }) {
      const unlocatedSpace =
        _.get(spaceObject, "dataField.fieldContentType") === "LandCovering";
      const unlocatedSpaces = unlocatedSpace
        ? {
            unlocatedSpaces: _.get(spaceObject, "unlocatedSpaces", []),
            selectedUnlocatedSpace: _.get(
              spaceObject,
              "selectedUnlocatedSpace",
              null,
            ),
          }
        : {};
      const newSpace = _.merge(
        {},
        {
          dataField: _.get(spaceObject, "dataField"),
          availabilities: _.get(spaceObject, "availabilities", []),
          spaceUsers: _.get(spaceObject, "spaceUsers", []),
          expanded: _.get(spaceObject, "expanded", true),
          datedTimelineFetched: _.get(
            spaceObject,
            "datedTimelineFetched",
            false,
          ),
          undatedTimelineFetched: _.get(
            spaceObject,
            "undatedTimelineFetched",
            false,
          ),
        },
        {
          diagramBorderColor: unlocatedSpace
            ? null
            : _.get(
                spaceObject,
                "diagramBorderColor",
                spaceBorderColors[spaceModIndex.value],
              ),
          diagramLegendColor: unlocatedSpace
            ? null
            : _.get(
                spaceObject,
                "diagramLegendColor",
                spaceLegendColors[spaceModIndex.value],
              ),
        },
        unlocatedSpaces,
      );

      spaceUsageBuilder.value.spaces[spaceKey] = newSpace;
      await nextTick();

      if (horizontalIsSpaces.value) {
        router.push({
          name: route.name,
          query: {
            ...route.query,
            horizontalTab: "Spaces",
            verticalTab: undefined,
            spaceFieldIds: spaceFieldIds.value,
          },
        });
      }
    }

    function setSelectedUnlocatedSpace({ spaceKey, selectedSpace = null }) {
      const space = spaceUsageBuilder.value.spaces[spaceKey];

      if (space && space.unlocatedSpaces) {
        space.selectedUnlocatedSpace = selectedSpace;
      }
    }

    function beginBuilder({
      depth,
      stubbedAvailability = null,
      spaceDataField = null,
      availabilityFieldContent = null,
    }) {
      newBuilder({
        spaceDataField,
        availabilityFieldContent,
        depth,
        stubbedAvailability,
      });
      router.push({
        name: route.name,
        query: {
          ...route.query,
          horizontalTab: "Spaces",
          verticalTab: undefined,
          spaceFieldIds: spaceFieldIds.value,
        },
      });
    }

    function newBuilder({
      spaceDataField = null,
      availabilityFieldContent = null,
      crossInteraction = null,
      depth,
      stubbedAvailability = null,
    }) {
      let spaceKey = null;
      let spaces = {};

      if (spaceDataField) {
        spaceKey = decoratingAndFieldKey(spaceDataField);
        const unlocatedSpace =
          spaceDataField.fieldContentType === "LandCovering";
        const unlocatedSpaces = unlocatedSpace
          ? { unlocatedSpaces: [], selectedUnlocatedSpace: null }
          : {};
        spaces[spaceKey] = _.merge(
          {},
          {
            dataField: spaceDataField,
            availabilities: [],
            spaceUsers: [],
            expanded: true,
            datedTimelineFetched: false,
            undatedTimelineFetched: false,
          },
          {
            diagramBorderColor: spaceBorderColors[spaceModIndex.value],
            diagramLegendColor: spaceLegendColors[spaceModIndex.value],
          },
          unlocatedSpaces,
        );
      }

      let availabilityGroups = [];
      let currentAvailabilityGroupPlaceholderId = 1000;

      if (availabilityFieldContent) {
        let availabilities = {};
        const availabilityKey = decoratingAndFieldKey(availabilityFieldContent);
        const combinedKey = `${spaceKey}_${availabilityKey}`;
        let newAvailability = {
          availabilityGroupId: `temp${currentAvailabilityGroupPlaceholderId}`,
          temporaryId: combinedKey,
          topLevelSpace: spaceDataField,
          availabilityFieldContent,
          spaceUsers: [],
          advisors: [],
          date: null,
          state: null,
          commencedDate: null,
          expiredDate: null,
          depth,
        };

        if (stubbedAvailability) {
          availabilities[combinedKey] = _.merge(
            {},
            newAvailability,
            stubbedAvailability,
          );
        } else {
          availabilities[combinedKey] = newAvailability;
        }

        const initialGroup = {
          placeholderId: `temp${currentAvailabilityGroupPlaceholderId}`,
          availabilities,
        };

        availabilityGroups.push(initialGroup);
        currentAvailabilityGroupPlaceholderId++;
      }

      const initialStructure = {
        spaces,
        crossInteraction,
        currentAvailabilityGroupPlaceholderId,
        availabilityGroups,
      };

      spaceUsageBuilder.value = initialStructure;
    }

    function setSpaceUsers({ spaceKey, spaceUsers = [] }) {
      if (
        spaceUsageBuilder.value?.spaces &&
        spaceUsageBuilder.value.spaces[spaceKey]
      ) {
        spaceUsageBuilder.value.spaces[spaceKey].spaceUsers = spaceUsers;
      }
    }

    async function updateSpaceAvailabilities({ spaceKey, availabilities }) {
      const oldSpaceObject = spaceUsageBuilder.value?.spaces?.[spaceKey];

      if (oldSpaceObject) {
        for (const availability of availabilities) {
          let matchingId = null;

          if (availability.fieldContentId) {
            let newAvailabilities = [];
            if (availability.fieldContent) {
              newAvailabilities = _.unionBy(
                [availability],
                oldSpaceObject.availabilities,
                "fieldContentId",
              );
            } else {
              const response = await api.get(
                `data_field_by_id/${availability.localId}`,
              );

              if (response?.data) {
                newAvailabilities = _.unionBy(
                  [response?.data],
                  oldSpaceObject.availabilities,
                  "fieldContentId",
                );
              }
            }
            // console.log("union new with old", newAvailabilities);
            oldSpaceObject.availabilities = newAvailabilities;
          } else if (availability.existingAvailability) {
            // console.log("has existing availability");
            matchingId = availability.existingAvailability.id;
          } else {
            // console.log("temp or direct id");
            matchingId = availability.temporaryId || availability.id;
          }

          if (matchingId) {
            let matchingOldAvailability = _.find(
              oldSpaceObject.availabilities,
              {
                fieldContentId: matchingId,
              },
            );

            if (matchingOldAvailability) {
              matchingOldAvailability.fieldContent = availability;
            }
          }
        }

        addSpace({
          spaceObject: oldSpaceObject,
          spaceKey,
        });
      }
    }

    async function clearSpaceUnlocatedSpaces({ spaceKey }) {
      const oldSpaceObject = spaceUsageBuilder.value?.spaces?.[spaceKey];
      oldSpaceObject.selectedUnlocatedSpace = null;
      oldSpaceObject.unlocatedSpaces = [];

      addSpace({
        spaceObject: oldSpaceObject,
        spaceKey,
      });
    }

    async function updateSpaceUnlocatedSpaces({ spaceKey, unlocatedSpaces }) {
      const oldSpaceObject = spaceUsageBuilder.value?.spaces?.[spaceKey];

      if (oldSpaceObject) {
        for (const space of unlocatedSpaces) {
          if (space.fieldContentId) {
            let newUnlocatedSpaces = [];
            if (space.fieldContent) {
              newUnlocatedSpaces = _.unionBy(
                [space],
                oldSpaceObject.unlocatedSpaces,
                "fieldContentId",
              );
            } else {
              const response = await api.get(
                `data_field_by_id/${space.localId}`,
              );

              if (response?.data) {
                newUnlocatedSpaces = _.unionBy(
                  [response?.data],
                  oldSpaceObject.unlocatedSpaces,
                  "fieldContentId",
                );
              }
            }
            // console.log("union new with old", newUnlocatedSpaces);
            oldSpaceObject.unlocatedSpaces = newUnlocatedSpaces;
          }
        }

        addSpace({
          spaceObject: oldSpaceObject,
          spaceKey,
        });
      }
    }

    function setTimelineFetchState({ spaceKey, dated, state = false }) {
      if (spaceKey && spaceUsageBuilder.value?.spaces?.[spaceKey]) {
        const key = dated ? "datedTimelineFetched" : "undatedTimelineFetched";

        if (spaceUsageBuilder.value?.spaces?.[spaceKey]?.[key]) {
          spaceUsageBuilder.value.spaces[spaceKey][key] = state;
        }
      }
    }

    async function refreshAvailabilityGroup({
      closingGroupId,
      groupId,
      isExistingGroup,
    }) {
      if (groupId) {
        removeAvailabilityGroup({
          groupId: closingGroupId || groupId,
          existing: isExistingGroup,
        });
        clearBuilder(false);
        refetchSpaceUsageBuilderEditor.value = true;

        await nextTick();

        console.log("add group again");

        addAvailabilityGroup({
          existingGroupId: groupId,
        });
      }
    }

    async function fetchAvailabilityGroupAvailabilities(availabilityGroupId) {
      if (availabilityGroupId) {
        fetchingSpaceUsageBuilderData.value = true;
        const response = await api.get(
          `availability_group_availabilities/${availabilityGroupId}`,
        );
        const availabilityDataFields = response.data;

        availabilityDataFields.forEach((dataField) => {
          addAvailabilityToGroup({
            existingGroupId: availabilityGroupId,
            existingAvailability: dataField.fieldContent,
            groupAvailabilityDataField: dataField,
          });
        });

        fetchingSpaceUsageBuilderData.value = false;

        collapsePortfolioSpaces({
          groupId: availabilityGroupId,
        });
        setAvailabilityGroupExpanded({
          groupId: availabilityGroupId,
          expanded: true,
        });
      }
    }

    function setSpaceExpanded({ spaceKey, expanded, availability = null }) {
      if (spaceKey && spaceUsageBuilder.value.spaces[spaceKey]) {
        spaceUsageBuilder.value.spaces[spaceKey].expanded = expanded;
      } else if (availability && availability.id) {
        const derivedSpaceKey = _.findKey(
          spaceUsageBuilder.value.spaces,
          function (space) {
            return !!_.find(space.availabilities, { id: availability.id });
          },
        );

        if (derivedSpaceKey) {
          spaceUsageBuilder.value.spaces[derivedSpaceKey].expanded = expanded;
        }
      }
    }

    function setAvailabilityGroupExpanded({ groupId, expanded }) {
      const existingGroup = _.find(availabilityGroups.value, {
        id: groupId,
      });
      const placeholderGroup = _.find(availabilityGroups.value, {
        placeholderId: groupId,
      });
      const group = existingGroup || placeholderGroup;

      if (group) {
        group.expanded = expanded;
      }
    }

    function collapseAllSpacesExcept({ spaceKey }) {
      _.forEach(spaces.value, function (spaceObject, key) {
        if (key !== spaceKey) {
          setSpaceExpanded({
            spaceKey: key,
            expanded: false,
          });
        }
      });
    }

    function collapsePortfolioSpaces({ groupId }) {
      const existingGroup = _.find(availabilityGroups.value, {
        id: groupId,
      });
      const placeholderGroup = _.find(availabilityGroups.value, {
        placeholderId: groupId,
      });
      const group = existingGroup || placeholderGroup;

      if (group) {
        _.forEach(group.availabilities, function (availability) {
          const spaceKey = availability.capitalStackTopLevelAsset
            ? decoratingAndFieldKey(availability.capitalStackTopLevelAsset)
            : null;

          setSpaceExpanded({
            spaceKey,
            expanded: false,
            availability: availability.existingAvailability,
          });
        });
      }
    }

    function availabilitiesLength(availabilityGroup) {
      return _.size(availabilityGroup.availabilities);
    }

    // EDITING

    function removeSpace(spaceKey) {
      delete spaceUsageBuilder.value.spaces[spaceKey];

      router.push({
        name: route.name,
        query: {
          ...route.query,
          horizontalTab: "Spaces",
          verticalTab: undefined,
          spaceFieldIds: spaceFieldIds.value,
        },
      });
    }

    function removeSpaceAvailability({ availabilityKey }) {
      if (!spaceUsageBuilder.value) return;

      availabilityGroups.value.forEach((group) => {
        const availability = _.get(group, `availabilities[${availabilityKey}]`);

        if (availability) {
          delete group.availabilities[availabilityKey];
        }
      });

      const remainingGroups = availabilityGroups.value.filter((group) => {
        return _.size(group.availabilities) > 0;
      });

      spaceUsageBuilder.value.availabilityGroups = remainingGroups;
    }

    function removeAvailabilityGroup({ groupId, existing = true }) {
      let group, differenceKey;

      if (existing) {
        group = _.find(availabilityGroups.value, {
          id: groupId,
        });
        differenceKey = "id";
      } else {
        group = _.find(availabilityGroups.value, {
          placeholderId: groupId,
        });
        differenceKey = "placeholderId";
      }

      const filteredGroups = _.differenceBy(
        availabilityGroups.value,
        [group],
        differenceKey,
      );

      spaceUsageBuilder.value.availabilityGroups = filteredGroups;
    }

    function resetAvailabilities() {
      _.forEach(spaces.value, function (spaceObject) {
        spaceObject.availabilities = [];
      });
    }

    function removeAvailabilityGroupPlayer({
      groupId,
      availabilityCombinedKey,
      toRemove,
      path,
      compareBy = _.identity,
    }) {
      if (groupId) {
        const existingGroup = _.find(availabilityGroups.value, {
          id: groupId,
        });
        const placeholderGroup = _.find(availabilityGroups.value, {
          placeholderId: groupId,
        });
        const group = existingGroup || placeholderGroup;
        const availability = _.get(
          group,
          `availabilities[${availabilityCombinedKey}]`,
        );
        let filteredSpaceUsers;

        if (availability.existingAvailability) {
          filteredSpaceUsers = _.differenceBy(
            _.get(availability, `existingAvailability.${path}`, []),
            [toRemove],
            compareBy,
          );

          group.availabilities[availabilityCombinedKey].existingAvailability[
            path
          ] = filteredSpaceUsers;
        } else {
          filteredSpaceUsers = _.differenceBy(
            _.get(availability, path, []),
            [toRemove],
            compareBy,
          );

          group.availabilities[availabilityCombinedKey][path] =
            filteredSpaceUsers;
        }
      }
    }

    function addAvailabilityGroupAvailabilityPlayers({
      groupId,
      availabilityCombinedKey,
      addedCompanies,
      path,
      compareBy = _.identity,
    }) {
      if (groupId) {
        const existingGroup = _.find(availabilityGroups.value, {
          id: groupId,
        });
        const placeholderGroup = _.find(availabilityGroups.value, {
          placeholderId: groupId,
        });
        const group = existingGroup || placeholderGroup;
        const availability = _.get(
          group,
          `availabilities[${availabilityCombinedKey}]`,
        );
        let newProviders;

        if (availability.existingAvailability) {
          newProviders = _.unionBy(
            _.get(availability, `existingAvailability.${path}`, []),
            addedCompanies,
            compareBy,
          );

          group.availabilities[availabilityCombinedKey].existingAvailability[
            path
          ] = newProviders;
        } else {
          newProviders = _.unionBy(
            _.get(availability, path, []),
            addedCompanies,
            compareBy,
          );

          group.availabilities[availabilityCombinedKey][path] = newProviders;
        }
      }
    }
    function removeAvailabilityGroupAvailabilityPlayer({
      groupId,
      availabilityCombinedKey,
      toRemove,
      path,
      compareBy = _.identity,
    }) {
      if (groupId) {
        const existingGroup = _.find(availabilityGroups.value, {
          id: groupId,
        });
        const placeholderGroup = _.find(availabilityGroups.value, {
          placeholderId: groupId,
        });
        const group = existingGroup || placeholderGroup;
        const availability = _.get(
          group,
          `availabilities[${availabilityCombinedKey}]`,
        );
        let filteredProviders;

        if (availability.existingAvailability) {
          filteredProviders = _.differenceBy(
            _.get(availability, `existingAvailability.${path}`, []),
            [toRemove],
            compareBy,
          );

          group.availabilities[availabilityCombinedKey].existingAvailability[
            path
          ] = filteredProviders;
        } else {
          filteredProviders = _.differenceBy(
            _.get(availability, path, []),
            [toRemove],
            compareBy,
          );

          group.availabilities[availabilityCombinedKey][path] =
            filteredProviders;
        }
      }
    }

    function addAvailabilityGroup({
      existingGroupId = null,
      existingAvailability = undefined,
      spaceDataField = null,
      availabilityFieldContent = null,
      depth,
      stubbedAvailability = null,
    }) {
      console.log("add group", stubbedAvailability);
      const borderColors = [
        "border-green-500",
        "border-blue-500",
        "border-purple-500",
        "border-fuchsia-500",
      ];
      const legendColors = [
        "bg-green-500",
        "bg-blue-500",
        "bg-purple-500",
        "bg-fuchsia-500",
      ];
      const modIndex =
        spaceUsageBuilder.value.currentAvailabilityGroupPlaceholderId % 4;
      const newGroup = {
        id: existingGroupId,
        placeholderId: `temp${spaceUsageBuilder.value.currentAvailabilityGroupPlaceholderId}`,
        diagramBorderColor: borderColors[modIndex],
        diagramLegendColor: legendColors[modIndex],
        availabilities: {},
        timingFields: [],
        cashflowTimingFields: [],
        contractTimingFields: [],
        occupancyTimingFields: [],
        expanded: !!existingAvailability,
      };

      if (!existingGroupId) {
        const spaceKey = decoratingAndFieldKey(spaceDataField);
        const availabilityKey = decoratingAndFieldKey(availabilityFieldContent);
        const combinedKey = `${spaceKey}_${availabilityKey}`;
        let newAvailability = {
          existingAvailability,
          availabilityGroupId:
            existingGroupId ||
            `temp${spaceUsageBuilder.value.currentAvailabilityGroupPlaceholderId}`,
          temporaryId: combinedKey,
          topLevelSpace: spaceDataField,
          availabilityFieldContent,
          spaceUsers: [],
          advisors: [],
          date: null,
          state: null,
          commencedDate: null,
          expiredDate: null,
          depth,
        };

        if (stubbedAvailability) {
          newGroup.availabilities[combinedKey] = _.merge(
            {},
            newAvailability,
            stubbedAvailability,
          );
        } else {
          newGroup.availabilities[combinedKey] = newAvailability;
        }
      }

      if (existingGroupId) {
        const newGroups = _.unionBy(
          availabilityGroups.value,
          [newGroup],
          function (group) {
            return group.id || group.placeholderId;
          },
        );

        spaceUsageBuilder.value.availabilityGroups = newGroups;
      } else {
        spaceUsageBuilder.value.availabilityGroups.push(newGroup);
      }
      spaceUsageBuilder.value.currentAvailabilityGroupPlaceholderId++;

      return newGroup;
    }

    function addAvailabilityToGroup({
      existingGroupId = null,
      existingAvailability = undefined,
      stubbedAvailability = null,
      groupAvailabilityDataField = undefined,
      spaceDataField = null,
      availabilityFieldContent = null,
      availabilityGroupPlaceholderId = null,
    }) {
      let group = null;

      if (existingGroupId) {
        group = _.find(availabilityGroups.value, {
          id: existingGroupId,
        });
      } else if (availabilityGroupPlaceholderId) {
        group = _.find(availabilityGroups.value, {
          placeholderId: availabilityGroupPlaceholderId,
        });
      }

      if (group) {
        let combinedKey;

        if (spaceDataField && availabilityFieldContent) {
          const spaceKey = decoratingAndFieldKey(spaceDataField);
          const availabilityKey = decoratingAndFieldKey(
            availabilityFieldContent,
          );

          combinedKey = `${spaceKey}_${availabilityKey}`;
        } else if (existingAvailability) {
          combinedKey = existingAvailability.id;
        }

        const newAvailability = _.merge(
          {},
          {
            existingAvailability,
            groupAvailabilityDataField,
            availabilityGroupId:
              existingGroupId || availabilityGroupPlaceholderId,
            temporaryId: combinedKey,
            topLevelSpace: spaceDataField,
            availabilityFieldContent,
            spaceUsers: [],
            advisors: [],
            date: null,
            state: null,
            commencedDate: null,
            expiredDate: null,
          },
        );

        if (stubbedAvailability) {
          group.availabilities[combinedKey] = _.merge(
            {},
            newAvailability,
            stubbedAvailability,
          );
        } else if (existingAvailability) {
          group.availabilities[combinedKey] = _.merge(
            {},
            newAvailability,
            existingAvailability,
          );
        } else {
          group.availabilities[combinedKey] = newAvailability;
        }
      }
    }

    function updateAvailabilityGroupAvailability({
      groupId,
      newAvailability,
      fieldName = null,
    }) {
      if (groupId) {
        const existingGroup = _.find(availabilityGroups.value, {
          id: groupId,
        });
        const placeholderGroup = _.find(availabilityGroups.value, {
          placeholderId: groupId,
        });
        const group = existingGroup || placeholderGroup;
        let combinedKey = null;

        if (
          newAvailability.topLevelSpace &&
          newAvailability.availabilityFieldContent &&
          group
        ) {
          const spaceKey = decoratingAndFieldKey(newAvailability.topLevelSpace);
          const availabilityKey = decoratingAndFieldKey(
            newAvailability.availabilityFieldContent,
          );

          combinedKey = `${spaceKey}_${availabilityKey}`;

          if (fieldName) {
            group.availabilities[combinedKey][fieldName] =
              newAvailability[fieldName];
          } else {
            group.availabilities[combinedKey] = newAvailability;
          }
        } else if (newAvailability.id) {
          // console.log("existing availability");
          const groupAvailability = _.find(
            group.availabilities,
            function (availability) {
              return (
                availability.existingAvailability.id === newAvailability.id
              );
            },
          );

          console.log(newAvailability);
          console.log(groupAvailability);

          combinedKey = groupAvailability.temporaryId;

          if (fieldName) {
            group.availabilities[combinedKey].existingAvailability[fieldName] =
              newAvailability[fieldName];
          } else {
            group.availabilities[combinedKey].existingAvailability =
              newAvailability;
          }
        }
      }
    }

    async function saveAvailabilityGroup({ placeholderId, successCallback }) {
      function persist(availability) {
        const focalAvailability =
          availability.existingAvailability || availability;

        let contentId = null;
        let contentType = null;

        if (!availability.existingAvailability) {
          contentId = focalAvailability.availabilityFieldContent.fieldContentId;
          contentType =
            focalAvailability.availabilityFieldContent.fieldContentType;
        }

        return {
          availabilityGroup: true,
          existingAvailabilityId: focalAvailability.id,
          existingDataFieldId: null,
          contentId: contentId,
          contentType: contentType,
          companies: _.get(focalAvailability, "spaceUsers", []),
          advisors: _.get(focalAvailability, "advisors", []),
          prospects: _.get(focalAvailability, "prospects", []),
          date: focalAvailability.date,
          state: focalAvailability.state,
          commencedDate: focalAvailability.commencedDate,
          expiredDate: focalAvailability.expiredDate,
          upstreamSpaceUsageId: _.get(
            focalAvailability,
            "upstreamSpaceUsageId",
            null,
          ),
        };
      }

      if (spaceUsageBuilder.value) {
        const group = _.find(availabilityGroups.value, {
          placeholderId,
        });

        if (group) {
          const groupPayload = {
            availabilities: _.map(group.availabilities, persist),
            changeGroupId: changeGroupId.value,
          };

          const apiRequestFunc = () =>
            api.post(`space_availability_groups`, groupPayload);
          const failureCallback = () => {};
          await changeGroupStore.originateData(
            apiRequestFunc,
            successCallback,
            failureCallback,
          );
        }
      }
    }

    // DEAL ACTIONS MENU

    async function awardAvailability({ availabilityId, companyInvolvementId }) {
      const apiRequestFunc = () =>
        api.post(`availability_awardings`, {
          availabilityId,
          companyInvolvementId,
          changeGroupId: changeGroupId.value,
        });
      await changeGroupStore.originateData(apiRequestFunc);
    }
    async function awardGroupAvailability({ availabilityGroupId, companyId }) {
      const apiRequestFunc = () =>
        api.post(`availability_group_awardings`, {
          availabilityGroupId,
          rawCompanyId: companyId,
          changeGroupId: changeGroupId.value,
        });
      await changeGroupStore.originateData(apiRequestFunc);
    }
    async function dropAwardedAvailability({ availabilityId }) {
      const apiRequestFunc = () =>
        api.delete(
          `availability_awardings/${availabilityId}?change_group_id=${changeGroupId.value}`,
        );
      await changeGroupStore.originateData(apiRequestFunc);
    }
    async function dropAwardedGroupAvailability({ availabilityGroupId }) {
      const apiRequestFunc = () =>
        api.delete(
          `availability_group_awardings/${availabilityGroupId}?change_group_id=${changeGroupId.value}`,
        );
      await changeGroupStore.originateData(apiRequestFunc);
    }

    // FETCH AND DISPLAY DEAL PRINCIPALS

    async function refetchAvailability(spaceObject, availability) {
      if (availability?.id) {
        const response = await api.get(
          `space_availabilities/${availability?.id}`,
        );

        updateStoreAvailability({
          spaceObject,
          existingAvailability: availability,
          newAvailability: response?.data,
        });
      }
    }

    function updateStoreAvailability({
      spaceObject,
      existingAvailability,
      newAvailability,
    }) {
      if (groupId(existingAvailability)) {
        console.log("has existing group id");
        updateAvailabilityGroupAvailability({
          groupId: groupId(existingAvailability),
          newAvailability,
        });
      } else if (existingAvailability?.id && spaceObject) {
        console.log("has availability id and space");
        updateSpaceAvailabilities({
          spaceKey: decoratingAndFieldKey(
            topLevelSpaceDataField(spaceObject, existingAvailability),
          ),
          availabilities: [newAvailability],
        });
      } else {
        console.log("nothing to update!");
      }
    }

    async function fetchLeasingCompaniesFor({
      spaceObject,
      availability,
      maybePayload,
    }) {
      let responsePayload = {
        spaceProviders: [],
        spaceUsers: [],
        awardedSpaceUsers: [],
        emitMessage: null,
      };
      if (!unknownSupplySide(availability)) {
        if (
          spaceField(availability)?.fieldContentType &&
          _.includes(
            ["SpaceUsage", "LayoutPartition"],
            spaceField(availability)?.fieldContentType,
          )
        ) {
          responsePayload.spaceProviders = await fetchSublandlord({
            spaceObject,
            availability,
            maybePayload,
          });
        } else if (
          existingContentType({
            containerObject: spaceObject,
            transaction: availability,
          }) &&
          existingContentId({
            containerObject: spaceObject,
            transaction: availability,
          })
        ) {
          responsePayload.spaceProviders = await fetchOwners({
            spaceObject,
            availability,
            maybePayload,
          });
        }
      }

      if (availability?.id) {
        responsePayload.spaceUsers = await fetchSpaceUsers({
          availability,
          maybePayload,
        });

        if (awardable(availability)) {
          responsePayload.awardedSpaceUsers =
            await fetchLeasingDemandSideAwardees({
              availability,
              maybePayload,
            });

          if (
            responsePayload.spaceUsers.length === 0 &&
            responsePayload.awardedSpaceUsers.length === 0 &&
            localSpaceUsers(availability, spaceUsageBuilder.value).length == 0
          ) {
            responsePayload.emitMessage = "editingDemand";
          }
        }
      } else {
        responsePayload.emitMessage = "editingDemand";
      }

      console.log("fetch leasing principals", responsePayload);

      return responsePayload;
    }

    // LEASING SUPPLY SIDE
    async function fetchOwners({ spaceObject, availability, maybePayload }) {
      console.log("fetch owners");
      const assetResponse = await api.get(
        `operational_controlling_assets/?content_type=${existingContentType({
          containerObject: spaceObject,
          transaction: availability,
        })}&content_id=${existingContentId({
          containerObject: spaceObject,
          transaction: availability,
        })}&data_field_id=${
          topLevelSpaceDataField(spaceObject, availability)?.localId
        }`,
      );

      if (assetResponse?.data) {
        let newProviders = [];
        for (const controllingAsset of assetResponse.data) {
          const additionalProviders = await fetchControllingAssetOwners({
            availability,
            maybePayload,
            controllingAsset,
          });

          newProviders = _.unionBy(
            additionalProviders,
            newProviders,
            "localId",
          );
        }

        console.log("new providers", newProviders);

        return newProviders;
      }
    }

    // LEASING SUPPLY SIDE
    async function fetchControllingAssetOwners({
      availability,
      maybePayload,
      controllingAsset,
    }) {
      const consumerFetchRequestKey = `availability_${
        availability.id
      }_ownership_interests_${controllingAsset.fieldContentType}_${
        controllingAsset.fieldContentId
      }${
        mustBeClosed(availability) ? "&availability_role=capital_consumer" : ""
      }`;

      console.log("fetch asset owners", consumerFetchRequestKey);

      if (alreadyFetched(consumerFetchRequestKey) && !maybePayload?.override) {
        const alreadyFetched = alreadyFetchedFieldsFor(consumerFetchRequestKey);

        return alreadyFetched.filter((cdf) => {
          return cdf.fieldContentType === "OwnershipInterest";
        });
      } else if (!dataFieldLoadingStore.isLoading(consumerFetchRequestKey)) {
        dataFieldLoadingStore.patchIds([consumerFetchRequestKey]);
        console.log(
          "SPACE USAGE BUILDER CONTROLLING ASSET OWNER fetch",
          consumerFetchRequestKey,
        );
        const json = await api.get(
          `ownership_interests/?content_type=${
            controllingAsset.fieldContentType
          }&content_id=${
            controllingAsset.fieldContentId
          }&as_of=${fetchMilliseconds(availability, asOfMilliseconds.value)}${
            mustBeClosed(availability)
              ? "&availability_role=capital_consumer"
              : ""
          }`,
        );

        if (json?.data) {
          dataFieldLoadingStore.dropId(consumerFetchRequestKey);
          interceptablePatch(json.data, consumerFetchRequestKey);
          return json.data;
        }
      }
    }

    // LEASING SUPPLY SIDE
    async function fetchSublandlord({
      spaceObject,
      availability,
      maybePayload,
    }) {
      const consumerFetchRequestKey = `availability_${
        availability.id
      }_sublandlords_${contentType(availability)}_${contentId(availability)}${
        mustBeClosed(availability) ? "&availability_role=space_user" : ""
      }`;

      if (alreadyFetched(consumerFetchRequestKey) && !maybePayload?.override) {
        const alreadyFetched = alreadyFetchedFieldsFor(consumerFetchRequestKey);

        return alreadyFetched.filter((cdf) => {
          return (
            cdf.decoratingContentType === "SpaceUsage" &&
            cdf.fieldContentType === "CompanyInvolvement"
          );
        });
      } else if (!dataFieldLoadingStore.isLoading(consumerFetchRequestKey)) {
        dataFieldLoadingStore.patchIds([consumerFetchRequestKey]);
        console.log(
          "SPACE USAGE BUILDER LEASING SUBLANDLORD fetch",
          consumerFetchRequestKey,
        );
        const json = await api.get(
          `sublandlords/?content_type=${existingContentType({
            containerObject: spaceObject,
            transaction: availability,
          })}&content_id=${existingContentId({
            containerObject: spaceObject,
            transaction: availability,
          })}&as_of=${fetchMilliseconds(availability, asOfMilliseconds.value)}${
            mustBeClosed(availability) ? "&availability_role=space_user" : ""
          }`,
        );

        if (json?.data) {
          dataFieldLoadingStore.dropId(consumerFetchRequestKey);
          interceptablePatch(json.data, consumerFetchRequestKey);

          return json.data;
        }
      }
    }

    // LEASING DEMAND SIDE (UNDER CONTRACT)
    async function fetchLeasingDemandSideAwardees({
      availability,
      maybePayload,
    }) {
      if (
        alreadyFetched(awardedUsersFetchRequestKey(availability)) &&
        !maybePayload?.override
      ) {
        const alreadyFetched = alreadyFetchedFieldsFor(
          awardedUsersFetchRequestKey(availability),
        );

        return alreadyFetched.filter((cdf) => {
          return cdf.fieldContentType === "CompanyInvolvement";
        });
      } else if (
        !dataFieldLoadingStore.isLoading(
          awardedUsersFetchRequestKey(availability),
        )
      ) {
        dataFieldLoadingStore.patchIds([
          awardedUsersFetchRequestKey(availability),
        ]);
        console.log(
          "SPACE USAGE BUILDER LEASING SUBLANDLORD fetch",
          awardedUsersFetchRequestKey(availability),
        );
        const awardeesResponse = await api.get(
          `availability_awardings/?availability_id=${availability?.id}`,
        );

        if (awardeesResponse?.data) {
          dataFieldLoadingStore.dropId(
            awardedUsersFetchRequestKey(availability),
          );
          interceptablePatch(
            awardeesResponse.data,
            awardedUsersFetchRequestKey(availability),
          );
          return awardeesResponse.data;
        }
      }
    }

    // LEASING DEMAND SIDE
    async function fetchSpaceUsers({
      spaceObject,
      availability,
      maybePayload,
    }) {
      if (
        alreadyFetched(usersFetchRequestKey(availability)) &&
        !maybePayload?.override
      ) {
        const alreadyFetched = alreadyFetchedFieldsFor(
          usersFetchRequestKey(availability),
        );

        return alreadyFetched.filter((cdf) => {
          return (
            cdf.fieldContentType === "CompanyInvolvement" &&
            cdf.decoratingContentType === "SpaceUsage"
          );
        });
      } else if (
        !dataFieldLoadingStore.isLoading(usersFetchRequestKey(availability))
      ) {
        const fetchContentType =
          spaceField(availability)?.fieldContentType === "SpaceUsage"
            ? contentType(availability)
            : existingContentType({
                containerObject: spaceObject,
                transaction: availability,
              });
        const fetchContentId =
          spaceField(availability)?.fieldContentType === "SpaceUsage"
            ? contentId(availability)
            : existingContentId({
                containerObject: spaceObject,
                transaction: availability,
              });

        dataFieldLoadingStore.patchIds([usersFetchRequestKey(availability)]);
        console.log(
          "SPACE USAGE BUILDER SPACE USERS fetch",
          usersFetchRequestKey(availability),
        );
        const spaceUsersResponse = await api.get(
          `space_users/?content_type=${fetchContentType}&content_id=${fetchContentId}&as_of=${fetchMilliseconds(
            availability,
            asOfMilliseconds.value,
          )}`,
        );

        if (spaceUsersResponse?.data) {
          dataFieldLoadingStore.dropId(usersFetchRequestKey(availability));
          interceptablePatch(
            spaceUsersResponse.data,
            usersFetchRequestKey(availability),
          );
          return spaceUsersResponse.data;
        }
      }
    }

    return {
      fetchingSpaceUsageBuilderData,
      refetchSpaceUsageBuilderEditor,
      spaceUsageBuilder,
      spaceFieldIds,
      crossInteraction,
      spaces,
      locatedSpaces,
      unlocatedSpaces,
      spacesLength,
      allFetchedFields,
      availabilityPatchableTimelineEvents,
      patchableCompanySize,
      patchableCompanyInvolvementFieldIds,
      contactFieldSize,
      fetchGroupTimingFields,
      checkGroupTimingUnlockability,
      checkGroupCashflowUnlockability,
      upsertSpaceUsageBuilder,
      prepopulateBuilder,
      populateBuilderFromQuery,
      newBuilder,
      beginBuilder,
      clearBuilder,
      setSpaceExpanded,
      setAvailabilityGroupExpanded,
      collapseAllSpacesExcept,
      collapsePortfolioSpaces,
      addSpace,
      removeSpace,
      removeSpaceAvailability,
      removeAvailabilityGroup,
      setTimelineFetchState,
      updateSpaceAvailabilities,
      updateSpaceUnlocatedSpaces,
      clearSpaceUnlocatedSpaces,
      alreadyFetched,
      alreadyFetchedFieldsFor,
      dropFetchRequest,
      interceptablePatch,
      postEditingPatch,
      matchingSpaceObject,
      addAvailabilityGroupAvailabilityPlayers,
      removeAvailabilityGroupAvailabilityPlayer,
      removeAvailabilityGroupPlayer,
      updateAvailabilityGroupAvailability,
      addAvailabilityGroup,
      addAvailabilityToGroup,
      availabilitiesLength,
      availabilityGroups,
      saveAvailabilityGroup,
      fetchAvailabilityGroupAvailabilities,
      refreshAvailabilityGroup,
      resetAvailabilities,
      setSpaceUsers,
      awardAvailability,
      awardGroupAvailability,
      dropAwardedAvailability,
      dropAwardedGroupAvailability,
      updateStoreAvailability,
      refetchAvailability,
      fetchLeasingCompaniesFor,
      setSelectedUnlocatedSpace,
    };
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useSpaceUsageBuilderStore, import.meta.hot),
  );
}
