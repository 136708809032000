import { defineStore, acceptHMRUpdate } from "pinia";
import { computed, ref } from "vue";
import api from "@/router/api";
import _ from "lodash";

export const useTopLevelContentFieldsStore = defineStore(
  "topLevelContentFields",
  () => {
    const topLevelContentFields = ref({});
    const fieldKeys = computed(() => _.keys(topLevelContentFields.value));

    const validationContexts = ref({});
    const contextKeys = computed(() => _.keys(validationContexts.value));

    async function fetchValidationContext(
      contentType,
      contentId,
      scope = "validation",
    ) {
      const combinedKey = `${contentType}${contentId}`;
      const existingField = getValidationContext(combinedKey);

      if (existingField === "await") {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        const result = await fetchValidationContext(contentType, contentId);

        return result;
      } else if (_.isObject(existingField)) {
        return existingField;
      } else if (_.includes(contextKeys.value, combinedKey)) {
        return null;
      } else {
        validationContexts.value[combinedKey] = "await";
        const response = await api.get(
          `validation_contexts/${contentType}/${contentId}?scope=${scope}`,
        );

        patchContexts(combinedKey, response.data);

        return response.data;
      }
    }

    function patchContexts(combinedKey, context) {
      validationContexts.value[combinedKey] = context;
    }

    function getValidationContext(combinedKey) {
      return validationContexts.value[combinedKey];
    }

    async function fetchTopLevelContentDataField(outerFieldId, fieldId) {
      const existingField = getTopLevelContentField(outerFieldId);

      if (existingField === "await") {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        const result = await fetchTopLevelContentDataField(
          outerFieldId,
          fieldId,
        );

        return result;
      } else if (_.isObject(existingField)) {
        return existingField;
      } else if (_.includes(fieldKeys.value, outerFieldId)) {
        return null;
      } else {
        topLevelContentFields.value[outerFieldId] = "await";
        const response = await api.get(
          `data_field_top_level_content/${fieldId}`,
        );

        patchTopLevelContentDataFields(outerFieldId, response.data);

        return response.data;
      }
    }

    function patchTopLevelContentDataFields(outerFieldId, newField) {
      topLevelContentFields.value[outerFieldId] = newField;
    }

    function getTopLevelContentField(id) {
      return topLevelContentFields.value[id];
    }

    return {
      topLevelContentFields,
      fetchTopLevelContentDataField,
      fetchValidationContext,
      getTopLevelContentField,
      patchTopLevelContentDataFields,
    };
  },
);

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useTopLevelContentFieldsStore, import.meta.hot),
  );
}
