<template>
  <span v-if="signedIn" class="relative z-0 inline-flex">
    <button
      @click="viewContributions"
      type="button"
      class="relative inline-flex flex-col justify-center px-3 rounded-l-md text-sm font-medium text-gray-700 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
      data-test="my-contributions-button"
    >
      <div
        :class="
          pulseAvailableBalance
            ? `pulse-available-balance-${pulseAvailableBalance}`
            : ''
        "
        class="flex items-center"
        data-test="available-balance-button"
      >
        <CurrencyDollarIcon
          v-tooltip="'Available Balance'"
          class="-ml-1 mr-2 h-5 w-5 text-gray-400"
        />
        {{ currencyAmount(availableBalance, 2) }}
      </div>
      <div
        :class="pulseReputation ? `pulse-reputation-${pulseReputation}` : ''"
        class="flex items-center"
        data-test="reputation-button"
      >
        <StarIcon
          v-if="reputationColor"
          v-tooltip="reputationLabel"
          :class="`${reputationColor}`"
          class="-ml-1 mr-2 h-5 w-5"
        />
        <OutlineStarIcon
          v-else
          v-tooltip="reputationLabel"
          class="-ml-1 mr-2 h-5 w-5 text-gray-400"
        />
        {{ currencyAmount(reputation, 1) }}
      </div>
    </button>
    <div
      class="relative inline-flex flex-col justify-center px-3 text-sm font-medium text-gray-700 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
    >
      <button
        type="button"
        @click="viewAvailableValidations"
        v-tooltip="'Available Validations'"
        :class="pulseAvailableValidations ? 'pulse-available-validations' : ''"
        class="flex items-center"
        data-test="available-validations-button"
      >
        <ShieldCheckIcon
          :class="
            availableValidationsCount > 0 ? 'text-teal-400' : 'text-gray-400'
          "
          class="-ml-1 mr-2 h-5 w-5"
        />
        <template v-if="availableValidationsCount >= 0">{{
          availableValidationsCount > 10 ? "10+" : availableValidationsCount
        }}</template>
      </button>
      <button
        type="button"
        @click="viewPendingValidations"
        v-tooltip="'Unpublished Contributions'"
        :class="pulsePendingValidations ? 'pulse-pending-validations' : ''"
        class="flex items-center"
        data-test="pending-validations-button"
      >
        <ShieldExclamationIcon
          :class="
            pendingValidationsCount > 0 ? 'text-pink-500' : 'text-gray-400'
          "
          class="-ml-1 mr-2 h-5 w-5"
        />
        <template v-if="pendingValidationsCount >= 0">{{
          pendingValidationsCount
        }}</template>
      </button>
    </div>
    <div
      class="-ml-px relative inline-flex flex-col justify-center px-3 overflow-visible"
    >
      <VDropdown placement="bottom-end">
        <button
          type="button"
          v-tooltip="'Sharing Stage'"
          :class="pulseSharing ? 'pulse-sharing' : ''"
          class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          data-test="sharing-stage-button"
        >
          <ShareIcon
            :class="stagedCount > 0 ? 'text-amber-500' : 'text-gray-400'"
            class="-ml-1 mr-2 h-5 w-5"
          />
          {{ stagedCount }}
        </button>

        <template #popper>
          <SharingStage />
        </template>
      </VDropdown>
      <VDropdown placement="bottom-end">
        <button
          type="button"
          v-tooltip="'Citations Stage'"
          :class="pulseCitations ? 'pulse-citations' : ''"
          class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          data-test="citations-stage-button"
        >
          <BookmarkIcon
            :class="citationsCount > 0 ? 'text-blue-500' : 'text-gray-400'"
            class="-ml-1 mr-2 h-5 w-5"
          />
          {{ citationsCount }}
        </button>

        <template #popper>
          <CitationsStage />
        </template>
      </VDropdown>
    </div>
    <ViewHelpButton />
  </span>
</template>

<script setup>
import {
  BookmarkIcon,
  CurrencyDollarIcon,
  ShareIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
  StarIcon,
} from "@heroicons/vue/20/solid";
import { StarIcon as OutlineStarIcon } from "@heroicons/vue/24/outline";
import { currencyAmount } from "@/assets/numberHelpers";
import { useUserStore } from "@/stores/user";
import { useSecondaryPanelStore } from "@/stores/secondaryPanel";
import { useSharingStore } from "@/stores/sharing";
import { useCitationStore } from "@/stores/citation";
import { useAvailableValidationsChannelStore } from "@/stores/availableValidationsChannel";
import { usePendingValidationsChannelStore } from "@/stores/pendingValidationsChannel";
import { useDatabaseUpdatesChannelStore } from "@/stores/databaseUpdatesChannel";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useUserAvailableBalancesChannelStore } from "@/stores/userAvailableBalancesChannel";
import { useUserActiveEasyDataInputPayloadItemChannelStore } from "@/stores/userActiveEasyDataInputPayloadItemChannel";
import { useUserReputationsChannelStore } from "@/stores/userReputationsChannel";
import { useDataLicensesChannelStore } from "@/stores/dataLicensesChannel";
import { useDataSharingsChannelStore } from "@/stores/dataSharingsChannel";
import { useCollectibleCardsChannelStore } from "@/stores/collectibleCardsChannel";
import { useTasksChannelStore } from "@/stores/tasksChannel";
import { useUserValidationSkipsChannelStore } from "@/stores/userValidationSkipsChannel";
import { useRoute, useRouter } from "vue-router";
import SharingStage from "@/components/crowdsourcing/safezone/SharingStage.vue";
import CitationsStage from "@/components/crowdsourcing/CitationsStage.vue";
import ViewHelpButton from "@/components/main-layout/ViewHelpButton.vue";
import { computed, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import api from "@/router/api";
import createChannel from "@/channels/channel";
import _ from "lodash";

const availableValidationsCount = ref(0);
const pendingValidationsCount = ref(0);
const pulseAvailableBalance = ref(null);
const pulseReputation = ref(null);
const pulseAvailableValidations = ref(false);
const pulsePendingValidations = ref(false);
const pulseSharing = ref(false);
const pulseCitations = ref(false);

const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { existingChangeGroupId } = storeToRefs(changeGroupStore);
const secondaryPanelStore = useSecondaryPanelStore();
const { validationsActive } = storeToRefs(secondaryPanelStore);
const userStore = useUserStore();
const {
  currentUser,
  signedIn,
  activeEasyDataInputPayloadItem,
  availableBalance,
  reputation,
  reputationLevel,
  reputable,
  correctPercentage,
  validationSkips,
} = storeToRefs(userStore);

const sharingStore = useSharingStore();
const { stagedCount } = storeToRefs(sharingStore);
const citationStore = useCitationStore();
const { citationsCount } = storeToRefs(citationStore);

const availableValidationsChannelStore = useAvailableValidationsChannelStore();
const { AvailableValidationsChannel, availableValidationsChannelDataQueue } =
  storeToRefs(availableValidationsChannelStore);
const pendingValidationsChannelStore = usePendingValidationsChannelStore();
const { PendingValidationsChannel, pendingValidationsChannelDataQueue } =
  storeToRefs(pendingValidationsChannelStore);
const databaseUpdatesChannelStore = useDatabaseUpdatesChannelStore();
const { DatabaseUpdatesChannel } = storeToRefs(databaseUpdatesChannelStore);
const userAvailableBalancesChannelStore =
  useUserAvailableBalancesChannelStore();
const { UserAvailableBalancesChannel, userAvailableBalancesChannelDataQueue } =
  storeToRefs(userAvailableBalancesChannelStore);
const userActiveEasyDataInputPayloadItemChannelStore =
  useUserActiveEasyDataInputPayloadItemChannelStore();
const { UserActiveEasyDataInputPayloadItemChannel } = storeToRefs(
  userActiveEasyDataInputPayloadItemChannelStore,
);
const userReputationsChannelStore = useUserReputationsChannelStore();
const { UserReputationsChannel, userReputationsChannelDataQueue } = storeToRefs(
  userReputationsChannelStore,
);
const dataLicensesChannelStore = useDataLicensesChannelStore();
const { DataLicensesChannel } = storeToRefs(dataLicensesChannelStore);
const dataSharingsChannelStore = useDataSharingsChannelStore();
const { DataSharingsChannel } = storeToRefs(dataSharingsChannelStore);
const collectibleCardsChannelStore = useCollectibleCardsChannelStore();
const { CollectibleCardsChannel } = storeToRefs(collectibleCardsChannelStore);
const tasksChannelStore = useTasksChannelStore();
const { TasksChannel } = storeToRefs(tasksChannelStore);
const userValidationSkipsChannelStore = useUserValidationSkipsChannelStore();
const { UserValidationSkipsChannel, userValidationSkipsChannelDataQueue } =
  storeToRefs(userValidationSkipsChannelStore);

const reputationLabel = computed(
  () => `${_.capitalize(reputationLevel.value)} reputation`,
);

const reputationColor = computed(() => {
  switch (reputationLevel.value) {
    case "bronze":
      return "text-orange-500";
    case "silver":
      return "text-gray-400";
    case "gold":
      return "text-yellow-400";
    default:
      return null;
  }
});

watch(signedIn, (val, oldVal) => {
  if (val && val !== oldVal) {
    subscribeToChannels();
  }
});

watch(userAvailableBalancesChannelDataQueue, () => {
  const data = _.last(userAvailableBalancesChannelDataQueue.value);
  availableBalance.value = _.toNumber(data.availableBalance);
});

watch(userReputationsChannelDataQueue, () => {
  const data = _.last(userReputationsChannelDataQueue.value);
  reputation.value = data.reputation;
  reputationLevel.value = data.level;
  reputable.value = data.reputable;
  correctPercentage.value = data.correctPercentage;
});

watch(userValidationSkipsChannelDataQueue, () => {
  const data = _.last(userValidationSkipsChannelDataQueue.value);
  validationSkips.value = data.validationSkips;
});

watch(availableValidationsChannelDataQueue, () => {
  longDebouncedValidationsFetch();
});
watch(pendingValidationsChannelDataQueue, () => {
  const oldVal = pendingValidationsCount.value;
  fetchPendingValidations().then(() => {
    if (
      pendingValidationsCount.value > 0 &&
      pendingValidationsCount.value !== oldVal
    ) {
      pulsePendingValidations.value = true;

      setTimeout(() => {
        pulsePendingValidations.value = false;
      }, 1600);
    }
  });
});

watch(availableValidationsCount, (val, oldVal) => {
  if (
    oldVal &&
    oldVal > 0 &&
    val !== oldVal &&
    val === 0 &&
    validationsActive.value
  ) {
    exitValidations();
  }
});

watch(stagedCount, () => {
  pulseSharing.value = true;

  setTimeout(() => {
    pulseSharing.value = false;
  }, 1600);
});

watch(citationsCount, () => {
  pulseCitations.value = true;

  setTimeout(() => {
    pulseCitations.value = false;
  }, 1600);
});

watch(availableBalance, (val, oldVal) => {
  if (oldVal) {
    if (val >= oldVal) {
      pulseAvailableBalance.value = "up";
    } else {
      pulseAvailableBalance.value = "down";
    }

    setTimeout(() => {
      pulseAvailableBalance.value = null;
    }, 1600);
  }
});

watch(reputation, (val, oldVal) => {
  if (oldVal) {
    if (val >= oldVal) {
      pulseReputation.value = "up";
    } else {
      pulseReputation.value = "down";
    }

    setTimeout(() => {
      pulseReputation.value = null;
    }, 1600);
  }
});

onMounted(async () => {
  fetchAvailableValidations();
  fetchPendingValidations();
  subscribeToChannels();
});

function subscribeToChannels() {
  if (signedIn.value) {
    if (!AvailableValidationsChannel.value) {
      AvailableValidationsChannel.value = createChannel(
        "AvailableValidationsChannel",
        {
          connected() {},
          received(data) {
            availableValidationsChannelStore.pushAndTrim(data);
          },
        },
      );
    }
    if (!PendingValidationsChannel.value) {
      PendingValidationsChannel.value = createChannel(
        {
          channel: "PendingValidationsChannel",
          userId: currentUser.value.id,
        },
        {
          connected() {},
          received(data) {
            pendingValidationsChannelStore.pushAndTrim(data);
          },
        },
      );
    }
    if (!DatabaseUpdatesChannel.value) {
      DatabaseUpdatesChannel.value = createChannel("DatabaseUpdatesChannel", {
        connected() {},
        received(data) {
          databaseUpdatesChannelStore.pushAndTrim(data);
        },
      });
    }

    if (!UserAvailableBalancesChannel.value) {
      UserAvailableBalancesChannel.value = createChannel(
        {
          channel: "UserAvailableBalancesChannel",
          userId: currentUser.value.id,
        },
        {
          connected() {},
          received(data) {
            userAvailableBalancesChannelStore.pushAndTrim(data);
          },
        },
      );
    }

    if (!UserActiveEasyDataInputPayloadItemChannel.value) {
      UserActiveEasyDataInputPayloadItemChannel.value = createChannel(
        {
          channel: "UserActiveEasyDataInputPayloadItemChannel",
          userId: currentUser.value.id,
        },
        {
          connected() {},
          received(data) {
            activeEasyDataInputPayloadItem.value = data.activeItem;
          },
        },
      );
    }

    if (!UserReputationsChannel.value) {
      UserReputationsChannel.value = createChannel(
        {
          channel: "UserReputationsChannel",
          userId: currentUser.value.id,
        },
        {
          connected() {},
          received(data) {
            userReputationsChannelStore.pushAndTrim(data);
          },
        },
      );
    }

    if (!UserValidationSkipsChannel.value) {
      UserValidationSkipsChannel.value = createChannel(
        {
          channel: "UserValidationSkipsChannel",
          userId: currentUser.value.id,
        },
        {
          connected() {},
          received(data) {
            userValidationSkipsChannelStore.pushAndTrim(data);
          },
        },
      );
    }

    if (!DataSharingsChannel.value) {
      DataSharingsChannel.value = createChannel(
        {
          channel: "DataSharingsChannel",
          userId: currentUser.value.id,
        },
        {
          connected() {},
          received(data) {
            dataSharingsChannelStore.pushAndTrim(data);
          },
        },
      );
    }

    if (!DataLicensesChannel.value) {
      DataLicensesChannel.value = createChannel(
        {
          channel: "DataLicensesChannel",
          userId: currentUser.value.id,
        },
        {
          connected() {},
          received(data) {
            dataLicensesChannelStore.pushAndTrim(data);
          },
        },
      );
    }

    if (!CollectibleCardsChannel.value) {
      CollectibleCardsChannel.value = createChannel(
        {
          channel: "CollectibleCardsChannel",
          userId: currentUser.value.id,
        },
        {
          connected() {},
          received(data) {
            collectibleCardsChannelStore.pushAndTrim(data);
          },
        },
      );
    }

    if (!TasksChannel.value) {
      TasksChannel.value = createChannel(
        {
          channel: "TasksChannel",
          userId: currentUser.value.id,
        },
        {
          connected() {},
          received(data) {
            tasksChannelStore.pushAndTrim(data);
          },
        },
      );
    }
  }
}

const longDebouncedValidationsFetch = _.debounce(function () {
  fetchAvailableValidations().then(() => {
    if (availableValidationsCount.value > 0) {
      pulseAvailableValidations.value = true;
      userStore.fetchAvailableCollectibleCardCount();

      setTimeout(() => {
        pulseAvailableValidations.value = false;
      }, 1600);
    }

    userStore.fetchAvailableCollectibleCardCount();
  });
}, 5000);

async function fetchAvailableValidations() {
  return new Promise((resolve) => {
    api.get(`available_validations_count`).then((json) => {
      availableValidationsCount.value = json.data;
      resolve();
    });
  });
}

async function fetchPendingValidations() {
  return new Promise((resolve) => {
    api.get(`unpublished_validations_count`).then((json) => {
      pendingValidationsCount.value = json.data;
      resolve();
    });
  });
}

const router = useRouter();
const route = useRoute();

function viewContributions() {
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Me",
      verticalTab: "Contributions",
    },
  });
}

function viewAvailableValidations() {
  validationsActive.value = true;
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Validations",
      verticalTab: "Available",
    },
  });
}

function viewPendingValidations() {
  validationsActive.value = true;
  router.push({
    name: route.name,
    query: {
      ...route.query,
      horizontalTab: "Validations",
      verticalTab: "Unpublished",
    },
  });
}

function exitValidations() {
  secondaryPanelStore.setSecondaryPanelWidth();
  validationsActive.value = false;
  existingChangeGroupId.value = null;
  router.push({
    name: route.name,
    query: {
      horizontalTab: "Me",
    },
  });
}
</script>

<style lang="css" scoped>
.pulse-available-balance-up,
.pulse-reputation-up {
  animation: pulse-up 1.5s;
  box-shadow: 0 0 0 2em transparent;
}
.pulse-available-balance-down,
.pulse-reputation-down {
  animation: pulse-down 1.5s;
  box-shadow: 0 0 0 2em transparent;
}
.pulse-available-validations {
  animation: pulse-available-validations 1.5s;
  box-shadow: 0 0 0 2em transparent;
}
.pulse-pending-validations {
  animation: pulse-pending-validations 1.5s;
  box-shadow: 0 0 0 2em transparent;
}

.pulse-sharing {
  animation: pulse-sharing 1.5s;
  box-shadow: 0 0 0 2em transparent;
}
.pulse-citations {
  animation: pulse-citations 1.5s;
  box-shadow: 0 0 0 2em transparent;
}

@keyframes pulse-up {
  0% {
    box-shadow: 0 0 0 0 #22c55e;
  }
}

@keyframes pulse-down {
  0% {
    box-shadow: 0 0 0 0 #ef4444;
  }
}

@keyframes pulse-available-validations {
  0% {
    box-shadow: 0 0 0 0 #2dd4bf;
  }
}

@keyframes pulse-pending-validations {
  0% {
    box-shadow: 0 0 0 0 #ec4899;
  }
}

@keyframes pulse-sharing {
  0% {
    box-shadow: 0 0 0 0 #f59e0b;
  }
}

@keyframes pulse-citations {
  0% {
    box-shadow: 0 0 0 0 #3b82f6;
  }
}
</style>
