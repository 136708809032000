<template>
  <div v-if="selectedTab" class="h-full flex">
    <!-- Narrow sidebar-->
    <nav
      aria-label="Sidebar"
      class="flex-shrink-0 p-2 bg-gray-100 overflow-y-auto"
    >
      <div class="relative flex flex-col space-y-2">
        <a
          v-for="tab in filteredTabs"
          :key="tab.name"
          @click.prevent="selectedTab = tab"
          :href="tab.href"
          v-tooltip="tab.name"
          :class="[
            selectedTab.name === tab.name
              ? 'text-gray-700 bg-gray-300'
              : 'text-gray-500 hover:bg-gray-200',
            'flex-shrink-0 inline-flex items-center justify-center h-8 w-8 rounded-lg',
          ]"
          class=""
          :data-test="`${_.kebabCase(tab.name)}-nav`"
        >
          <span class="sr-only">{{ tab.name }}</span>
          <svg
            :class="selectedTab.name === tab.name ? '' : ''"
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
              :d="tab.svgPath"
            />
          </svg>
        </a>
      </div>
    </nav>

    <div class="flex-grow">
      <component :is="selectedTab.component" v-bind="selectedTabProperties" />
    </div>
  </div>
</template>

<script setup>
import EasyDataInputJobsTable from "@/components/crowdsourcing/contributions/EasyDataInputJobsTable.vue";
import JobOpportunities from "@/components/crowdsourcing/contributions/JobOpportunities.vue";
import SelectedInputPayloadItemDetails from "@/components/crowdsourcing/contributions/SelectedInputPayloadItemDetails.vue";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import { computed, markRaw, onMounted, ref, watch } from "vue";
import _ from "lodash";

const userStore = useUserStore();
const { activeEasyDataInputPayloadItem } = storeToRefs(userStore);

const tabs = ref([
  {
    name: "Active Job Opportunity",
    href: "",
    svgPath:
      "M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5",
    component: markRaw(SelectedInputPayloadItemDetails),
  },
  {
    name: "Job Opportunities",
    href: "",
    svgPath:
      "M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z",
    component: markRaw(JobOpportunities),
  },
  {
    name: "Job Referrals",
    href: "",
    svgPath:
      "M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5",
    component: markRaw(EasyDataInputJobsTable),
  },
]);
const selectedTab = ref(null);
const filteredTabs = computed(() => {
  if (activeEasyDataInputPayloadItem.value) {
    return tabs.value;
  } else {
    return tabs.value.filter(({ name }) => name !== "Active Job Opportunity");
  }
});
const selectedTabProperties = computed(() => {
  return {};
});

watch(activeEasyDataInputPayloadItem, () => {
  autoSelectTab();
});

onMounted(async () => {
  autoSelectTab();
});

function autoSelectTab() {
  if (activeEasyDataInputPayloadItem.value) {
    selectedTab.value = {
      name: "Active Job Opportunity",
      href: "",
      svgPath:
        "M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5",
      component: markRaw(SelectedInputPayloadItemDetails),
    };
  } else {
    selectedTab.value = {
      name: "Job Referrals",
      href: "",
      svgPath:
        "M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5",
      component: markRaw(EasyDataInputJobsTable),
    };
  }
}
</script>
