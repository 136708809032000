<template>
  <tr v-if="field" :class="selected ? 'bg-gray-50' : ''">
    <td class="relative w-12 px-4">
      <div
        v-if="selected"
        class="absolute inset-y-0 left-0 w-0.5 bg-indigo-600"
      ></div>

      <input
        @click="toggleSelected"
        type="checkbox"
        :checked="selected"
        :value="selected"
        class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
      />
    </td>
    <td class="py-4 pr-3 text-gray-500 space-y-1">
      <DataField
        v-if="propertyDataField"
        :data-field="propertyDataField"
        primary-text-path="fieldContent.name"
        text-classes="text-sm font-medium"
        dropdown-placement="left-start"
        text-styles=""
      />
      <a
        @click.prevent="selectDecorating"
        href=""
        class="text-xs text-indigo-600 hover:text-indigo-900"
        >{{ _.truncate(validationContextMetaData?.summary, { length: 50 }) }}</a
      >
      <div class="text-xs text-gray-500">
        {{ fieldDecoratingType }}
      </div>
    </td>
    <td class="px-3 py-4 space-y-1">
      <DataField
        :data-field="field"
        :safezone-tab="true"
        self-label-output-format="rentRollName"
        text-classes="text-sm font-medium"
        :dropdown-placement="
          workspaceLayout === 'sideBySide' ? 'left-start' : null
        "
        @dismiss="dismissed"
      />
      <div class="text-xs text-gray-500">
        {{ fieldType }} &middot;
        {{ moment(field?.createdAt).format("MM/DD/YYYY, h:mm a") }}
      </div>
      <div class="text-xs text-gray-500">
        <span
          :class="changeType === 'addition' ? 'text-green-500' : 'text-red-500'"
          class="text-xs"
        >
          {{ _.capitalize(changeType) }}
        </span>
      </div>
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      <a
        @click.prevent="selectGroup"
        href=""
        class="text-indigo-600 hover:text-indigo-900"
        :data-test="`pending-change-row-${index}-group-select`"
        >{{ field.latestChangeGroupId }}</a
      >
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 space-y-1">
      <div
        @click="statusClickHandler"
        :class="[statusColor]"
        class="flex font-semibold justify-between items-center"
      >
        {{ statusLabel }}
      </div>
      <div
        v-if="!isCitation"
        class="flex items-center space-x-1 flex-shrink-0"
        :data-test="`pending-change-row-${index}-citation-label`"
      >
        <CitationsMenu :data-field="field" @refetch="fetchCitations">
          <template v-slot:button>
            <a href="" @click.prevent class="hover:underline">
              {{ pluralize("Citation", citations.length, true) }}
            </a>
          </template>
        </CitationsMenu>
        <CitationForm
          class="flex items-center"
          :change-ids="[changeId]"
          @refetch="fetchCitations"
        >
          <template v-slot:button>
            <button
              type="button"
              class="mr-1 flex-shrink-0 bg-white inline-flex h-6 w-6 items-center justify-center rounded-full border-2 border-dashed border-gray-200 text-gray-400 hover:text-gray-500 hover:border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              :data-test="`pending-change-row-${index}-citation-button`"
            >
              <span class="sr-only">Add citation</span>
              <!-- Heroicon name: solid/plus -->
              <svg
                class="h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </template>
        </CitationForm>
      </div>
    </td>
  </tr>
</template>

<script setup>
import CitationForm from "@/components/crowdsourcing/CitationForm.vue";
import CitationsMenu from "@/components/crowdsourcing/CitationsMenu.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import api from "@/router/api";
import { computed, onMounted, ref } from "vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { useTopLevelContentFieldsStore } from "@/stores/topLevelContentFields";
import { storeToRefs } from "pinia";
import pluralize from "pluralize";
import _ from "lodash";
import moment from "moment";

const props = defineProps([
  "changeId",
  "changeType",
  "dataFieldId",
  "outerFieldId",
  "selected",
  "index",
]);
const emit = defineEmits([
  "attach-field",
  "dismissed",
  "unselect",
  "select",
  "select-decorating",
  "select-group",
  "select-publishable",
]);

const field = ref(null);
const propertyId = ref(null);
const validationContextMetaData = ref(null);
const citations = ref([]);

const topLevelContentFieldsStore = useTopLevelContentFieldsStore();
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const propertyFieldsStore = usePropertyFieldsStore();

const propertyDataField = computed(() => {
  if (propertyId.value) {
    return propertyFieldsStore.getPropertyField(propertyId.value);
  } else {
    return null;
  }
});

const fieldType = computed(() => {
  if (field.value?.fieldContentType) {
    return _.startCase(field.value.fieldContentType);
  } else if (field.value?.fieldName) {
    return _.startCase(field.value.fieldName);
  } else {
    return "Unknown field type";
  }
});
const fieldDecoratingType = computed(() => {
  if (field.value?.decoratingContentType) {
    return _.startCase(field.value.decoratingContentType);
  } else {
    return "";
  }
});
const isCitation = computed(() =>
  _.includes(
    ["DataFieldCitation", "DataFieldChangeGroup"],
    _.get(field.value, "decoratingContentType"),
  ),
);

const statusLabel = computed(() => {
  switch (field.value.state) {
    case "unstaked":
      return "Unstaked";
    case "staked":
      return "Staked, publishable";
    case "proposed":
      return "In validation";
    default:
      return null;
  }
});

const statusColor = computed(() => {
  switch (field.value.state) {
    case "unstaked":
      return "text-pink-500";
    case "staked":
      return "text-amber-500 hover:text-amber-700 underline cursor-pointer";
    case "proposed":
      return "text-emerald-500";
    default:
      return "text-gray-500";
  }
});

onMounted(() => {
  fetchDataField().then(() => fetchCitations());
  fetchTopLevelContent();
});

function statusClickHandler() {
  if (field.value.state === "staked") {
    emit("select-publishable");
  }
}

async function fetchTopLevelContent() {
  const topLevelField =
    await topLevelContentFieldsStore.fetchTopLevelContentDataField(
      props.outerFieldId,
      props.dataFieldId,
    );
  if (topLevelField) {
    if (topLevelField.fieldContentType === "Property") {
      propertyFieldsStore.patchPropertyDataFields(topLevelField);
      propertyId.value = topLevelField.fieldContentId;
    } else if (
      topLevelField.fieldContent?.propertyId ||
      topLevelField.fieldContent?.topLevelPropertyId
    ) {
      propertyId.value =
        topLevelField.fieldContent?.propertyId ||
        topLevelField.fieldContent?.topLevelPropertyId;
      propertyFieldsStore.fetchPropertyDataField(propertyId.value);
    }
  }
}

async function fetchDataField() {
  const fieldResponse = await api.get(`data_field_by_id/${props.dataFieldId}`);

  field.value = fieldResponse.data;
  emit("attach-field", fieldResponse.data);

  if (field.value.decoratingContentType && field.value.decoratingContentId) {
    validationContextMetaData.value =
      await topLevelContentFieldsStore.fetchValidationContext(
        field.value.decoratingContentType,
        field.value.decoratingContentId,
        "visible",
      );
  } else {
    return;
  }
}

async function fetchCitations() {
  citations.value = [];
  api
    .get(`data_field_change_group_citations/${props.changeId}?scope=validation`)
    .then((json) => {
      citations.value = json.data;
    });
}

function dismissed() {
  emit("dismissed");
}
function toggleSelected() {
  if (props.selected) {
    emit("unselect");
  } else {
    emit("select");
  }
}
function selectDecorating() {
  emit("select-decorating", {
    contentType: field.value.decoratingContentType,
    contentId: field.value.decoratingContentId,
  });
}
function selectGroup() {
  emit("select-group", {
    groupId: field.value.latestChangeGroupId,
  });
}
</script>
