import api from "@/router/api";
import propertyLandCoveringMapMarker from "@/components/property-diagram/propertyLandCoveringMapMarker";
import locationMarker from "@/components/maps/locationMarker";
import parcelPopup from "@/components/property-diagram/parcelPopup";
import { storeToRefs } from "pinia";
import { defaultBoundaryMeta } from "@/components/maps/defaultBoundaryMeta";
import _ from "lodash";
/* global L */

export async function fetchNearbyValidationParcels({
  map,
  modalStore,
  unlockerStore,
  userStore,
  tasksStore,
  taskListStore,
  reminderStore,
  guestProfileStore,
  propertyDiagramStore,
  changeGroupStore,
  secondaryPanelStore,
  documentationStore,
  analyzePanelStore,
  layoutStore,
  parcelFeatureGroup,
  asOfMilliseconds,
  activeChangeGroupId,
}) {
  const zoom = map.getZoom();
  const bounds = map.getBounds();
  const southwest = bounds.getSouthWest();
  const northeast = bounds.getNorthEast();
  const northeastLatLng = [northeast.lat, northeast.lng];
  const southwestLatLng = [southwest.lat, southwest.lng];

  await Promise.all(
    _.compact([
      fetchParcels({
        mode: "validation",
        map,
        mapStore: null,
        modalStore,
        unlockerStore,
        userStore,
        tasksStore,
        taskListStore,
        reminderStore,
        guestProfileStore,
        propertyDiagramStore,
        changeGroupStore,
        layoutStore,
        secondaryPanelStore,
        documentationStore,
        analyzePanelStore,
        featureGroup: parcelFeatureGroup,
        southwestLatLng,
        northeastLatLng,
        asOfMilliseconds,
        activeChangeGroupId,
        horizontalIsAnalyze: false,
        zoom,
      }),
    ]),
  );
}

export async function fetchNearbyValidationProperties({
  nearbyType,
  map,
  propertyFieldsStore,
  modalStore,
  unlockerStore,
  userStore,
  tasksStore,
  taskListStore,
  reminderStore,
  guestProfileStore,
  propertyDiagramStore,
  changeGroupStore,
  documentationStore,
  layoutStore,
  propertyFeatureGroup,
  landCoveringFeatureGroup,
  asOfMilliseconds,
  activeChangeGroupId,
}) {
  const zoom = map.getZoom();
  const bounds = map.getBounds();
  const southwest = bounds.getSouthWest();
  const northeast = bounds.getNorthEast();
  const northeastLatLng = [northeast.lat, northeast.lng];
  const southwestLatLng = [southwest.lat, southwest.lng];

  await Promise.all(
    _.compact([
      nearbyType === "Property"
        ? fetchProperties({
            mode: "validation",
            mapStore: null,
            propertyFieldsStore,
            modalStore,
            unlockerStore,
            userStore,
            tasksStore,
            taskListStore,
            reminderStore,
            guestProfileStore,
            propertyDiagramStore,
            changeGroupStore,
            documentationStore,
            layoutStore,
            featureGroup: propertyFeatureGroup,
            southwestLatLng,
            northeastLatLng,
            asOfMilliseconds,
            activeChangeGroupId,
            zoom,
            propertyZoomTrigger: 16,
            horizontalIsAnalyze: false,
            companyIdParam: null,
            companyDetailStore: null,
            contactIdParam: null,
            contactDetailStore: null,
          })
        : null,
      nearbyType === "LandCovering"
        ? fetchLandCoverings({
            mode: "validation",
            mapStore: null,
            propertyFieldsStore,
            modalStore,
            unlockerStore,
            userStore,
            tasksStore,
            taskListStore,
            reminderStore,
            guestProfileStore,
            propertyDiagramStore,
            changeGroupStore,
            documentationStore,
            featureGroup: landCoveringFeatureGroup,
            southwestLatLng,
            northeastLatLng,
            asOfMilliseconds,
            activeChangeGroupId,
            horizontalIsAnalyze: false,
            zoom,
          })
        : null,
    ]),
  );
}

export async function exploreMap({
  mapStore,
  userStore,
  guestProfileStore,
  polygonDrawer,
  fullSize,
  navigationType,
}) {
  const { map, minimapSize, newOriginOverride } = storeToRefs(mapStore);
  const { signedIn } = storeToRefs(userStore);
  const bounds = map.value.getBounds();
  const southwest = bounds.getSouthWest();
  const northeast = bounds.getNorthEast();
  const northeastLatLng = [northeast.lat, northeast.lng];
  const southwestLatLng = [southwest.lat, southwest.lng];

  const zoom = map.value.getZoom();
  const eligibleMode = fullSize || minimapSize.value === "compact";

  if (zoom >= 16.5 && eligibleMode) {
    const { lat, lng } = map.value.getCenter();
    const payload = {
      lat,
      lng,
      navigationType: newOriginOverride.value ? "origin" : navigationType.value,
      createdAt: new Date(),
    };

    if (signedIn.value) {
      const { sessionGeographyIntents } = storeToRefs(userStore);

      if (sessionGeographyIntents.value === 0) {
        payload.navigationType = "origin";
      }

      await api.post(`user_geography_intents`, payload).then(() => {
        navigationType.value = null;
        newOriginOverride.value = false;
        sessionGeographyIntents.value = sessionGeographyIntents.value + 1;
      });
    } else {
      const { geographyIntents } = storeToRefs(guestProfileStore);

      if (geographyIntents.value.length === 0) {
        payload.navigationType = "origin";
      }

      await geographyIntents.value.push(payload);
      navigationType.value = null;
      newOriginOverride.value = false;
    }
  } else if (zoom < 16.5 || newOriginOverride.value) {
    navigationType.value = "origin";
  }

  await fetchFog({
    zoom,
    polygonDrawer,
    southwestLatLng,
    northeastLatLng,
    signedIn: signedIn.value,
    guestProfileStore,
    userStore,
  });
}

export async function fetchBoundingBoxProperties({
  mapStore,
  propertyFieldsStore,
  modalStore,
  unlockerStore,
  userStore,
  tasksStore,
  taskListStore,
  reminderStore,
  guestProfileStore,
  userGeographiesStore,
  propertyDiagramStore,
  companyDetailStore,
  contactDetailStore,
  changeGroupStore,
  documentationStore,
  analyzePanelStore,
  layoutStore,
  secondaryPanelStore,
  userFeatureGroup,
  propertyFeatureGroup,
  landCoveringFeatureGroup,
  parcelFeatureGroup,
  asOfMilliseconds,
  activeChangeGroupId,
  routeName,
}) {
  const {
    map,
    propertyZoomTrigger,
    horizontalIsAnalyze,
    minimapSize,
    fetchingMapData,
  } = storeToRefs(mapStore);
  const { signedIn } = storeToRefs(userStore);
  const { companyIdParam } = storeToRefs(companyDetailStore);
  const { contactIdParam } = storeToRefs(contactDetailStore);
  const bounds = map.value.getBounds();
  const southwest = bounds.getSouthWest();
  const northeast = bounds.getNorthEast();
  const northeastLatLng = [northeast.lat, northeast.lng];
  const southwestLatLng = [southwest.lat, southwest.lng];

  const zoom = map.value.getZoom();

  fetchingMapData.value = true;

  await Promise.all([
    fetchUsers({
      zoom,
      mapStore,
      featureGroup: userFeatureGroup,
      southwestLatLng,
      northeastLatLng,
      userGeographiesStore,
      documentationStore,
    }),
    fetchProperties({
      mode: "store",
      mapStore,
      propertyFieldsStore,
      modalStore,
      unlockerStore,
      userStore,
      tasksStore,
      taskListStore,
      reminderStore,
      guestProfileStore,
      propertyDiagramStore,
      changeGroupStore,
      documentationStore,
      analyzePanelStore,
      layoutStore,
      secondaryPanelStore,
      featureGroup: propertyFeatureGroup,
      southwestLatLng,
      northeastLatLng,
      asOfMilliseconds,
      activeChangeGroupId,
      zoom,
      minimapSize: minimapSize.value,
      propertyZoomTrigger: propertyZoomTrigger.value,
      horizontalIsAnalyze: horizontalIsAnalyze.value,
      companyIdParam: companyIdParam.value,
      companyDetailStore,
      contactIdParam: contactIdParam.value,
      contactDetailStore,
    }),
    fetchTaskPriorities({
      signedIn: signedIn.value,
      tasksStore,
      southwestLatLng,
      northeastLatLng,
      zoom,
      propertyZoomTrigger: propertyZoomTrigger.value,
    }),
    fetchLandCoverings({
      mode: "store",
      mapStore,
      propertyFieldsStore,
      modalStore,
      unlockerStore,
      userStore,
      tasksStore,
      taskListStore,
      reminderStore,
      guestProfileStore,
      propertyDiagramStore,
      changeGroupStore,
      secondaryPanelStore,
      documentationStore,
      analyzePanelStore,
      featureGroup: landCoveringFeatureGroup,
      southwestLatLng,
      northeastLatLng,
      asOfMilliseconds,
      activeChangeGroupId,
      horizontalIsAnalyze: horizontalIsAnalyze.value,
      zoom,
    }),
    fetchParcels({
      mode: "store",
      map: map.value,
      mapStore,
      modalStore,
      unlockerStore,
      userStore,
      tasksStore,
      taskListStore,
      reminderStore,
      guestProfileStore,
      propertyDiagramStore,
      changeGroupStore,
      documentationStore,
      analyzePanelStore,
      layoutStore,
      secondaryPanelStore,
      featureGroup: parcelFeatureGroup,
      southwestLatLng,
      northeastLatLng,
      asOfMilliseconds,
      activeChangeGroupId,
      horizontalIsAnalyze: horizontalIsAnalyze.value,
      zoom,
    }),
    fetchDetailPageHuntBoundaries({
      mapStore,
      companyDetailStore,
      contactDetailStore,
      featureGroup: parcelFeatureGroup,
      asOfMilliseconds,
    }),
    fetchNearbyHunts({
      mapStore,
      southwestLatLng,
      northeastLatLng,
      asOfMilliseconds,
      zoom,
      routeName,
    }),
  ]);

  fetchingMapData.value = false;
}

function fetchFog({
  zoom,
  polygonDrawer,
  southwestLatLng,
  northeastLatLng,
  signedIn,
  userStore,
  guestProfileStore,
}) {
  return new Promise((resolve) => {
    if (signedIn && zoom >= defaultBoundaryMeta.zoom) {
      const { userGeographyIntents } = storeToRefs(userStore);
      api
        .get(`my_map_fog/${southwestLatLng}/${northeastLatLng}`)
        .then((response) => {
          const intents = response.data;
          userGeographyIntents.value = intents;
          if (intents.length > 0) polygonDrawer.consolidateIntents(intents);
          resolve();
        });
    } else {
      const { geographyIntents } = storeToRefs(guestProfileStore);
      const geocodedIntents = geographyIntents.value.filter((intent) => {
        return !!intent.lat && !!intent.lng;
      });

      if (geocodedIntents.length > 0)
        polygonDrawer.consolidateIntents(_.reverse(geocodedIntents));

      resolve();
    }
  });
}

function fetchUsers({
  zoom,
  mapStore,
  featureGroup,
  southwestLatLng,
  northeastLatLng,
  userGeographiesStore,
  documentationStore,
}) {
  return new Promise((resolve) => {
    if (zoom >= defaultBoundaryMeta.zoom) {
      api
        .get(`user_geography_intents/${southwestLatLng}/${northeastLatLng}`)
        .then((response) => {
          const intents = response.data;
          userGeographiesStore.updateUsers(
            intents,
            mapStore,
            featureGroup,
            documentationStore,
          );
          resolve();
        });
    } else {
      resolve();
    }
  });
}

function fetchProperties({
  mode,
  mapStore,
  propertyFieldsStore,
  modalStore,
  unlockerStore,
  userStore,
  tasksStore,
  taskListStore,
  reminderStore,
  guestProfileStore,
  propertyDiagramStore,
  changeGroupStore,
  documentationStore,
  analyzePanelStore,
  layoutStore,
  secondaryPanelStore,
  featureGroup,
  southwestLatLng,
  northeastLatLng,
  asOfMilliseconds,
  activeChangeGroupId,
  zoom,
  minimapSize,
  propertyZoomTrigger,
  horizontalIsAnalyze,
  companyIdParam,
  companyDetailStore,
  contactIdParam,
  contactDetailStore,
}) {
  return new Promise((resolve) => {
    if (companyIdParam || contactIdParam) {
      const { companyFetchedLocationDataFields } =
        storeToRefs(companyDetailStore);
      const { contactFetchedLocationDataFields } =
        storeToRefs(contactDetailStore);
      const needsFetch = companyIdParam
        ? _.isEmpty(companyFetchedLocationDataFields.value)
        : _.isEmpty(contactFetchedLocationDataFields.value);
      const endpoint = companyIdParam
        ? `company_properties/${companyIdParam}`
        : `contact_properties/${contactIdParam}`;
      const contextStore = companyIdParam
        ? companyDetailStore
        : contactDetailStore;
      const contextLocations = companyIdParam
        ? companyFetchedLocationDataFields
        : contactFetchedLocationDataFields;
      const displayLocation = (dataField) => {
        const location = dataField.fieldContent;
        const workspaceMarker = propertyLandCoveringMapMarker({
          dataField,
          location,
          mapStore,
          propertyFieldsStore,
          modalStore,
          unlockerStore,
          userStore,
          tasksStore,
          taskListStore,
          reminderStore,
          guestProfileStore,
          propertyDiagramStore,
          changeGroupStore,
          layoutStore,
          secondaryPanelStore,
          documentationStore,
          analyzePanelStore,
          playerMinimap: minimapSize === "micro",
        });

        if (workspaceMarker) {
          featureGroup.addLayer(workspaceMarker);
          location.mapInternalId = featureGroup.getLayerId(workspaceMarker);
        }
        mapStore.setNearbyDataField({
          dataField,
          locationType: "Property",
        });
      };

      if (needsFetch) {
        api.get(`${endpoint}?as_of=${asOfMilliseconds}`).then((json) => {
          const locationFields = json.data;
          contextStore.patchLocationDataFields(locationFields);
          contextLocations.value.forEach(displayLocation);

          resolve();
        });
      } else {
        contextLocations.value.forEach(displayLocation);

        resolve();
      }
    } else if (zoom >= propertyZoomTrigger) {
      const analyzeParam =
        mode === "validation" || horizontalIsAnalyze ? "all" : "explored";
      api
        .post(
          `content_locations/${southwestLatLng}/${northeastLatLng}?zoom=${zoom}&content_type=Property&as_of=${asOfMilliseconds}&active_change_group_id=${activeChangeGroupId}&analyze=${analyzeParam}`,
        )
        .then((response) => {
          const contentLocationDataFields = response.data;

          contentLocationDataFields.forEach((dataField) => {
            const location = dataField.fieldContent;
            let workspaceMarker = null;

            if (mode === "store") {
              workspaceMarker = propertyLandCoveringMapMarker({
                dataField,
                location,
                mapStore,
                propertyFieldsStore,
                modalStore,
                unlockerStore,
                userStore,
                tasksStore,
                taskListStore,
                reminderStore,
                guestProfileStore,
                propertyDiagramStore,
                changeGroupStore,
                layoutStore,
                secondaryPanelStore,
                documentationStore,
                analyzePanelStore,
              });
            } else {
              const markerIcon = locationMarker({
                classes:
                  "h-3 w-3 bg-gray-600 hover:bg-gray-700 focus:ring-gray-500",
                interactive: true,
                mapStore,
                propertyFieldsStore,
                propertyId: dataField.decoratingContentId,
              });
              workspaceMarker = L.marker([location.lat, location.lng], {
                draggable: false,
                icon: markerIcon,
              });
            }

            if (workspaceMarker) {
              featureGroup.addLayer(workspaceMarker);
              location.mapInternalId = featureGroup.getLayerId(workspaceMarker);
              if (mode === "store") {
                mapStore.setNearbyDataField({
                  dataField,
                  locationType: "Property",
                });
              }
            }
          });

          resolve();
        });
    } else {
      resolve();
    }
  });
}

function fetchTaskPriorities({
  signedIn,
  tasksStore,
  southwestLatLng,
  northeastLatLng,
  zoom,
  propertyZoomTrigger,
}) {
  return new Promise((resolve) => {
    if (signedIn && zoom >= propertyZoomTrigger) {
      api
        .post(`property_task_priorities/${southwestLatLng}/${northeastLatLng}`)
        .then((response) => {
          const taskPriorities = response.data;
          const { propertyTaskPriorities } = storeToRefs(tasksStore);

          propertyTaskPriorities.value = taskPriorities;

          resolve();
        });
    } else {
      resolve();
    }
  });
}

function fetchLandCoverings({
  mode,
  mapStore,
  propertyFieldsStore,
  modalStore,
  unlockerStore,
  userStore,
  tasksStore,
  taskListStore,
  reminderStore,
  guestProfileStore,
  propertyDiagramStore,
  changeGroupStore,
  secondaryPanelStore,
  documentationStore,
  analyzePanelStore,
  featureGroup,
  southwestLatLng,
  northeastLatLng,
  asOfMilliseconds,
  activeChangeGroupId,
  horizontalIsAnalyze,
  zoom,
}) {
  return new Promise((resolve) => {
    if (zoom >= 16) {
      const analyzeParam =
        mode === "validation" || horizontalIsAnalyze ? "all" : "explored";
      api
        .post(
          `content_locations/${southwestLatLng}/${northeastLatLng}?zoom=${zoom}&content_type=LandCovering&as_of=${asOfMilliseconds}&active_change_group_id=${activeChangeGroupId}&analyze=${analyzeParam}`,
        )
        .then((response) => {
          const contentLocationDataFields = response.data;

          contentLocationDataFields.forEach((dataField) => {
            const location = dataField.fieldContent;
            let workspaceMarker = null;
            if (mode === "store") {
              workspaceMarker = propertyLandCoveringMapMarker({
                dataField,
                location,
                mapStore,
                propertyFieldsStore,
                modalStore,
                unlockerStore,
                userStore,
                tasksStore,
                taskListStore,
                reminderStore,
                guestProfileStore,
                propertyDiagramStore,
                changeGroupStore,
                secondaryPanelStore,
                documentationStore,
                analyzePanelStore,
              });
            } else {
              const markerIcon = locationMarker({
                classes:
                  "h-3 w-3 bg-gray-600 hover:bg-gray-700 focus:ring-gray-500",
                interactive: true,
                mapStore,
                propertyFieldsStore,
                landCoveringId: dataField.decoratingContentId,
              });
              workspaceMarker = L.marker([location.lat, location.lng], {
                draggable: false,
                icon: markerIcon,
              });
            }

            if (workspaceMarker) {
              featureGroup.addLayer(workspaceMarker);
              location.mapInternalId = featureGroup.getLayerId(workspaceMarker);
              if (mode === "store") {
                mapStore.setNearbyDataField({
                  dataField,
                  locationType: "ContentLocation",
                });
              }
            }
          });

          resolve();
        });
    } else {
      resolve();
    }
  });
}

function fetchParcels({
  mode,
  map,
  mapStore,
  modalStore,
  unlockerStore,
  userStore,
  tasksStore,
  taskListStore,
  reminderStore,
  guestProfileStore,
  propertyDiagramStore,
  changeGroupStore,
  layoutStore,
  secondaryPanelStore,
  documentationStore,
  analyzePanelStore,
  featureGroup,
  southwestLatLng,
  northeastLatLng,
  asOfMilliseconds,
  activeChangeGroupId,
  horizontalIsAnalyze,
  zoom,
}) {
  const { propertyDiagramSelected, propertyIdParam } =
    storeToRefs(propertyDiagramStore);
  const {
    investmentsChecked,
    loansChecked,
    spaceAvailabilitiesChecked,
    spaceUsagesChecked,
    checkedCategories,
    combinedFilteredPropertyIds,
    datedInvestmentPropertyIds,
    datedAvailabilityPropertyIds,
  } = storeToRefs(analyzePanelStore);
  return new Promise((resolve) => {
    if (zoom >= 16) {
      const analyzeParam =
        mode === "validation" || horizontalIsAnalyze ? "all" : "explored";
      api
        .post(
          `content_polygons/${southwestLatLng}/${northeastLatLng}?zoom=${zoom}&as_of=${asOfMilliseconds}&active_change_group_id=${activeChangeGroupId}&analyze=${analyzeParam}`,
        )
        .then((response) => {
          const contentPolygonDataFields = response.data;
          const propertyRightType = _.get(
            propertyDiagramSelected.value,
            "dataField.fieldContentSubType",
            null,
          );
          const idComparisonPath =
            propertyRightType && propertyRightType === "fee_simple"
              ? "dataField.fieldContentId"
              : "dataField.decoratingContentId";
          const fieldContentType = _.get(
            propertyDiagramSelected.value,
            "dataField.fieldContentType",
            null,
          );
          const decoratingContentType = _.get(
            propertyDiagramSelected.value,
            "dataField.decoratingContentType",
            null,
          );
          const typeMatch =
            fieldContentType === "PropertyRight" ||
            decoratingContentType === "PropertyRight";

          let canDisplay = false;

          if (mode === "store") {
            const { minimapSize } = storeToRefs(mapStore);
            canDisplay =
              !propertyIdParam.value || minimapSize?.value === "compact";
          }

          for (const dataField of contentPolygonDataFields) {
            const parcel = dataField.fieldContent;
            const idMatch =
              dataField.decoratingContentId ===
              _.get(propertyDiagramSelected.value, idComparisonPath, null);
            const excludedProperty =
              checkedCategories.value.length > 0 &&
              !_.includes(
                combinedFilteredPropertyIds.value,
                dataField.joiningContentId,
              );

            const excludedInvestment =
              (investmentsChecked.value || loansChecked.value) &&
              !_.includes(
                datedInvestmentPropertyIds.value,
                dataField.joiningContentId,
              );
            const excludedAvailability =
              (spaceAvailabilitiesChecked.value || spaceUsagesChecked.value) &&
              !_.includes(
                datedAvailabilityPropertyIds.value,
                dataField.joiningContentId,
              );
            const visuallyExcluded =
              horizontalIsAnalyze &&
              (excludedProperty ||
                (excludedInvestment && excludedAvailability));

            if (typeMatch && idMatch && mode === "validation") continue;

            const color = typeMatch && idMatch ? "#FDBA74" : "#99F6E4"; // orange-300 : teal-200
            const polygon = L.polygon(parcel.coordinates, {
              interactive: mode === "store" && canDisplay,
              color: visuallyExcluded ? "#9ca3af" : color, // excluded: gray-400
              stroke: true,
              weight: map.getZoom() > 15 ? 3 : 1.5,
              fill: true,
            });

            featureGroup.addLayer(polygon);
            parcel.mapInternalId = featureGroup.getLayerId(polygon);
            if (mode === "store") {
              mapStore.setNearbyDataField({
                dataField,
                locationType: "ContentPolygon",
              });

              polygon.on("click", () => {
                const responsivePopup = parcelPopup({
                  mapStore,
                  propertyDiagramStore,
                  changeGroupStore,
                  modalStore,
                  unlockerStore,
                  userStore,
                  tasksStore,
                  taskListStore,
                  reminderStore,
                  guestProfileStore,
                  layoutStore,
                  secondaryPanelStore,
                  documentationStore,
                  parcelDataField: dataField,
                });

                if (idMatch && typeMatch) {
                  console.log("no_op");
                } else {
                  polygon.setStyle({
                    color: visuallyExcluded ? "#6b7280" : "#2DD4BF", // gray-600 : teal-400
                  });
                }

                polygon
                  .unbindPopup()
                  .bindPopup(responsivePopup, { minWidth: 230 })
                  .openPopup();
              });

              polygon.on("popupclose", () => {
                if (idMatch && typeMatch) {
                  return;
                } else {
                  polygon.setStyle({
                    color: visuallyExcluded ? "#9ca3af" : "#99F6E4", // gray-400 : teal-200
                  });
                }
              });
            }
          }

          resolve();
        });
    } else {
      resolve();
    }
  });
}

async function fetchDetailPageHuntBoundaries({
  mapStore,
  companyDetailStore,
  contactDetailStore,
  featureGroup,
  asOfMilliseconds,
}) {
  const { companyIdParam, companyFetchedPolygonDataFields } =
    storeToRefs(companyDetailStore);
  const { contactIdParam, contactFetchedPolygonDataFields } =
    storeToRefs(contactDetailStore);

  if (!companyIdParam.value && !contactIdParam.value) return;

  const needsFetch = companyIdParam.value
    ? _.isEmpty(companyFetchedPolygonDataFields.value)
    : _.isEmpty(contactFetchedPolygonDataFields.value);
  const endpoint = companyIdParam.value
    ? `company_hunt_polygons/${companyIdParam.value}`
    : `contact_hunt_polygons/${contactIdParam.value}`;
  const contextStore = companyIdParam.value
    ? companyDetailStore
    : contactDetailStore;
  const contextPolygons = companyIdParam.value
    ? companyFetchedPolygonDataFields
    : contactFetchedPolygonDataFields;
  const displayPolygon = (dataField) => {
    const huntPolygon = dataField.fieldContent;

    if (huntPolygon.coordinates) {
      const polygon = L.polygon(huntPolygon.coordinates, {
        interactive: false,
        color: "#fb7185", // rose-400
        stroke: true,
        weight: 1.5,
        fill: true,
      });

      featureGroup.addLayer(polygon);
      huntPolygon.mapInternalId = featureGroup.getLayerId(polygon);
    }
  };

  if (needsFetch) {
    const response = await api.get(`${endpoint}?as_of=${asOfMilliseconds}`);

    if (response?.data) {
      const contentPolygonDataFields = response.data;
      contextStore.patchPolygonDataFields(contentPolygonDataFields);

      for (const dataField of contextPolygons.value) {
        displayPolygon(dataField);
      }
    }
  } else {
    for (const dataField of contextPolygons.value) {
      displayPolygon(dataField);
    }
  }
}

async function fetchNearbyHunts({
  mapStore,
  southwestLatLng,
  northeastLatLng,
  zoom,
  asOfMilliseconds,
  routeName,
}) {
  if (routeName !== "MainMap") return;

  const { nearbyHuntDataFields } = storeToRefs(mapStore);

  const response = await api.post(
    `content_polygons/${southwestLatLng}/${northeastLatLng}?zoom=${zoom}&as_of=${asOfMilliseconds}&content_type=Hunt`,
  );

  if (response?.data) {
    const contentPolygonDataFields = response.data;
    nearbyHuntDataFields.value = contentPolygonDataFields;
  }
}
