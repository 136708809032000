import { ref, computed } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";
import moment from "moment";
import _ from "lodash";

export const useTimeTravelStore = defineStore("timeTravel", () => {
  const timeTravelTo = ref(null);
  const chosenDate = ref(null);
  const asOfDate = computed(() =>
    moment(timeTravelTo.value || undefined).toDate(),
  );
  const asOfMilliseconds = computed(() => moment(asOfDate.value).valueOf());
  const dayBeforeAsOf = computed(() =>
    moment(asOfDate.value).subtract(1, "day").valueOf(),
  );
  const hoveringMarkerField = ref(null);
  const refreshingMainTimeline = ref(false);
  const mainTimelineDated = ref([]);
  const mainTimelineUndated = ref([]);
  const mainTimelineCustomForwardDate = ref(null);
  const mainTimelineCustomLookbackDate = ref(null);

  const datedFields = computed(() =>
    _.compact(
      mainTimelineDated.value.map((timelineObj) => timelineObj?.dataField),
    ),
  );
  const undatedFields = computed(() =>
    _.compact(
      mainTimelineUndated.value.map((timelineObj) => timelineObj?.dataField),
    ),
  );
  const datedInvestmentFields = computed(() =>
    datedFields.value.filter(
      (dataField) => dataField.fieldContentType === "Investment",
    ),
  );
  const datedInvestmentIds = computed(() => {
    return datedInvestmentFields.value.map(
      (dataField) => dataField.fieldContentId,
    );
  });
  const undatedInvestmentFields = computed(() =>
    undatedFields.value.filter(
      (dataField) => dataField.fieldContentType === "Investment",
    ),
  );
  const undatedInvestmentIds = computed(() => {
    return undatedInvestmentFields.value.map(
      (dataField) => dataField.fieldContentId,
    );
  });
  const combinedTimelineInvestmentFields = computed(() =>
    _.unionBy(
      datedInvestmentFields.value,
      undatedInvestmentFields.value,
      function (dataField) {
        return `${dataField.fieldContentType}${dataField.fieldContentId}`;
      },
    ),
  );
  const combinedInvestmentIds = computed(() => {
    return combinedTimelineInvestmentFields.value.map(
      (dataField) => dataField.fieldContentId,
    );
  });
  const datedSpaceAvailabilityFields = computed(() =>
    datedFields.value.filter(
      (dataField) => dataField.fieldContentType === "SpaceAvailability",
    ),
  );
  const datedSpaceAvailabilityIds = computed(() => {
    return datedSpaceAvailabilityFields.value.map(
      (dataField) => dataField.fieldContentId,
    );
  });
  const undatedSpaceAvailabilityFields = computed(() =>
    undatedFields.value.filter(
      (dataField) => dataField.fieldContentType === "SpaceAvailability",
    ),
  );
  const undatedSpaceAvailabilityIds = computed(() => {
    return undatedSpaceAvailabilityFields.value.map(
      (dataField) => dataField.fieldContentId,
    );
  });
  const combinedTimelineSpaceAvailabilityFields = computed(() =>
    _.unionBy(
      datedSpaceAvailabilityFields.value,
      undatedSpaceAvailabilityFields.value,
      function (dataField) {
        return `${dataField.fieldContentType}${dataField.fieldContentId}`;
      },
    ),
  );
  const combinedSpaceAvailabilityIds = computed(() => {
    return combinedTimelineSpaceAvailabilityFields.value.map(
      (dataField) => dataField.fieldContentId,
    );
  });
  function triggerRefetch() {
    timeTravelTo.value = moment(asOfDate.value).add(1, "second").toDate();

    setTimeout(() => {
      if (moment().isSame(timeTravelTo.value, "day")) {
        timeTravelTo.value = null;
      }
    }, 100);
  }

  return {
    timeTravelTo,
    chosenDate,
    asOfDate,
    asOfMilliseconds,
    dayBeforeAsOf,
    hoveringMarkerField,
    refreshingMainTimeline,
    mainTimelineDated,
    mainTimelineUndated,
    mainTimelineCustomForwardDate,
    mainTimelineCustomLookbackDate,
    datedInvestmentFields,
    datedInvestmentIds,
    datedSpaceAvailabilityFields,
    datedSpaceAvailabilityIds,
    combinedTimelineInvestmentFields,
    combinedInvestmentIds,
    undatedInvestmentFields,
    undatedInvestmentIds,
    undatedSpaceAvailabilityFields,
    undatedSpaceAvailabilityIds,
    combinedTimelineSpaceAvailabilityFields,
    combinedSpaceAvailabilityIds,
    triggerRefetch,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTimeTravelStore, import.meta.hot));
}
