<template>
  <section aria-labelledby="space_usage_stack">
    <ContentHeader>
      <template v-slot:title>
        <span>Space Usage Stack</span>
        <span class="flex items-center">
          <CalendarDaysIcon
            class="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
          />
          <p>
            as of
            <time :datetime="asOfDate">{{
              moment(asOfDate).format("MMM YYYY")
            }}</time>
          </p>
        </span>
      </template>
      <template v-slot:description>Contractual usages of space.</template>
    </ContentHeader>

    <!-- Current stack -->
    <SpaceUsageStackDiagram
      :property-id="
        dataField.joiningContentId || dataField.fieldContent?.propertyId
      "
      :data-field="dataField"
      context="details"
      class="px-2"
    />
  </section>
</template>

<script setup>
import { CalendarDaysIcon } from "@heroicons/vue/20/solid";
import ContentHeader from "@/components/main-layout/secondary-panel/ContentHeader.vue";
import SpaceUsageStackDiagram from "@/components/space-usage-builder/SpaceUsageStackDiagram.vue";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import moment from "moment";

defineProps(["dataField"]);

const timeTravelStore = useTimeTravelStore();
const { asOfDate } = storeToRefs(timeTravelStore);
</script>
