<template>
  <div
    class="flex flex-col divide-y divide-gray-200"
    :data-test="`Availability-${focalAvailability.id}-container`"
  >
    <div class="p-2 flex items-center justify-between text-gray-500 text-sm">
      <div
        v-if="
          maskedGroupAvailability && availability.groupAvailabilityDataField
        "
        class="flex items-center space-x-1"
      >
        <DataField
          :data-field="availability.groupAvailabilityDataField"
          primary-text-path="fieldContent.dealAction"
          text-classes="text-sm font-medium"
          text-styles=""
          @open-sourced="refetchGroupAvailabilities"
          @unlocked="refetchGroupAvailabilities"
          @completed="refetchGroupAvailabilities"
          @dismiss="refetchGroupAvailabilities"
        />
      </div>
      <template v-else>
        <div class="flex items-center space-x-1">
          <button
            @click.prevent="tabsExpanded = !tabsExpanded"
            type="button"
            :data-test="`Availability-${focalAvailability.id}-details-header-collapse-icon`"
            class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-800"
          >
            <ChevronDownIcon class="h-5 w-5" />
          </button>
          <DataField
            v-if="availability.groupAvailabilityDataField"
            :data-field="availability.groupAvailabilityDataField"
            :primary-text-prop-override="availabilityLabel"
            text-classes="text-sm font-medium"
            text-styles=""
            :skip-post-editing-patch="true"
            @open-sourced="refetchGroupAvailabilities"
            @unlocked="refetchGroupAvailabilities"
            @completed="refreshGroup"
            @dismiss="refreshGroup"
          />
          <DataField
            v-else-if="dataFieldAvailabilityFieldContent"
            :data-field="availability"
            text-classes="text-sm font-medium"
            text-styles=""
            @open-sourced="refetchBuilder"
            @unlocked="refetchBuilder"
            @completed="refetchBuilder"
            @dismiss="refetchBuilder"
          />
          <a
            v-else
            @click.prevent="tabsExpanded = !tabsExpanded"
            href=""
            class="hover:text-gray-700 font-semibold"
            >{{ availabilityLabel }}</a
          >
        </div>
        <div class="flex items-center space-x-1">
          <button
            v-if="canFocusTimeline"
            @click="setTimeline"
            type="button"
            v-tooltip="'Focus in timeline'"
            class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
          <span
            v-if="inPlay"
            class="inline-flex items-center justify-center h-3 w-3 rounded-full bg-green-500"
          />
          <span
            v-else-if="dead"
            v-tooltip="'Withdrawn'"
            :data-test="`Availability-${focalAvailability.id}-dead-deal-icon`"
            class="inline-flex items-center justify-center h-5 w-5 text-red-600"
            style="font-size: 16px"
          >
            <i class="fas fa-skull-crossbones" />
          </span>
          <a
            v-if="canFocusTimeline"
            @click.prevent="setTimeline"
            href=""
            v-tooltip="'Focus in timeline'"
            :data-test="`Availability-${focalAvailability.id}-details-header-date-label`"
            class="text-gray-500 hover:text-gray-700"
            >{{ dateLabel }}</a
          >
          <div
            v-else
            :data-test="`Availability-${focalAvailability.id}-details-header-date-label`"
          >
            {{ dateLabel }}
          </div>
          <button
            v-if="canAddToGroup"
            @click="saveToExistingGroup"
            type="button"
            class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add
          </button>
          <button
            v-else-if="existingPortfolio"
            @click="openPortfolio"
            type="button"
            v-tooltip.top-end="
              'This availability is part of a group. Click to view the group.'
            "
            class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
          >
            <Squares2X2Icon class="h-5 w-5" />
          </button>
          <template v-if="portfoliosAllowed">
            <VDropdown v-if="availabilityGroups.length > 0">
              <button
                type="button"
                v-tooltip.top-end="'Add to group...'"
                class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
              >
                <SquaresPlusIcon class="h-5 w-5" />
              </button>

              <template #popper>
                <div
                  class="w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabindex="-1"
                >
                  <div class="py-1" role="none">
                    <a
                      v-for="(group, index) in availabilityGroups"
                      :key="index"
                      @click.prevent="addToPortfolio(group)"
                      href=""
                      class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-1"
                    >
                      Add to{{
                        _.includes(group.placeholderId, "temp")
                          ? " Draft "
                          : " "
                      }}Group{{
                        _.includes(group.placeholderId, "temp")
                          ? ""
                          : group.placeholderId
                      }}:
                      {{
                        pluralize(
                          "Space",
                          spaceUsageBuilderStore.availabilitiesLength(group),
                          true,
                        )
                      }}
                    </a>
                    <a
                      @click.prevent="newPortfolio"
                      href=""
                      class="text-gray-700 hover:bg-gray-100 hover:text-gray-900 group flex items-center px-4 py-2 text-sm"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-0"
                    >
                      New Group
                    </a>
                  </div>
                </div>
              </template>
            </VDropdown>

            <button
              v-else
              type="button"
              @click="newPortfolio"
              v-tooltip.top-end="'Add to new group'"
              class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z"
                />
              </svg>
            </button>
          </template>
          <button
            v-else-if="!alreadyInDiagram"
            @click="addSpaceToBuilder"
            type="button"
            v-tooltip="'Open space in Space Hub'"
            class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z"
              />
            </svg>
          </button>
          <button
            v-if="discardable"
            @click="removeAvailabilityConfirmation"
            type="button"
            v-tooltip="'Discard'"
            :data-test="`discard-availability-${focalAvailability.id}-from-group-button`"
            class="h-5 w-5 p-1 inline-flex justify-center items-center text-sm text-gray-500 hover:text-gray-700"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </template>
    </div>
    <AvailabilityDetailsTabs
      :space-object="spaceObject"
      :availability="availability"
      :availability-group-id="availabilityGroupId"
      :expanded-override="tabsExpanded"
    />
  </div>
</template>

<script setup>
import {
  ChevronDownIcon,
  Squares2X2Icon,
  SquaresPlusIcon,
} from "@heroicons/vue/20/solid";
import AvailabilityDetailsTabs from "@/components/space-usage-builder/AvailabilityDetailsTabs.vue";
import { useCrowdsourcedChangeGroupStore } from "@/stores/crowdsourcedChangeGroup";
import { useSpaceUsageBuilderStore } from "@/stores/spaceUsageBuilder";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { usePropertyDiagramStore } from "@/stores/propertyDiagram";
import { useModalStore } from "@/stores/modal";
import { storeToRefs } from "pinia";
import { computed, nextTick, onMounted, ref } from "vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import decoratingAndFieldKey from "@/components/crowdsourcing/decoratingAndFieldKey";
import investmentStates from "@/components/deal-builder/investmentStates";
import propertySpaceLabel from "@/assets/spaceLabel";
import { useRoute, useRouter } from "vue-router";
import VueScrollTo from "vue-scrollto";
import _ from "lodash";
import api from "@/router/api";
import moment from "moment";
import pluralize from "pluralize";

const props = defineProps([
  "spaceObject",
  "availability",
  "availabilityGroupId",
]);

const spaceUsageBuilderStore = useSpaceUsageBuilderStore();
const {
  spaceUsageBuilder,
  spaces,
  availabilityGroups,
  refetchSpaceUsageBuilderEditor,
} = storeToRefs(spaceUsageBuilderStore);
const timeTravelStore = useTimeTravelStore();
const { timeTravelTo } = storeToRefs(timeTravelStore);
const modalStore = useModalStore();
const { confirmationPayload } = storeToRefs(modalStore);
const changeGroupStore = useCrowdsourcedChangeGroupStore();
const { changeGroupId } = storeToRefs(changeGroupStore);
const propertyFieldsStore = usePropertyFieldsStore();
const propertyDiagramStore = usePropertyDiagramStore();
const { propertyDiagramPropertyIds, selectedTimelineEvent } =
  storeToRefs(propertyDiagramStore);

const tabsExpanded = ref(false);
const propertyDataField = computed(() => {
  return propertyFieldsStore.getPropertyField(propertyId.value);
});
const dataFieldAvailabilityFieldContent = computed(() => {
  return _.get(props.availability, "fieldContent");
});
const focalAvailability = computed(() => {
  const spaceUsageBuilderExistingAvailability = _.get(
    props.availability,
    "existingAvailability",
  );

  return (
    dataFieldAvailabilityFieldContent.value ||
    spaceUsageBuilderExistingAvailability ||
    props.availability
  );
});
const builderCombinedKey = computed(() => {
  if (
    focalAvailability.value.topLevelSpace &&
    focalAvailability.value.availabilityFieldContent
  ) {
    const spaceKey = decoratingAndFieldKey(
      focalAvailability.value.topLevelSpace,
    );
    const availabilityKey = decoratingAndFieldKey(
      focalAvailability.value.availabilityFieldContent,
    );

    return `${spaceKey}_${availabilityKey}`;
  } else {
    return _.get(props.availability, "temporaryId");
  }
});
const existingAvailabilityId = computed(() => {
  return (
    _.get(focalAvailability.value, "id") ||
    _.get(focalAvailability.value, "fieldContentId")
  );
});
const canFocusTimeline = computed(() => {
  return (
    !props.availabilityGroupId &&
    existingAvailabilityId.value &&
    _.get(focalAvailability.value, "date")
  );
});

const maskedGroupAvailability = computed(() => {
  return props.availabilityGroupId && focalAvailability.value?.masked;
});
const alreadyInDiagram = computed(() => {
  return _.find(spaces.value, function (space) {
    const dataField = topLevelSpaceDataField.value;

    if (dataField) {
      const key = `${space.dataField.fieldContentType}${space.dataField.fieldContentId}`;
      const fieldKey = `${dataField.fieldContentType}${dataField.fieldContentId}`;
      return key === fieldKey;
    } else {
      return !props.availabilityGroupId;
    }
  });
});
const discardable = computed(() => {
  if (props.availabilityGroupId) {
    return true;
  } else {
    return !existingAvailabilityId.value;
  }
});
const topLevelSpaceDataField = computed(() =>
  _.get(focalAvailability.value, "topLevelSpace"),
);
const space = computed(
  () =>
    _.get(focalAvailability.value, "space") ||
    _.get(focalAvailability.value, "topLevelSpace"),
);
const spaceLabel = computed(() => {
  return propertySpaceLabel(space.value, propertyDataField.value);
});
const availabilityLabel = computed(() => {
  if (spaceLabel.value) {
    return spaceLabel.value;
  } else {
    return `TODO: SPACE USAGE LABEL`;
  }
});
const dateLabel = computed(() => {
  switch (focalAvailability.value.state) {
    case "withdrawn":
    case "live":
    case "under_contract":
      return _.find(investmentStates, {
        value: focalAvailability.value.state,
      }).statusLabel;
    case "closed":
    default: {
      if (focalAvailability.value.date) {
        return moment(focalAvailability.value.date).format("MMM YYYY");
      } else {
        return "Undated";
      }
    }
  }
});
const state = computed(() => {
  return _.get(focalAvailability.value, "state");
});
const inPlay = computed(() => {
  return state.value && !_.includes(["closed", "withdrawn"], state.value);
});
const dead = computed(() => {
  return state.value && state.value === "withdrawn";
});

const propertyId = computed(() => {
  return (
    _.get(focalAvailability.value, "topLevelPropertyId") ||
    _.get(focalAvailability.value, "topLevelSpace.fieldContent.propertyId")
  );
});
const portfolioId = computed(() => {
  return (
    _.get(focalAvailability.value, "portfolioId") ||
    _.get(matchingAvailabilityGroup.value, "placeholderId")
  );
});
const existingPortfolio = computed(
  () => !props.availabilityGroupId && portfolioId.value,
);
const portfoliosAllowed = computed(() => {
  return (
    !props.availabilityGroupId &&
    existingAvailabilityId.value &&
    _.isNil(portfolioId.value)
  );
});
const canAddToGroup = computed(() => {
  return (
    props.availabilityGroupId &&
    existingAvailabilityId.value &&
    !_.get(focalAvailability.value, "portfolioId") &&
    _.get(matchingAvailabilityGroup.value, "id")
  );
});
const matchingAvailabilityGroup = computed(() => {
  return _.find(availabilityGroups.value, function (group) {
    return _.find(group.availabilities, function (availability) {
      const localAvailabilityId =
        _.get(props.availability, "existingAvailability.id") ||
        _.get(props.availability, "id") ||
        _.get(props.availability, "temporaryId");

      return (
        group.id === portfolioId.value ||
        _.get(availability, "existingAvailability.id") === localAvailabilityId
      );
    });
  });
});

onMounted(() => {
  fetchPropertyDataField();
  debouncedAvailabilityFetch();
});

const debouncedAvailabilityFetch = _.debounce(function () {
  fetchAvailability();
}, 1000);

function fetchAvailability() {
  const fetchRequestKey = `availabilities_${existingAvailabilityId.value}`;

  if (
    existingAvailabilityId.value &&
    !dataFieldAvailabilityFieldContent.value &&
    props.spaceObject?.dataField
  ) {
    if (spaceUsageBuilderStore.alreadyFetched(fetchRequestKey)) {
      spaceUsageBuilderStore.updateSpaceAvailabilities({
        assetKey: decoratingAndFieldKey(props.spaceObject.dataField),
        availabilities:
          spaceUsageBuilderStore.alreadyFetchedFieldsFor(fetchRequestKey),
      });
    } else {
      api.get(`availabilities/${existingAvailabilityId.value}`).then((json) => {
        spaceUsageBuilderStore.interceptablePatch([json.data], fetchRequestKey);
        spaceUsageBuilderStore.updateSpaceAvailabilities({
          assetKey: decoratingAndFieldKey(props.spaceObject.dataField),
          availabilities: [json.data],
        });
      });
    }
  }
}

function fetchPropertyDataField() {
  if (
    props.availabilityGroupId &&
    propertyId.value &&
    !maskedGroupAvailability.value
  ) {
    propertyFieldsStore.fetchPropertyDataField(propertyId.value);
  }
}

async function refetchGroupAvailabilities() {
  await spaceUsageBuilderStore.fetchAvailabilityGroupAvailabilities(
    props.availabilityGroupId,
  );
  fetchPropertyDataField();
}

function refreshGroup() {
  spaceUsageBuilderStore.refreshAvailabilityGroup({
    groupId: props.availabilityGroupId,
    isExistingGroup: !!_.get(matchingAvailabilityGroup.value, "id"),
  });
}

const router = useRouter();
const route = useRoute();

function setTimeline() {
  if (focalAvailability.value.date) {
    timeTravelTo.value = focalAvailability.value.date;

    selectedTimelineEvent.value = {
      eventType: "SpaceAvailability",
      event: focalAvailability.value,
    };

    router.push({
      name: route.name,
      query: {
        ...route.query,
        asOf: moment(focalAvailability.value.date).endOf("month").valueOf(),
      },
    });
  }
}
function addSpaceToBuilder() {
  const dataField = topLevelSpaceDataField.value;

  if (dataField) {
    const spaceKey = decoratingAndFieldKey(dataField);
    const spaceObject = {
      dataField,
      availabilities: [],
      ownershipInterests: [],
      expanded: true,
      datedTimelineFetched: false,
      undatedTimelineFetched: false,
    };

    spaceUsageBuilderStore.addSpace({
      spaceObject,
      spaceKey,
    });
    spaceUsageBuilderStore.collapseAllSpacesExcept({ spaceKey });

    if (
      dataField.joiningContentType === "Property" ||
      dataField.fieldContent?.propertyId
    ) {
      const propertyId =
        dataField.joiningContentType === "Property"
          ? dataField.joiningContentId
          : dataField.fieldContent?.propertyId;
      if (!_.includes(propertyDiagramPropertyIds.value, propertyId)) {
        propertyDiagramStore.addPropertyToDiagram(propertyId);
      }
    }
  }
}

async function saveToExistingGroup() {
  const payload = {
    availabilityGroupId: matchingAvailabilityGroup.value.id,
    availabilityId: existingAvailabilityId.value,
    changeGroupId: changeGroupId.value,
  };

  const apiRequestFunc = () =>
    api.post(`availability_group_availabilities`, payload);
  const successCallback = () => {
    refreshGroup();
  };
  const failureCallback = () => {};
  await changeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}
function newPortfolio() {
  spaceUsageBuilderStore.addAvailabilityGroup({
    existingAvailability: focalAvailability.value,
    spaceDataField: props.spaceObject.dataField,
    availabilityFieldContent: focalAvailability.value.space,
  });

  if (matchingAvailabilityGroup.value) {
    spaceUsageBuilderStore.collapsePortfolioSpaces({
      groupId:
        matchingAvailabilityGroup.value.id ||
        matchingAvailabilityGroup.value.placeholderId,
    });
  }
}
function addToPortfolio(availabilityGroup) {
  spaceUsageBuilderStore.addAvailabilityToGroup({
    existingAvailability: focalAvailability.value,
    spaceDataField: props.spaceObject.dataField,
    availabilityFieldContent: focalAvailability.value.space,
    availabilityGroupPlaceholderId: availabilityGroup.placeholderId,
  });
  spaceUsageBuilderStore.collapsePortfolioSpaces({
    groupId: availabilityGroup.id || availabilityGroup.placeholderId,
  });
  spaceUsageBuilderStore.setAvailabilityGroupExpanded({
    groupId: availabilityGroup.id || availabilityGroup.placeholderId,
    expanded: true,
  });
}
function focusAvailabilityGroup(availabilityGroup) {
  spaceUsageBuilderStore.collapsePortfolioSpaces({
    groupId: availabilityGroup.id || availabilityGroup.placeholderId,
  });
  spaceUsageBuilderStore.setAvailabilityGroupExpanded({
    groupId: availabilityGroup.id || availabilityGroup.placeholderId,
    expanded: true,
  });
}
async function openPortfolio() {
  if (matchingAvailabilityGroup.value) {
    focusAvailabilityGroup(matchingAvailabilityGroup.value);
  } else {
    spaceUsageBuilderStore.addAvailabilityGroup({
      existingGroupId: portfolioId.value,
    });
  }

  // spaceUsageBuilderStore.collapseAllSpacesExcept({ spaceKey: null });
  await nextTick();
  VueScrollTo.scrollTo("#availability-groups-list", 100, {
    container: "#lists-panel-container",
  });
}
function removeAvailabilityConfirmation() {
  if (
    existingAvailabilityId.value &&
    _.get(focalAvailability.value, "portfolioId")
  ) {
    confirmationPayload.value = {
      title: "Remove space",
      message: "Are you sure you want to remove this space from the group?",
      affirmText: "Remove",
      affirmCallback: removeAvailability,
    };
  } else {
    removeAvailabilityLocally();
  }
}
async function removeAvailability() {
  removeAvailabilityFromDatabase().then(() => {
    refreshGroup();
  });
}
async function removeAvailabilityFromDatabase() {
  const apiRequestFunc = () =>
    api.delete(
      `availability_group_availabilities/${existingAvailabilityId.value}`,
    );
  const successCallback = () => {
    // this.$store.dispatch("flash", "Availability removed from group.");
  };
  const failureCallback = () => {};
  await changeGroupStore.originateData(
    apiRequestFunc,
    successCallback,
    failureCallback,
  );
}
function removeAvailabilityLocally() {
  spaceUsageBuilderStore.removeSpaceAvailability({
    availabilityKey: builderCombinedKey.value,
  });
}
function refetchBuilder() {
  const refetchKey = decoratingAndFieldKey(props.spaceObject.dataField);

  spaceUsageBuilderStore.clearBuilder(false);
  spaceUsageBuilderStore.resetAvailabilities();
  refetchSpaceUsageBuilderEditor.value = refetchKey;
  timeTravelStore.triggerRefetch();
}
</script>
