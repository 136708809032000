import { currencyBelowThreshold } from "@/assets/numberHelpers";
import _ from "lodash";
import { storeToRefs } from "pinia";

export function operableDeals(
  calculationData,
  calculationOptions,
  analyzePanelStore,
) {
  const { investmentLikeFilterable } = storeToRefs(analyzePanelStore);
  const investmentDataFields = _.get(
    calculationData,
    "investmentVolume.investmentDataFields",
    [],
  );
  const allInvestmentGroups = _.get(
    calculationData,
    "investmentVolume.investmentGroupDataFields",
    [],
  );
  let excludedInvestmentIds = [];
  const operableInvestmentGroups = allInvestmentGroups.filter((dataField) => {
    const investmentIds = dataField.fieldContent?.investmentIds;
    const allWithinView = _.every(investmentIds, function (investmentId) {
      const filteredInvestmentIncluded =
        analyzePanelStore.combinedFilteredInvestmentIncluded(investmentId);
      const valuationIncluded = investmentLikeFilterable.value
        ? analyzePanelStore.investmentLikeIncluded(
            investmentId,
            dataField.fieldContentId,
          )
        : true;
      return filteredInvestmentIncluded && valuationIncluded;
    });

    if (allWithinView) {
      excludedInvestmentIds = _.union(excludedInvestmentIds, investmentIds);
    }

    return allWithinView;
  });
  const operableInvestments = investmentDataFields.filter((dataField) => {
    const investmentId = dataField.fieldContentId;
    const filteredInvestmentIncluded =
      analyzePanelStore.combinedFilteredInvestmentIncluded(investmentId);
    const valuationIncluded = investmentLikeFilterable.value
      ? analyzePanelStore.investmentLikeIncluded(investmentId)
      : true;
    return (
      !_.includes(excludedInvestmentIds, investmentId) &&
      filteredInvestmentIncluded &&
      valuationIncluded
    );
  });

  // console.log(
  //   "operable investments",
  //   operableInvestmentGroups,
  //   operableInvestments,
  // );

  return _.unionBy(operableInvestmentGroups, operableInvestments, "localId");
}

function includeDebt(dealDataField) {
  return _.includes(
    ["originateLoan", "refinance"],
    dealDataField.fieldContent?.dealAction || dealDataField.dealAction,
  );
}
function excludeDebt(dealDataField) {
  return !_.includes(
    ["originateLoan", "refinance"],
    dealDataField.fieldContent?.dealAction || dealDataField.dealAction,
  );
}

export function operableValuations(
  calculationData,
  calculationOptions,
  analyzePanelStore,
) {
  const includedValueTypes = calculationOptions.investmentVolume.valueTypes;
  const groupValuations = _.get(
    calculationData,
    "investmentVolume.investmentGroupValuationDataFields",
    [],
  );
  const investmentValuations = _.get(
    calculationData,
    "investmentVolume.investmentValuationDataFields",
    [],
  );

  const typeMatchedValuations = operableDeals(
    calculationData,
    calculationOptions,
    analyzePanelStore,
  )
    .filter(excludeDebt)
    .map((dealDataField) => {
      switch (dealDataField.fieldContentType) {
        case "InvestmentGroup": {
          const matchedDeals = groupValuations.filter((valuationDataField) => {
            const dealMatch =
              valuationDataField.decoratingContentId ===
              dealDataField.fieldContentId;
            const typeMatch = _.includes(
              includedValueTypes,
              valuationDataField.fieldContent.valueType,
            );

            return dealMatch && typeMatch;
          });

          return _.head(_.sortBy(matchedDeals, ["fieldContent.sortOrder"]));
        }
        case "Investment": {
          const matchedDeals = investmentValuations.filter(
            (valuationDataField) => {
              const dealMatch =
                valuationDataField.decoratingContentId ===
                dealDataField.fieldContentId;
              const typeMatch = _.includes(
                includedValueTypes,
                valuationDataField.fieldContent.valueType,
              );

              return dealMatch && typeMatch;
            },
          );

          return _.head(_.sortBy(matchedDeals, ["fieldContent.sortOrder"]));
        }
        default:
          return null;
      }
    });

  // console.log(includedValueTypes, typeMatchedValuations);

  return _.compact(typeMatchedValuations);
}

function loanId(dealDataField) {
  return (
    dealDataField.fieldContent?.loanId ||
    dealDataField.fieldContent?.loanCollateral?.decoratingContentId
  );
}

function operableLoanIds(
  calculationData,
  calculationOptions,
  analyzePanelStore,
) {
  const loanIds = operableDeals(
    calculationData,
    calculationOptions,
    analyzePanelStore,
  )
    .filter(includeDebt)
    .map(loanId);

  // console.log("loan ids", _.uniq(loanIds));

  return _.uniq(loanIds);
}

function valueSum(dataFields) {
  const numericValues = dataFields.map((df) => _.toNumber(df.fieldValue));
  console.log("values", numericValues);
  return _.sum(numericValues);
}

function totalValue(calculationData, calculationOptions, analyzePanelStore) {
  const valueDataFields = _.get(
    calculationData,
    "investmentVolume.valueDataFields",
    [],
  );
  const valuationMatchIds = operableValuations(
    calculationData,
    calculationOptions,
    analyzePanelStore,
  ).map((dataField) => dataField.fieldContentId);
  const operableValues = valueDataFields.filter((dataField) => {
    return _.includes(valuationMatchIds, dataField.decoratingContentId);
  });

  const loanAmountDataFields = _.get(
    calculationData,
    "investmentVolume.loanAmountDataFields",
    [],
  );
  const loanIds = operableLoanIds(
    calculationData,
    calculationOptions,
    analyzePanelStore,
  );
  const operableLoanAmounts = loanAmountDataFields.filter((dataField) => {
    return _.includes(loanIds, dataField.decoratingContentId);
  });

  console.log(operableValues, operableLoanAmounts);

  return valueSum(operableValues) + valueSum(operableLoanAmounts);
}

function totalValueString(
  calculationData,
  calculationOptions,
  analyzePanelStore,
) {
  return `$${currencyBelowThreshold(
    totalValue(calculationData, calculationOptions, analyzePanelStore),
  )}`;
}

function noValue(calculationData, calculationOptions, analyzePanelStore) {
  const deals = operableDeals(
    calculationData,
    calculationOptions,
    analyzePanelStore,
  ).filter(excludeDebt);
  const valuations = operableValuations(
    calculationData,
    calculationOptions,
    analyzePanelStore,
  );

  return deals.filter((df) => {
    return !_.find(valuations, {
      decoratingContentId: df.fieldContentId,
    });
  });
}

function noLoanAmount(calculationData, calculationOptions, analyzePanelStore) {
  const loanAmountDataFields = _.get(
    calculationData,
    "investmentVolume.loanAmountDataFields",
    [],
  );
  const deals = operableDeals(
    calculationData,
    calculationOptions,
    analyzePanelStore,
  ).filter(includeDebt);

  return deals.filter((df) => {
    const id = loanId(df);
    return !_.find(loanAmountDataFields, {
      decoratingContentId: id,
    });
  });
}

// WARNINGS

function warnings(calculationData, calculationOptions, analyzePanelStore) {
  const noValueFields = noValue(
    calculationData,
    calculationOptions,
    analyzePanelStore,
  );
  let noValueWarning;

  if (_.size(noValueFields) > 0) {
    noValueWarning = {
      id: "no_value",
      description: "Valuations missing.",
      extendedDescription:
        "Fix this by adding a valuation to the investment (or related portfolio).",
      rawFields: noValueFields,
    };
  }

  const noLoanAmountFields = noLoanAmount(
    calculationData,
    calculationOptions,
    analyzePanelStore,
  );
  let noLoanAmountWarning;

  if (_.size(noLoanAmountFields) > 0) {
    noLoanAmountWarning = {
      id: "no_loan_amount",
      description: "Loan amounts missing.",
      extendedDescription:
        "Fix this by adding a loan amount to the investment (or related portfolio).",
      rawFields: noLoanAmountFields,
    };
  }

  return _.compact([noValueWarning, noLoanAmountWarning]);
}

export function investmentVolumeOutputs(
  calculationData,
  calculationOptions,
  analyzePanelStore,
) {
  return {
    totalValue: totalValueString(
      calculationData,
      calculationOptions,
      analyzePanelStore,
    ),
    warnings: warnings(calculationData, calculationOptions, analyzePanelStore),
  };
}
