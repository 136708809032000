<template>
  <tr
    v-if="investmentId"
    v-observe-visibility="{ callback: fetchInvestmentDataField, once: true }"
    :class="portfolioInvestment ? 'bg-orange-50' : ''"
  >
    <td>
      <span class="sr-only">Empty</span>
    </td>
    <td
      v-if="investmentDataField"
      @mouseenter="pulseProperty"
      @mouseleave="propertyMarkerPulseId = null"
      class="whitespace-nowrap py-2 pr-3"
      :class="portfolioInvestment ? 'pl-4' : ''"
    >
      <div v-if="investmentDataField" class="flex items-center">
        <DataField
          :data-field="investmentDataField"
          :property-data-field="propertyDataField"
          text-classes="text-sm font-medium"
          dropdown-placement="left-start"
          text-styles=""
          :analyze="true"
          @completed="refetchAll"
          @unlocked="refetchAll"
          @open-sourced="refetchAll"
        />
      </div>
    </td>
    <td
      v-if="investmentDataField"
      class="whitespace-nowrap px-2 py-2 text-xs text-gray-500"
    >
      <DataField
        v-if="locationDataField"
        :data-field="locationDataField"
        text-classes="text-sm"
        :analyze="true"
      />
      <template v-else>Unlock</template>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <div class="grid grid-cols-3 gap-x-2">
        <PrincipalsDetail
          v-if="investmentDataField?.fieldContent"
          transaction-context="capMarkets"
          transaction-side="demand"
          display-context="table-row"
          :investment="investmentDataField?.fieldContent"
        />
        <PrincipalsDetail
          v-if="investmentDataField?.fieldContent"
          transaction-context="capMarkets"
          transaction-side="supply"
          display-context="table-row"
          :investment="investmentDataField?.fieldContent"
        />
        <AdvisorsDetail
          v-if="investmentDataField?.fieldContent"
          :investment="investmentDataField.fieldContent"
          :fetch-milliseconds="fetchMilliseconds"
          clientRole="capitalConsumer"
          context="table-row"
        />
      </div>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <div v-if="selectedValuation" class="flex items-center space-x-1">
        <DataField
          v-if="selectedValue"
          :data-field="selectedValue"
          text-classes="text-sm font-medium"
          dropdown-placement="left-start"
          text-styles=""
          :analyze="true"
        />
        <VDropdown>
          <slot name="button">
            <button
              type="button"
              class="rounded flex items-center space-x-1 bg-white p-1 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <div class="text-xs text-gray-700">
                {{ selectedValuation.fieldContent.valueType }}
              </div>
              <ChevronDownIcon class="w-3 h-3 text-gray-700" />
            </button>
          </slot>
          <template #popper>
            <div class="flex flex-col">
              <div class="space-y-2">
                <h1 class="px-2 pt-2 text-xs font-semibold text-gray-700">
                  Choose a Valuation Type
                </h1>
                <ul class="max-h-56 overflow-y-auto divide-y divide-gray-200">
                  <li
                    v-for="dataField in valuationDataFields"
                    :key="dataField.localId"
                    class="px-2 py-1"
                  >
                    <a
                      @click.prevent="selectedValuation = dataField"
                      v-close-popper
                      href=""
                      class="block hover:bg-gray-50"
                    >
                      <div class="truncate text-xs font-medium text-gray-700">
                        {{ dataField.fieldContent.valueType }}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </VDropdown>
      </div>
      <DataField
        v-else-if="loanAmountDataField"
        :data-field="loanAmountDataField"
        text-classes="text-sm"
        :analyze="true"
      />
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <PricePerArea
        v-if="investmentDataField && selectedValuation"
        :content-type="selectedValuation?.fieldContentType"
        :content-ids="[selectedValuation?.fieldContentId]"
        :decorating-content-type="record.type"
        :decorating-content-id="valuationContentId"
        context="analyze"
        text-classes="text-sm font-medium"
        @refetch="refetchAll"
      />
    </td>
  </tr>

  <tr
    v-if="investmentGroupId"
    v-observe-visibility="{
      callback: fetchInvestmentGroupDataField,
      once: true,
    }"
  >
    <td class="relative whitespace-nowrap py-2 px-3 text-sm font-medium">
      <button
        @click="expanded = !expanded"
        type="button"
        v-tooltip="
          expanded ? 'Hide portfolio investments' : 'View portfolio investments'
        "
        class="h-5 w-5 inline-flex justify-center items-center text-sm text-gray-700 hover:text-gray-900"
      >
        <ChevronDownIcon class="h-5 w-5" />
      </button>
    </td>
    <td
      v-if="investmentGroupDataField"
      @mouseenter="pulseProperty"
      @mouseleave="propertyMarkerPulseId = null"
      class="whitespace-nowrap px-2 py-2 text-sm text-gray-500"
    >
      <div class="flex items-center space-x-1">
        <ExclamationTriangleIcon
          v-if="offMapGroupInvestments"
          class="h-5 w-5 text-orange-500"
          v-tooltip="
            `Some portfolio investments are outside the ${
              geographyFilterPolygon ? 'polygon' : 'map'
            } boundaries`
          "
        />
        <DataField
          v-if="investmentGroupDataField"
          :data-field="investmentGroupDataField"
          text-classes="text-sm font-medium"
          dropdown-placement="left-start"
          text-styles=""
          :analyze="true"
          @completed="fetchInvestmentGroupDataField"
        />
      </div>
    </td>
    <td
      @click="expanded = !expanded"
      class="whitespace-nowrap px-2 py-2 text-xs text-gray-500 cursor-pointer"
    >
      {{ expanded ? "See below" : "Expand to see" }}
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <div class="grid grid-cols-3 gap-x-2">
        <GroupPrincipalsDetail
          transaction-context="capMarkets"
          transaction-side="demand"
          display-context="table-row"
          :existingGroupId="investmentGroupId"
          :fetched-timing-fields="groupTimingDataFields"
        />
        <GroupPrincipalsDetail
          transaction-context="capMarkets"
          transaction-side="supply"
          display-context="table-row"
          :existingGroupId="investmentGroupId"
          :fetched-timing-fields="groupTimingDataFields"
        />
        <InvestmentGroupAdvisors
          :existingGroupId="investmentGroupId"
          context="table-row"
          clientRole="capitalConsumer"
          :fetch-milliseconds="fetchMilliseconds"
        />
      </div>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <div v-if="selectedValuation" class="flex items-center space-x-1">
        <DataField
          v-if="selectedValue"
          :data-field="selectedValue"
          text-classes="text-sm font-medium"
          dropdown-placement="left-start"
          text-styles=""
          :analyze="true"
        />
        <VDropdown>
          <slot name="button">
            <button
              type="button"
              class="rounded flex items-center space-x-1 bg-white p-1 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <div class="text-xs text-gray-700">
                {{ selectedValuation.fieldContent.valueType }}
              </div>
              <ChevronDownIcon class="w-3 h-3 text-gray-700" />
            </button>
          </slot>
          <template #popper>
            <div class="flex flex-col">
              <div class="space-y-2">
                <h1 class="px-2 pt-2 text-xs font-semibold text-gray-700">
                  Choose a Valuation Type
                </h1>
                <ul class="max-h-56 overflow-y-auto divide-y divide-gray-200">
                  <li
                    v-for="dataField in valuationDataFields"
                    :key="dataField.localId"
                    class="px-2 py-1"
                  >
                    <a
                      @click.prevent="selectedValuation = dataField"
                      v-close-popper
                      href=""
                      class="block hover:bg-gray-50"
                    >
                      <div class="truncate text-xs font-medium text-gray-700">
                        {{ dataField.fieldContent.valueType }}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </VDropdown>
      </div>
      <DataField
        v-else-if="loanAmountDataField"
        :data-field="loanAmountDataField"
        text-classes="text-sm"
        :analyze="true"
      />
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-xs text-gray-500">
      <PricePerArea
        v-if="investmentGroupDataField && selectedValuation"
        :content-type="selectedValuation?.fieldContentType"
        :content-ids="[selectedValuation?.fieldContentId]"
        :decorating-content-type="record.type"
        :decorating-content-id="valuationContentId"
        context="analyze"
        text-classes="text-sm font-medium"
        @refetch="refetchAll"
      />
    </td>
  </tr>

  <template v-if="investmentGroupDataField && expanded">
    <InvestmentTableRow
      v-for="id in withinMapGroupInvestmentIds"
      :key="`${investmentGroupDataField.localId}_Investment${id}`"
      :record="{ id, type: 'Investment' }"
      :portfolio-investment="true"
    />
    <tr v-if="offMapGroupInvestments">
      <td colspan="100">
        <div class="flex items-center space-x-1">
          <span class="text-xs text-gray-600 font-semibold ml-14">
            {{
              pluralize(
                "investment",
                groupInvestmentIds.length - withinMapGroupInvestmentIds.length,
                true,
              )
            }}
            outside {{ geographyFilterPolygon ? "polygon" : "map" }} boundaries
          </span>
          <a
            href=""
            @click.prevent="viewInvestmentGroup"
            class="text-xs text-indigo-700 font-semibold underline"
            >View portfolio</a
          >
        </div>
      </td>
    </tr>
  </template>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import AdvisorsDetail from "@/components/deal-builder/AdvisorsDetail.vue";
import PrincipalsDetail from "@/components/deal-builder/PrincipalsDetail.vue";
import PricePerArea from "@/components/analyze/calculations/PricePerArea.vue";
import GroupPrincipalsDetail from "@/components/deal-builder/GroupPrincipalsDetail.vue";
import InvestmentGroupAdvisors from "@/components/deal-builder/InvestmentGroupAdvisors.vue";
import { useMainMapStore } from "@/stores/mainMap";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useDealBuilderStore } from "@/stores/dealBuilder";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import pluralize from "pluralize";
import fullyUnlocked from "@/components/crowdsourcing/fullyUnlocked";
import _ from "lodash";
import {
  ChevronDownIcon,
  ExclamationTriangleIcon,
} from "@heroicons/vue/20/solid";
import moment from "moment";

const props = defineProps(["record", "portfolioInvestment"]);
const selectedValuation = ref(null);
const mapStore = useMainMapStore();
const { propertyMarkerPulseId, nearbyPropertyDataFields } =
  storeToRefs(mapStore);
const analyzePanelStore = useAnalyzePanelStore();
const {
  analyzeInvestmentFields,
  fetchedInvestmentKeys,
  geographyFilterPolygon,
} = storeToRefs(analyzePanelStore);
const timeTravelStore = useTimeTravelStore();
const { combinedInvestmentIds, asOfMilliseconds } =
  storeToRefs(timeTravelStore);
const dealBuilderStore = useDealBuilderStore();

const expanded = ref(true);
const fetchMilliseconds = computed(() => {
  if (investmentDataField.value) {
    return investmentDataField.value?.fieldContent?.date
      ? moment(investmentDataField.value?.fieldContent?.date).valueOf()
      : asOfMilliseconds.value;
  } else if (investmentGroupDataField.value) {
    if (groupTimingUnlocked.value) {
      const uniqueFields = _.uniqBy(groupTimingDataFields.value, "fieldName");
      const dateField = _.head(
        uniqueFields.filter((field) => field.fieldName !== "state"),
      );

      return dateField ? moment(dateField.fieldValue).valueOf() : null;
    } else if (groupDate.value) {
      return moment(groupDate.value).valueOf();
    } else {
      return null;
    }
  } else {
    return null;
  }
});
const investmentId = computed(() =>
  props.record.type === "Investment" ? props.record.id : null,
);
const investmentGroupId = computed(() =>
  props.record.type === "InvestmentGroup" ? props.record.id : null,
);
const valuationContentId = computed(() => {
  return investmentId.value || investmentGroupId.value;
});
const propertyId = computed(
  () =>
    investmentDataField.value?.fieldContent?.topLevelPropertyId ||
    investmentGroupDataField.value?.fieldContent?.topLevelPropertyId,
);
const propertyDataField = computed(() => {
  if (propertyId.value) {
    return analyzeInvestmentFields.value[`Property${propertyId.value}`];
  } else {
    return null;
  }
});
const investmentFetchKey = computed(() => `Investment${investmentId.value}`);
const investmentDataField = computed(() => {
  if (investmentId.value) {
    return analyzeInvestmentFields.value[investmentFetchKey.value];
  } else {
    return null;
  }
});
const loanId = computed(() => {
  if (
    _.includes(
      ["originateLoan", "refinance"],
      investmentDataField.value?.fieldContent?.dealAction,
    )
  ) {
    return investmentDataField.value?.fieldContent?.loanCollateral
      ?.decoratingContentId;
  } else if (investmentGroupDataField.value?.fieldContent?.loanId) {
    return investmentGroupDataField.value?.fieldContent?.loanId;
  } else {
    return null;
  }
});
const investmentGroupFetchKey = computed(
  () => `InvestmentGroup${investmentGroupId.value}`,
);
const investmentGroupDataField = computed(() => {
  if (investmentGroupId.value) {
    return analyzeInvestmentFields.value[investmentGroupFetchKey.value];
  } else {
    return null;
  }
});
const groupInvestmentIds = computed(
  () => investmentGroupDataField.value?.fieldContent?.investmentIds || [],
);
const withinMapGroupInvestmentIds = computed(() => {
  if (groupInvestmentIds.value.length > 0) {
    return groupInvestmentIds.value.filter((id) => {
      return _.includes(combinedInvestmentIds.value, id);
    });
  } else {
    return [];
  }
});
const offMapGroupInvestments = computed(
  () =>
    groupInvestmentIds.value.length > 0 &&
    groupInvestmentIds.value.length !==
      withinMapGroupInvestmentIds.value.length,
);
const groupTimingFetchKey = computed(() => {
  if (investmentGroupId.value) {
    return `InvestmentGroup${investmentGroupId.value}Timing`;
  } else {
    return null;
  }
});
const matchingGroupTimingKeys = computed(() => {
  return fetchedInvestmentKeys.value.filter((key) => {
    return _.includes(key, groupTimingFetchKey.value);
  });
});
const groupTimingDataFields = computed(() => {
  if (groupTimingFetchKey.value) {
    return _.filter(analyzeInvestmentFields.value, function (dataField, key) {
      return _.includes(matchingGroupTimingKeys.value, key);
    });
  } else {
    return [];
  }
});
const groupTimingUnlocked = computed(() => {
  return fullyUnlocked(groupTimingDataFields.value);
});
const groupDateState = computed(() => {
  const investments = _.compact(
    groupInvestmentIds.value.map((id) => {
      const fetchKey = `Investment${id}`;
      const investmentField = analyzeInvestmentFields.value[fetchKey];

      return investmentField?.fieldContent;
    }),
  );
  const ids = investments.map((i) => i?.id);
  const dates = _.compact(
    _.uniq(
      investments.map((i) => {
        if (i.date) {
          return moment(i.date).format("YYYYMMDD");
        } else {
          return null;
        }
      }),
    ),
  );
  const states = _.compact(
    _.uniq(
      investments.map((i) => {
        if (_.isObject(i.state)) {
          return null;
        } else if (i.state) {
          return i.state;
        } else {
          return null;
        }
      }),
    ),
  );
  const dealActions = _.uniq(investments.map((i) => i.dealAction));

  return { ids, dates, states, dealActions };
});
const groupDate = computed(() => {
  if (groupDateState.value.dates.length === 1) {
    let unparsed = _.head(groupDateState.value.dates);

    return moment(unparsed).toDate();
  } else {
    return null;
  }
});
const valuationFetchKey = computed(
  () => `${props.record.type}${valuationContentId.value}Valuation`,
);
const matchingValuationKeys = computed(() => {
  return fetchedInvestmentKeys.value.filter((key) => {
    return _.includes(key, valuationFetchKey.value);
  });
});
const valuationDataFields = computed(() => {
  const matchingFields = _.filter(
    analyzeInvestmentFields.value,
    function (dataField, key) {
      return _.includes(matchingValuationKeys.value, key);
    },
  );

  return _.sortBy(matchingFields, ["fieldContent.sortOrder"]);
});
const valueKeys = computed(() =>
  valuationDataFields.value.map(
    (dataField) => `Valuation${dataField.fieldContentId}value`,
  ),
);
const matchingValueKeys = computed(() =>
  _.intersection(fetchedInvestmentKeys.value, valueKeys.value),
);
const valueDataFields = computed(() => {
  const matchingFields = _.filter(
    analyzeInvestmentFields.value,
    function (dataField, key) {
      return _.includes(matchingValueKeys.value, key);
    },
  );

  return matchingFields;
});

const selectedValue = computed(() =>
  _.find(valueDataFields.value, {
    decoratingContentId: selectedValuation.value?.fieldContentId,
  }),
);
const selectedValuationId = computed(
  () => selectedValuation.value?.fieldContentId,
);
const loanAmountDataField = computed(() => {
  if (loanId.value) {
    const loanAmountFetchKey = `Loan${loanId.value}fully_funded_loan_amount`;

    return analyzeInvestmentFields.value[loanAmountFetchKey];
  } else {
    return null;
  }
});
const locationFromMap = computed(() => {
  if (propertyId.value) {
    return _.get(nearbyPropertyDataFields.value, propertyId.value);
  } else {
    return null;
  }
});
const locationDataField = computed(() => {
  if (propertyId.value) {
    if (locationFromMap.value) {
      return locationFromMap.value;
    } else if (propertyDataField.value) {
      return propertyDataField.value.fieldContent?.locationDataField;
    } else {
      return null;
    }
  } else {
    return null;
  }
});

watch(selectedValuationId, (id, oldId) => {
  if (id && oldId && !selectedValue.value) {
    fetchValue();
  }
});

function clearValuations() {
  _.forEach(matchingValueKeys.value, function (key) {
    delete analyzeInvestmentFields.value[key];
  });
  _.forEach(matchingValuationKeys.value, function (key) {
    delete analyzeInvestmentFields.value[key];
  });
}

function clearRow() {
  console.log("clear row");
  delete analyzeInvestmentFields.value[investmentFetchKey.value];
  delete analyzeInvestmentFields.value[investmentGroupFetchKey.value];
  clearValuations();
  analyzePanelStore.dropContentCalculationData({
    contentType: props.record.type,
    contentId: valuationContentId.value,
  });
}

function refetchAll() {
  console.log("investment row refetch all", valuationContentId.value);
  clearRow();
  switch (props.record.type) {
    case "Investment":
      delete analyzeInvestmentFields.value[investmentFetchKey.value];
      fetchInvestmentDataField();
      break;
    case "InvestmentGroup":
      delete analyzeInvestmentFields.value[investmentGroupFetchKey.value];
      fetchInvestmentGroupDataField();
      break;
  }
}

async function fetchInvestmentDataField() {
  if (!investmentDataField.value) {
    await analyzePanelStore.fetchInvestmentDataField(
      investmentId.value,
      investmentFetchKey.value,
    );
    if (loanId.value) {
      fetchLoanAmount();
    } else {
      fetchValuations();
    }
    analyzePanelStore.fetchPropertyDataField(propertyId.value);
  } else {
    if (loanId.value) {
      fetchLoanAmount();
    } else {
      fetchValuations();
    }
    analyzePanelStore.fetchPropertyDataField(propertyId.value);
  }
}

async function fetchInvestmentGroupDataField() {
  if (!investmentGroupDataField.value) {
    await analyzePanelStore.fetchInvestmentGroupDataField(
      investmentGroupId.value,
      investmentGroupFetchKey.value,
    );
    if (loanId.value) {
      fetchLoanAmount();
    } else {
      fetchValuations();
    }

    fetchInvestmentGroupTiming();

    if (propertyId.value)
      analyzePanelStore.fetchPropertyDataField(propertyId.value);
  } else {
    if (loanId.value) {
      fetchLoanAmount();
    } else {
      fetchValuations();
    }

    fetchInvestmentGroupTiming();

    if (propertyId.value)
      analyzePanelStore.fetchPropertyDataField(propertyId.value);
  }
}

async function fetchInvestmentGroupTiming() {
  if (investmentGroupId.value && _.size(groupTimingDataFields.value) === 0) {
    await analyzePanelStore.fetchInvestmentGroupTimingDataFields(
      investmentGroupId.value,
    );
  }
}

async function fetchValuations() {
  if (_.size(valuationDataFields.value) === 0) {
    await analyzePanelStore.fetchValuations(
      props.record.type,
      valuationContentId.value,
      valuationFetchKey.value,
    );
    selectedValuation.value = favoredValuation();
    fetchValue();
  } else {
    selectedValuation.value = favoredValuation();
    fetchValue();
  }
}
function favoredValuation() {
  return (
    _.find(valuationDataFields.value, function (dataField) {
      return dataField.fieldContent?.valueType === "Final";
    }) || _.head(valuationDataFields.value)
  );
}
async function fetchValue() {
  if (selectedValuationId.value) {
    await analyzePanelStore.fetchValueFor(
      props.record.type,
      valuationContentId.value,
      selectedValuationId.value,
    );
  }
}

async function fetchLoanAmount() {
  if (loanId.value) {
    await analyzePanelStore.fetchLoanAmountFor(
      props.record.type,
      valuationContentId.value,
      loanId.value,
    );
  }
}

function pulseProperty() {
  if (propertyDataField.value) {
    const pulseId =
      propertyDataField.value.fieldContent?.groundLayerPropertyId ||
      propertyDataField.value.fieldContentId;
    propertyMarkerPulseId.value = pulseId;
  }
}

function viewInvestmentGroup() {
  dealBuilderStore.upsertDealBuilder({
    existingGroupId: investmentGroupId.value,
  });
}
</script>
