<template>
  <tr v-if="field" :class="selected ? 'bg-gray-50' : ''">
    <td class="relative w-12 px-4 py-2">
      <div
        v-if="selected"
        class="absolute inset-y-0 left-0 w-0.5 bg-indigo-600"
      ></div>

      <input
        @click="toggleSelected"
        type="checkbox"
        :checked="selected"
        :value="selected"
        class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
      />
    </td>
    <td class="whitespace-nowrap py-2 pr-3">
      <DataField
        :data-field="field"
        :safezone-tab="true"
        text-classes="text-sm font-medium"
        :dropdown-placement="
          workspaceLayout === 'sideBySide' ? 'left-start' : null
        "
        @dismiss="dismissed"
      />
    </td>
    <td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
      {{ _.capitalize(attribution.userRole) }}
    </td>
    <td class="px-3 py-2 text-gray-500">
      <DataField
        v-if="propertyDataField"
        :data-field="propertyDataField"
        primary-text-path="fieldContent.name"
        text-classes="text-sm font-medium"
        dropdown-placement="left-start"
        text-styles=""
      />
      <a
        @click.prevent="selectDecorating"
        href=""
        class="text-xs text-indigo-600 hover:text-indigo-900"
        >{{ validationContextMetaData?.summary }}</a
      >
    </td>
    <td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
      <a
        @click.prevent="selectGroup"
        href=""
        class="text-indigo-600 hover:text-indigo-900"
        >{{ field.latestChangeGroupId }}</a
      >
    </td>
    <td class="whitespace-nowrap px-3 py-2 text-sm space-y-1">
      <div
        v-if="earnings"
        class="flex font-medium items-center text-emerald-500"
      >
        ${{ earnings }}
      </div>
      <div v-else class="flex items-center text-gray-500">$0.00</div>
      <div
        v-if="free"
        class="flex items-center text-gray-500"
        :data-test="`contribution-${index}-free-label`"
      >
        Data credits
      </div>
      <PayoutSelector
        v-else
        :attribution="attribution"
        :selected-account="payoutAccount"
        :shared-payout-accounts="sharedPayoutAccounts"
        :index="index"
        @update-account="updateAccount"
      />
    </td>
  </tr>
</template>

<script setup>
import DataField from "@/components/crowdsourcing/DataField.vue";
import PayoutSelector from "@/components/crowdsourcing/contributions/PayoutSelector.vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useDataLicensesChannelStore } from "@/stores/dataLicensesChannel";
import { usePropertyFieldsStore } from "@/stores/propertyFields";
import { useTopLevelContentFieldsStore } from "@/stores/topLevelContentFields";
import api from "@/router/api";
import { computed, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import _ from "lodash";

const props = defineProps([
  "attribution",
  "sharedPayoutAccounts",
  "selected",
  "index",
]);
const emit = defineEmits([
  "attach-field",
  "open-sourced",
  "dismissed",
  "unselect",
  "select",
  "select-decorating",
  "select-group",
]);

const field = ref(null);
const propertyId = ref(null);
const validationContextMetaData = ref(null);
const earnings = ref(null);
const payoutAccount = ref(null);

const topLevelContentFieldsStore = useTopLevelContentFieldsStore();
const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const dataLicensesChannelStore = useDataLicensesChannelStore();
const { dataLicensesChannelDataQueue } = storeToRefs(dataLicensesChannelStore);
const propertyFieldsStore = usePropertyFieldsStore();

const propertyDataField = computed(() => {
  if (propertyId.value) {
    return propertyFieldsStore.getPropertyField(propertyId.value);
  } else {
    return null;
  }
});

const dataFieldId = computed(() => props.attribution.dataFieldId);
const free = computed(() => _.get(field.value, "free"));

watch(dataLicensesChannelDataQueue, () => {
  const data = _.last(dataLicensesChannelDataQueue.value);

  if (data.dataFieldIds && _.includes(data.dataFieldIds, dataFieldId.value)) {
    // field.value = null;
    fetchDataField();
  }
});

onMounted(() => {
  fetchDataField();
  fetchTopLevelContent();
  fetchEarnings();
});

function updateAccount(newAccount) {
  payoutAccount.value = newAccount;
}

async function fetchDataField() {
  const fieldResponse = await api.get(
    `data_field_by_id/${dataFieldId.value}?scope=validation`,
  );

  field.value = fieldResponse.data;
  emit("attach-field", fieldResponse.data);

  validationContextMetaData.value =
    await topLevelContentFieldsStore.fetchValidationContext(
      field.value.decoratingContentType,
      field.value.decoratingContentId,
      "validation",
    );
}

async function fetchTopLevelContent() {
  const topLevelField =
    await topLevelContentFieldsStore.fetchTopLevelContentDataField(
      props.attribution.outerFieldId,
      dataFieldId.value,
    );
  if (topLevelField) {
    if (topLevelField.fieldContentType === "Property") {
      propertyFieldsStore.patchPropertyDataFields(topLevelField);
      propertyId.value = topLevelField.fieldContentId;
    } else if (
      topLevelField.fieldContent?.propertyId ||
      topLevelField.fieldContent?.topLevelPropertyId
    ) {
      propertyId.value =
        topLevelField.fieldContent?.propertyId ||
        topLevelField.fieldContent?.topLevelPropertyId;
      propertyFieldsStore.fetchPropertyDataField(propertyId.value);
    }
  }
}

function fetchEarnings() {
  api.get(`data_field_earnings/${props.attribution.id}`).then((json) => {
    earnings.value = json.data.earnings;
    payoutAccount.value = json.data.payoutAccount;
  });
}
function dismissed() {
  emit("dismissed");
}
function toggleSelected() {
  if (props.selected) {
    emit("unselect");
  } else {
    emit("select");
  }
}
function selectDecorating() {
  emit("select-decorating", {
    contentType: field.value.decoratingContentType,
    contentId: field.value.decoratingContentId,
  });
}
function selectGroup() {
  emit("select-group", {
    groupId: field.value.latestChangeGroupId,
  });
}
</script>
