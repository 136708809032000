<template>
  <Listbox as="div" v-model="selectedDateMenuOption" class="flex-shrink-0">
    <ListboxLabel class="sr-only"> {{ headerLabel }} Date/State </ListboxLabel>
    <VDropdown
      v-if="editingCustomDate"
      :triggers="[]"
      :shown="true"
      :autoHide="false"
    >
      <button
        v-tooltip="collapsedView ? dateStatePillLabel : ''"
        type="button"
        class="relative inline-flex items-center rounded-full py-2 px-2 text-sm font-medium whitespace-nowrap"
        :class="[
          !_.includes(
            ['spaceAvailabilityCommenced', 'spaceAvailabilityExpired'],
            eventType,
          )
            ? 'bg-gray-50 text-gray-500 hover:bg-gray-100'
            : '',
          eventType === 'spaceAvailabilityCommenced'
            ? 'bg-green-50 text-green-500 hover:bg-green-100'
            : '',
          eventType === 'spaceAvailabilityExpired'
            ? 'bg-red-50 text-red-500 hover:bg-red-100'
            : '',
        ]"
      >
        <CalendarDaysIcon
          :class="[
            collapsedView ? '' : '-ml-1',
            !state ? 'text-gray-300' : '',
            !_.includes(
              ['spaceAvailabilityCommenced', 'spaceAvailabilityExpired'],
              eventType,
            )
              ? !!state
                ? 'text-gray-500'
                : 'text-gray-300'
              : '',
            eventType === 'spaceAvailabilityCommenced'
              ? !!state && date
                ? 'text-green-500'
                : 'text-green-300'
              : '',
            eventType === 'spaceAvailabilityExpired'
              ? !!state && date
                ? 'text-red-500'
                : 'text-red-300'
              : '',
            `flex-shrink-0 h-5 w-5`,
          ]"
        />
        <span
          :class="[
            collapsedView ? 'hidden truncate' : 'ml-2 block',
            !!state ? 'text-gray-900' : '',
          ]"
        >
          {{ dateStatePillLabel }}
        </span>
      </button>
      <template #popper>
        <div
          v-if="selectedDateMenuOption?.value === 'customDate'"
          class="p-1 flex items-center space-x-2"
        >
          <input
            v-observe-visibility="{ callback: focusInput, once: true }"
            v-model="customDate"
            type="date"
            name="date"
            :id="`${dataField.localId}-custom-date`"
            data-test="closed_date-input"
            class="w-full rounded-full border-gray-300 pl-2 focus:border-indigo-500 focus:ring-indigo-500 text-xs"
          />
          <template v-if="customDate">
            <div class="flex items-center justify-end space-x-2">
              <button
                @click="cancelCustomDate"
                type="button"
                class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <XMarkIcon class="h-4 w-4" />
              </button>

              <button
                @click="persistCustomDate"
                type="button"
                class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
                data-test="closed_date-save-button"
              >
                <CheckIcon class="h-4 w-4" />
              </button>
            </div>
          </template>
        </div>
        <div
          v-else-if="selectedDateMenuOption?.value === 'termLengthMonths'"
          class="p-1 flex items-center space-x-2"
        >
          <input
            v-observe-visibility="{ callback: focusInput, once: true }"
            v-model="termLengthMonths"
            type="number"
            name="term-months"
            :id="`${dataField.localId}-term-months`"
            data-test="term_months-input"
            placeholder="Lease term months"
            class="w-full rounded-full border-gray-300 pl-2 focus:border-indigo-500 focus:ring-indigo-500 text-xs"
          />
          <template v-if="termLengthMonths">
            <div class="flex items-center justify-end space-x-2">
              <button
                @click="cancelCustomDate"
                type="button"
                class="inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <XMarkIcon class="h-4 w-4" />
              </button>

              <button
                @click="persistCustomDate"
                type="button"
                class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2"
                data-test="closed_date-save-button"
              >
                <CheckIcon class="h-4 w-4" />
              </button>
            </div>
          </template>
        </div>
      </template>
    </VDropdown>
    <div v-else class="relative">
      <ListboxButton
        v-tooltip="collapsedView ? dateStatePillLabel : ''"
        :id="`${buttonName}-${dataField.localId}`"
        class="relative inline-flex items-center rounded-full py-2 px-2 text-sm font-medium whitespace-nowrap"
        :class="[
          !_.includes(
            ['spaceAvailabilityCommenced', 'spaceAvailabilityExpired'],
            eventType,
          )
            ? 'bg-gray-50 text-gray-500 hover:bg-gray-100'
            : '',
          eventType === 'spaceAvailabilityCommenced'
            ? 'bg-green-50 text-green-500 hover:bg-green-100'
            : '',
          eventType === 'spaceAvailabilityExpired'
            ? 'bg-red-50 text-red-500 hover:bg-red-100'
            : '',
        ]"
        data-test="investment-date-state-dropdown"
      >
        <CalendarDaysIcon
          :class="[
            collapsedView ? '' : '-ml-1',
            !state ? 'text-gray-300' : '',
            !_.includes(
              ['spaceAvailabilityCommenced', 'spaceAvailabilityExpired'],
              eventType,
            )
              ? !!state
                ? 'text-gray-500'
                : 'text-gray-300'
              : '',
            eventType === 'spaceAvailabilityCommenced'
              ? !!state && date
                ? 'text-green-500'
                : 'text-green-300'
              : '',
            eventType === 'spaceAvailabilityExpired'
              ? !!state && date
                ? 'text-red-500'
                : 'text-red-300'
              : '',
            `flex-shrink-0 h-5 w-5`,
          ]"
        />
        <span
          :class="[
            collapsedView ? 'hidden truncate' : 'ml-2 block',
            !!state ? 'text-gray-900' : '',
          ]"
        >
          {{ dateStatePillLabel }}
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute left-0 z-10 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          style="z-index: 26"
        >
          <ListboxOption
            as="template"
            v-for="(option, index) in dateStateOptions"
            :key="index"
            :value="option"
            :data-test="`${_.kebabCase(option.name)}-state-option`"
            v-slot="{ active }"
          >
            <li
              :class="[
                active ? 'bg-gray-100' : 'bg-white',
                'relative cursor-default select-none py-2 px-3',
              ]"
            >
              <div class="flex items-center">
                <span class="block font-medium truncate">
                  {{ option.name }}
                </span>
              </div>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup>
import { CheckIcon } from "@heroicons/vue/24/outline";
import { CalendarDaysIcon, XMarkIcon } from "@heroicons/vue/20/solid";
import { ref, computed, watch, nextTick, onMounted } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { storeToRefs } from "pinia";
import moment from "moment";
import api from "@/router/api";
import _ from "lodash";

const props = defineProps([
  "eventType",
  "dataField",
  "state",
  "date",
  "commencedDate",
  "upstreamSpaceUsageId",
  "compact",
]);
const emit = defineEmits([
  "new-date",
  "new-state",
  "use-sublease-expiration-date",
]);

const layoutStore = useWorkspaceLayoutStore();
const { workspaceLayout } = storeToRefs(layoutStore);
const timeTravelStore = useTimeTravelStore();
const { timeTravelTo } = storeToRefs(timeTravelStore);

const lastUsedTermMonths = ref(null);
const selectedDateMenuOption = ref(null);
const editingCustomDate = ref(false);
const customDate = ref(null);
const termLengthMonths = ref(null);
const subleaseExpirationDateField = ref(null);

const headerLabel = computed(() => {
  switch (props.eventType) {
    case "capitalEvent":
    case "portfolioCapitalEvent":
    case "investmentTiming":
      return "Investment";
    case "spaceAvailability":
    case "portfolioSpaceAvailability":
    default:
      return "Space Availability";
  }
});
const collapsedView = computed(() => {
  return workspaceLayout.value === "sideBySide" && props.compact;
});
const buttonName = computed(() => {
  if (props.eventType === "spaceAvailabilityCommenced") {
    return "commenced-date-button";
  } else if (props.eventType === "spaceAvailabilityExpired") {
    return "expired-date-button";
  } else {
    return "date-state-button";
  }
});
const dateStateOptions = computed(() => {
  if (props.eventType === "spaceAvailabilityCommenced") {
    return [{ name: "Commencement date...", value: "customDate" }];
  } else if (props.eventType === "spaceAvailabilityExpired") {
    return _.compact([
      subleaseExpirationDateField.value
        ? { name: "Sublease expiration date", value: "subleaseExpirationDate" }
        : null,
      { name: "Expiration date...", value: "customDate" },
      props.commencedDate
        ? { name: "Term length (months)", value: "termLengthMonths" }
        : null,
    ]);
  } else {
    return baseOptions.value;
  }
});
const baseOptions = computed(() => {
  let underContractLabel = "Under Contract";
  if (props.eventType === "spaceAvailability") {
    underContractLabel = "Letter of Intent";
  }
  const closedOnly =
    props.eventType === "spaceAvailability" &&
    props.dataField.fieldContentType === "LandCovering";

  const earlyStageUnclosedOptions = closedOnly
    ? [{ name: underContractLabel, value: "under_contract" }]
    : [
        { name: "Available", value: "live" },
        { name: underContractLabel, value: "under_contract" },
      ];
  const lateStageUnclosedOptions = closedOnly
    ? []
    : [{ name: "Unknown", value: "unknown" }];

  if (timeTravelTo.value) {
    return _.concat(
      earlyStageUnclosedOptions,
      [
        {
          name: `Closed ${moment(timeTravelTo.value).format("MMM YYYY")}`,
          value: "timeTravelDate",
        },
        { name: "Closed date...", value: "customDate" },
        { name: "Closed date unknown", value: "closedNoDate" },
      ],
      lateStageUnclosedOptions,
    );
  } else {
    return _.concat(
      earlyStageUnclosedOptions,
      [
        { name: "Closed this week", value: "backDate1", numDays: 1 },
        { name: "Closed last week", value: "backDate10", numDays: 10 },
        { name: "Closed date...", value: "customDate" },
        { name: "Closed date unknown", value: "closedNoDate" },
      ],
      lateStageUnclosedOptions,
    );
  }
});
const dateStatePillLabel = computed(() => {
  if (
    selectedDateMenuOption.value &&
    selectedDateMenuOption.value.value === "customDate" &&
    props.date
  ) {
    return moment(props.date).format("MMM YYYY");
  } else if (props.state) {
    // Handle case where date/state are updated in the store but not in this component.
    const derivedOption = _.find(dateStateOptions.value, {
      value: props.state,
    });

    if (derivedOption) {
      return _.get(derivedOption, "name", "Date/state");
    } else if (
      _.includes(
        [
          "portfolioSpaceAvailability",
          "portfolioCapitalEvent",
          "investmentTiming",
        ],
        props.eventType,
      ) &&
      props.date &&
      props.state === "closed"
    ) {
      return `Closed ${moment(props.date).format("MMM YYYY")}`;
    } else if (
      _.includes(
        [
          "portfolioSpaceAvailability",
          "portfolioCapitalEvent",
          "investmentTiming",
        ],
        props.eventType,
      ) &&
      !props.date &&
      props.state === "closed"
    ) {
      return `Closed date unknown`;
    } else if (
      _.includes(["capitalEvent", "spaceAvailability"], props.eventType) &&
      props.date &&
      props.state === "closed"
    ) {
      return `Closed ${moment(props.date).format("MMM YYYY")}`;
    } else if (
      _.includes(["spaceAvailabilityCommenced"], props.eventType) &&
      props.date &&
      props.state === "closed"
    ) {
      return `LCD: ${moment(props.date).format("MMM YYYY")}`;
    } else if (
      _.includes(["spaceAvailabilityExpired"], props.eventType) &&
      props.date &&
      props.state === "closed"
    ) {
      return `LXD: ${moment(props.date).format("MMM YYYY")}`;
    } else if (props.eventType === "spaceAvailabilityCommenced") {
      return "LCD";
    } else if (props.eventType === "spaceAvailabilityExpired") {
      return "LXD";
    } else {
      return "Date/state";
    }
  } else if (selectedDateMenuOption.value) {
    return selectedDateMenuOption.value.name;
  } else {
    return "Date/state";
  }
});

watch(selectedDateMenuOption, () => {
  switch (selectedDateMenuOption.value?.value) {
    case "live":
    case "under_contract":
      backDate(1, false);
      setState(selectedDateMenuOption.value.value);
      break;
    case "backDate1":
    case "backDate10":
      backDate(selectedDateMenuOption.value.numDays);
      break;
    case "timeTravelDate": {
      const newDate = moment(timeTravelTo.value)
        .subtract(7, "days")
        .startOf("day")
        .toDate();

      emit("new-date", newDate);
      setState("closed");
      break;
    }
    case "customDate":
      if (props.date) {
        customDate.value = moment(props.date).format("YYYY-MM-DD");
      } else {
        customDate.value = moment().format("YYYY-MM-DD");
      }
      editingCustomDate.value = true;
      break;
    case "termLengthMonths":
      if (props.date && props.commencedDate) {
        const adjustedFinalMoment = moment(props.date)
          .endOf("month")
          .add(1, "day");
        termLengthMonths.value = adjustedFinalMoment.diff(
          moment(props.commencedDate),
          "months",
        );
      }
      editingCustomDate.value = true;
      break;
    case "subleaseExpirationDate":
      if (subleaseExpirationDateField.value?.fieldDate) {
        const newDate = moment(
          subleaseExpirationDateField.value?.fieldDate,
        ).toDate();

        emit("use-sublease-expiration-date", newDate);
        setState("closed");
      }
      break;
    case "closedNoDate":
      emit("new-date", null);
      setState("closed");
      break;
    case "unknown":
      clearDateState();
      break;
    default:
      return;
  }
});

watch(editingCustomDate, (bool, oldBool) => {
  if (oldBool && !bool) {
    focusDropdownButton();
  }
});

watch(
  () => props.commencedDate,
  () => {
    recalculateMonthBasedExpirationDate();
  },
);

onMounted(() => {
  fetchSubleaseExpirationDate();
});

function recalculateMonthBasedExpirationDate() {
  if (lastUsedTermMonths.value && props.date && props.commencedDate) {
    const lastUsed = lastUsedTermMonths.value;
    const newDate = moment(props.commencedDate)
      .add(lastUsedTermMonths.value, "months")
      .startOf("month")
      .subtract(1, "day");
    const newDateOutput = newDate.toDate();
    const isSameAsExisting = newDate.isSame(moment(props.date), "month");

    if (!isSameAsExisting) {
      emit("new-date", newDateOutput);
      cancelCustomDate();
      lastUsedTermMonths.value = lastUsed;
    }
  }
}

async function fetchSubleaseExpirationDate() {
  if (
    props.eventType === "spaceAvailabilityExpired" &&
    props.upstreamSpaceUsageId &&
    !subleaseExpirationDateField.value
  ) {
    if (_.isArray(props.upstreamSpaceUsageId)) {
      let dateFields = [];
      for (const id of props.upstreamSpaceUsageId) {
        const dateFieldResponse = await api.get(
          `crowdsourced_data_fields/SpaceUsage/${id}?field_name=expired_date`,
        );

        if (dateFieldResponse?.data) {
          dateFields = _.unionBy(
            [dateFieldResponse.data],
            dateFields,
            "localId",
          );
        }
      }

      if (dateFields.length === props.upstreamSpaceUsageId.length) {
        const dates = dateFields.map((df) => df.fieldDate);
        const sameExpirationDate = _.uniq(dates).length === 1;

        if (sameExpirationDate) {
          subleaseExpirationDateField.value = _.head(dateFields);
        }
      }
    } else {
      const dateFieldResponse = await api.get(
        `crowdsourced_data_fields/SpaceUsage/${props.upstreamSpaceUsageId}?field_name=expired_date`,
      );

      if (dateFieldResponse?.data) {
        subleaseExpirationDateField.value = dateFieldResponse.data;
      }
    }
  }
}

function focusDropdownButton() {
  setTimeout(() => {
    const el = document.getElementById(
      `${buttonName.value}-${props.dataField.localId}`,
    );

    if (el) {
      el.focus();
    }
  }, 100);
}

function focusInput(isVisible) {
  if (isVisible) {
    setTimeout(() => {
      selectInput();
    }, 100);
  }
}
function selectInput() {
  if (selectedDateMenuOption.value?.value === "customDate") {
    document.getElementById(`${props.dataField.localId}-custom-date`).focus();
  } else if (selectedDateMenuOption.value?.value === "termLengthMonths") {
    document.getElementById(`${props.dataField.localId}-term-months`).focus();
  }
}

function cancelCustomDate() {
  editingCustomDate.value = false;
  customDate.value = null;
  termLengthMonths.value = null;
  selectedDateMenuOption.value = null;
  lastUsedTermMonths.value = null;
}

function persistCustomDate() {
  let newDate = null;
  let lastUsed = null;
  if (selectedDateMenuOption.value?.value === "customDate") {
    newDate = moment(customDate.value).toDate();
  } else if (
    selectedDateMenuOption.value?.value === "termLengthMonths" &&
    termLengthMonths.value &&
    props.commencedDate
  ) {
    lastUsed = termLengthMonths.value;
    newDate = moment(props.commencedDate)
      .add(termLengthMonths.value, "months")
      .startOf("month")
      .subtract(1, "day")
      .toDate();
  }
  emit("new-date", newDate);
  setState("closed");
  cancelCustomDate();
  lastUsedTermMonths.value = lastUsed;
}

function setState(state) {
  emit("new-state", state);
}
async function backDate(numDays, close = true) {
  const newDate = moment().subtract(numDays, "days").startOf("day").toDate();

  emit("new-date", newDate);

  if (close) {
    await nextTick();
    setState("closed");
  }
}
function clearDateState() {
  emit("new-date", null);
  setState(null);
  selectedDateMenuOption.value = null;
}
</script>
