<template>
  <div class="overflow-hidden rounded-lg p-3 shadow bg-gray-100">
    <dt class="truncate text-sm font-medium text-gray-500">Availabilities</dt>
    <div
      v-if="fetchingAnalyzePanelData || refreshingMainTimeline"
      class="mt-1 w-full flex"
    >
      <SquareLoader :loading="true" size="24px" color="#9ca3af" />
    </div>
    <dd v-else class="mt-1 text-xl font-semibold tracking-tight text-gray-900">
      <DataField
        calculation-name="space_availability_count"
        :calculation-value="spaceAvailabilityCount"
        :bundle-field-ids="bundleFieldIds"
        text-classes="text-xl font-semibold tracking-tight"
        :analyze="true"
      />
    </dd>
  </div>
</template>

<script setup>
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { storeToRefs } from "pinia";
import { computed, onMounted, watch } from "vue";
import _ from "lodash";

const analyzePanelStore = useAnalyzePanelStore();
const {
  fetchingAnalyzePanelData,
  fetchedSpaceAvailabilityFields,
  combinedFilteredSpaceAvailabilityIds,
  surveySpaceAvailabilityCountProxy,
} = storeToRefs(analyzePanelStore);
const { refreshingMainTimeline, combinedSpaceAvailabilityIds } = storeToRefs(
  useTimeTravelStore(),
);

const bundleFieldIds = computed(() => {
  return _.compact(
    countFields.value.map((availabilityId) => {
      return _.find(fetchedSpaceAvailabilityFields.value, {
        fieldContentId: availabilityId,
      })?.localId;
    }),
  );
});
const countFields = computed(() => {
  return _.intersection(
    combinedSpaceAvailabilityIds.value,
    combinedFilteredSpaceAvailabilityIds.value,
  );
});

const spaceAvailabilityCount = computed(() => {
  return countFields.value.length;
});

watch(bundleFieldIds, () => {
  setUnlockableCount();
});

onMounted(() => {
  setUnlockableCount();
});

function setUnlockableCount() {
  surveySpaceAvailabilityCountProxy.value = spaceAvailabilityCount.value || 0;
}
</script>
