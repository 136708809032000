<template>
  <div :class="isDesktop ? '' : 'px-3'" class="flex space-x-12">
    <ContentComponent
      v-if="displayable"
      v-observe-visibility="{ callback: populateSections, once: true }"
      id="article"
      class="prose max-w-none prose-sm prose-a:text-indigo-700 even:prose-tr:bg-gray-50"
    />
    <div
      v-if="displaySections && sections.length > 0"
      class="flex-shrink-0 px-3 py-4 self-start sticky top-0 rounded-lg border border-gray-200 shadow-lg"
    >
      <div class="uppercase text-sm font-semibold border-b border-gray-200">
        In this article
      </div>
      <ul class="my-2 space-y-1">
        <li v-for="(el, index) in sections" :key="index">
          <button
            @click="documentationStore.viewArticle(article, el.innerText)"
            type="button"
            :class="[
              currentSection?.id === el.id
                ? 'bg-indigo-500 text-white font-semibold'
                : 'hover:bg-indigo-100',
            ]"
            class="w-full text-left text-sm px-2 py-1 rounded-md"
          >
            {{ el.innerText }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import Markdoc from "@markdoc/markdoc";
import render from "vue-markdoc";
import { config } from "@/assets/documentation/markdoc.config";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useDocumentationStore } from "@/stores/documentation";
import { computed, markRaw, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import VueScrollTo from "vue-scrollto";
import _ from "lodash";
import { storeToRefs } from "pinia";

const props = defineProps(["article", "tabView"]);

const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const documentationStore = useDocumentationStore();
const { querySectionName } = storeToRefs(documentationStore);

const displayable = ref(false);
const ContentComponent = ref(null);
const scrolling = ref(false);
const sections = ref([]);
const currentSection = ref(null);
const displaySections = computed(() => {
  return (
    !props.tabView &&
    isDesktop.value &&
    props.article.name !== "Tower Hunt glossary"
  );
});

const route = useRoute();
const hash = computed(() => route.hash);
const configWithArticle = computed(() => {
  let newConfig = config;

  newConfig.variables.article = props.article;

  return newConfig;
});

const articleId = computed(() => _.kebabCase(props.article.name));

const doc = `
Write article for {% $article.name %}

## Example custom tags
Tower Hunt {% inlineAppIcon iconName="check" %}**test icon**{% /inlineAppIcon %} lets you use real estate data on your terms, where other solutions… {% inlineRouterLink articleId="using-the-map" %}Read more{% /inlineRouterLink %}.

Let's test diagram icons: {% inlinePropertyDiagramIcon name="contacts" %}**new deal builder button**{% /inlinePropertyDiagramIcon %}

Let's test router links:
- {% inlineRouterLink articleId="property-diagram" %}This goes to the property diagram{% /inlineRouterLink %}
- {% inlineRouterLink categoryId="3" %}This goes to the profile category{% /inlineRouterLink %}
- {% inlineRouterLink categoryId="4" %}This goes to the crowdsourcing category{% /inlineRouterLink %}

{% callout type="caution" %}
Testing cautions for [Tower Hunt](https://towerhunt.com).
{% /callout %}
{% callout type="check" %}
Testing checks for [Tower Hunt](https://towerhunt.com).
{% /callout %}
{% callout type="note" %}
Testing notes for [Tower Hunt](https://towerhunt.com).
{% /callout %}
{% callout type="warning" %}
Testing warnings for [Tower Hunt](https://towerhunt.com).
{% /callout %}
{% callout type="tip" %}
**Tip:** Testing tips for [Tower Hunt](https://towerhunt.com).
{% /callout %}
`;

watch(articleId, () => {
  refreshMarkdown();

  if (hash.value || querySectionName.value) {
    setTimeout(() => {
      console.log("article id change scroll");
      scrollToSection(kebabToSection(hash.value || querySectionName.value));
    }, 1000);
  }
});

watch(querySectionName, () => {
  setTimeout(() => {
    scrollToSection(kebabToSection(querySectionName.value));
  }, 100);
});

watch(hash, () => {
  if (hash.value) {
    setTimeout(() => {
      scrollToSection(kebabToSection(hash.value));
    }, 100);
  }
});

onMounted(() => {
  refreshMarkdown(0);

  if (hash.value || querySectionName.value) {
    setTimeout(() => {
      scrollToSection(kebabToSection(hash.value || querySectionName.value));
    }, 500);
  }

  setTimeout(() => {
    if (props.tabView) {
      const listContainer = document.getElementById("lists-panel-container");
      listContainer.addEventListener("scroll", debouncedManageSections);
    } else {
      document.addEventListener("scroll", debouncedManageSections);
    }
  }, 1000);
});

onBeforeUnmount(() => {
  if (props.tabView) {
    const listContainer = document.getElementById("lists-panel-container");
    listContainer.removeEventListener("scroll", debouncedManageSections);
  } else {
    document.removeEventListener("scroll", debouncedManageSections);
  }
});

function refreshMarkdown(timeout = 500) {
  displayable.value = false;
  sections.value = [];

  setTimeout(() => {
    const ast = Markdoc.parse(props.article.markdown || doc);

    const content = Markdoc.transform(ast, configWithArticle.value);
    ContentComponent.value = markRaw(render(content));
    displayable.value = true;

    setTimeout(() => {
      populateSections(true);
    }, 500);
  }, timeout);
}

const debouncedManageSections = _.debounce(function () {
  manageCurrentSection();
}, 125);

function kebabToSection(kebabCaseName) {
  const rawKebab = _.replace(kebabCaseName, "#", "");
  if (sections.value.length > 0) {
    let matchables = [];
    for (const section of sections.value) {
      matchables.push({
        el: section,
        hashTestValue: _.kebabCase(section.innerText),
      });
    }

    return _.find(matchables, { hashTestValue: rawKebab })?.el;
  }
}

function manageCurrentSection(initialSetup = false) {
  if (sections.value.length > 0) {
    let tops = [];
    for (const section of sections.value) {
      const top = section.getBoundingClientRect().top;
      const documentTop = section.getBoundingClientRect().top + window.scrollY;
      tops.push({
        el: section,
        top,
        documentTop,
      });
    }

    const viewportBottom = window.scrollY + window.innerHeight;
    const availableTops = tops.filter((obj) => obj.top > 0);
    const visibleTops = tops.filter(
      (obj) => obj.top > 0 && obj.documentTop < viewportBottom,
    );
    const nearestToTop = _.minBy(availableTops, "top");

    if (
      visibleTops.length === 1 ||
      (nearestToTop && nearestToTop.top < 250) ||
      window.scrollY < 10
    ) {
      currentSection.value = nearestToTop.el;

      if (!initialSetup) {
        scrolling.value = true;
        documentationStore.viewArticle(
          props.article,
          nearestToTop.el.innerText,
        );
        setTimeout(() => {
          scrolling.value = false;
        }, 1000);
      }
    }
  }
}

function populateSections(isVisible) {
  if (isVisible) {
    const container = document.querySelector("#article");
    const matches = container.querySelectorAll("h2");
    matches.forEach((el, index) => {
      el.id = `article-section-${index + 1}`;
    });

    sections.value = matches;
    manageCurrentSection(true);
  }
}
function scrollToSection(sectionElement, timeout = 100) {
  if (sectionElement?.id && !scrolling.value) {
    const beforeTop = sectionElement.getBoundingClientRect().top;

    if (Math.abs(beforeTop) > 200) {
      scrolling.value = true;
      setTimeout(() => {
        const rawId = _.replace(sectionElement.id, "#", "");
        const scrollEl = document.getElementById(rawId);
        if (scrollEl) {
          const container = props.tabView
            ? { container: "#lists-panel-container" }
            : {};
          VueScrollTo.scrollTo(
            scrollEl,
            100,
            _.merge({}, { offset: -75 }, container),
          );
        }
        setTimeout(() => {
          scrolling.value = false;
        }, 500);
      }, timeout);
    }
  }
}
</script>

<style scoped>
:deep(ol) {
  list-style: none;
}
:deep(ol > li) {
  vertical-align: middle;
  margin-bottom: 10px;
}
:deep(ol > li:first-child) {
  counter-reset: index;
}
:deep(ol > li:before) {
  content: counter(index);
  counter-increment: index;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #007a5a;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  text-align: center;
  color: #fff;
  line-height: 25px;
  font-weight: 700;
}
</style>
