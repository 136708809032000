<template>
  <div class="overflow-hidden rounded-lg p-3 shadow bg-gray-100">
    <dt class="truncate text-sm font-medium text-gray-500">Investments</dt>
    <div
      v-if="fetchingAnalyzePanelData || refreshingMainTimeline"
      class="mt-1 w-full flex"
    >
      <SquareLoader :loading="true" size="24px" color="#9ca3af" />
    </div>
    <dd v-else class="mt-1 text-xl font-semibold tracking-tight text-gray-900">
      <DataField
        calculation-name="investment_count"
        :calculation-value="
          investmentVolumeSurveyFetched ? investmentCount : 'Get'
        "
        :bundle-field-ids="bundleFieldIds"
        text-classes="text-xl font-semibold tracking-tight"
        :analyze="true"
      />
    </dd>
  </div>
</template>

<script setup>
import SquareLoader from "vue-spinner/src/SquareLoader.vue";
import DataField from "@/components/crowdsourcing/DataField.vue";
import { useTimeTravelStore } from "@/stores/timeTravel";
import { useAnalyzePanelStore } from "@/stores/analyzePanel";
import { useNotificationsStore } from "@/stores/notifications";
import { operableDeals } from "@/components/analyze/calculations/investmentVolume";
import { storeToRefs } from "pinia";
import { computed, onMounted, watch } from "vue";
import api from "@/router/api";
import _ from "lodash";

const notificationsStore = useNotificationsStore();
const analyzePanelStore = useAnalyzePanelStore();
const {
  investmentVolumeSurveyFetched,
  calculationData,
  calculationOptions,
  fetchingAnalyzePanelData,
  valueFilterApplied,
  combinedFilteredInvestmentIds,
  checkedCategories,
  surveyInvestmentCountProxy,
} = storeToRefs(analyzePanelStore);
const { asOfMilliseconds, refreshingMainTimeline, combinedInvestmentIds } =
  storeToRefs(useTimeTravelStore());

const investmentIdsCount = computed(() => _.size(combinedInvestmentIds.value));
const bundleFieldIds = computed(() => {
  return _.map(countFields.value, function (field) {
    return field.localId;
  });
});
const countFields = computed(() => {
  if (investmentVolumeSurveyFetched.value) {
    return operableDeals(
      calculationData.value,
      calculationOptions.value,
      analyzePanelStore,
    );
  } else {
    return [];
  }
});

const investmentCount = computed(() => {
  return countFields.value.length;
});

watch(investmentCount, () => {
  setUnlockableCount();
});

watch(valueFilterApplied, async () => {
  debouncedrefetchData();
});

watch(investmentIdsCount, async () => {
  debouncedrefetchData();
});

watch(
  combinedFilteredInvestmentIds,
  () => {
    debouncedrefetchData();
  },
  { deep: true },
);

onMounted(() => {
  setUnlockableCount();
});

function setUnlockableCount() {
  console.log("investment count", investmentCount.value);
  surveyInvestmentCountProxy.value = investmentCount.value || 0;
}

const debouncedrefetchData = _.debounce(function () {
  refetchData();
}, 3000);

async function refetchData() {
  if (
    investmentIdsCount.value > 0 &&
    checkedCategories.value &&
    !investmentVolumeSurveyFetched.value
  ) {
    try {
      const response = await fetchInvestmentVolumeData();

      if (response?.data) {
        analyzePanelStore.setCalculationDataByName(
          "investment_volume",
          response.data?.calculationFields,
          "survey",
        );
      }
    } catch (error) {
      console.error(error);
      notificationsStore.addNotification("anErrorOccurred");
    } finally {
      fetchingAnalyzePanelData.value = false;
    }
  }
}

async function fetchInvestmentVolumeData() {
  fetchingAnalyzePanelData.value = true;
  const payload = {
    contentType: "Investment",
    contentIds: combinedInvestmentIds.value,
    calculationName: "investment_volume",
    bundle: "calculation",
    asOf: asOfMilliseconds.value,
  };
  return api.post(`crowdsourced_data_field_calculation_bundles`, payload);
}
</script>
